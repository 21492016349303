import React, { FunctionComponent, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, X } from '@styled-icons/heroicons-solid'
import Drawer from 'rc-drawer'
import { NotificationBell } from '../../features/notifications/Bell'
import { useCurrentCommunity } from '../../features/users/useMe'
import { dashboardPath } from '../../helpers/paths'
import { useOnOutsideClick } from '../../hooks/useOnClickOutside'
import logo from '../../learnlife_barcelona_logo.svg'
import { CommunityMobileMenu } from '../CommunityMobileMenu/CommunityMobileMenu'
import { UserAvatarMenu } from '../UserAvatarMenu/UserAvatarMenu'

export const CommunityTopMenu: FunctionComponent = () => {
  const community = useCurrentCommunity()

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false)

  const handleMobileMenuButton = useCallback(() => {
    setProfileMenuOpen(false)
    setMobileMenuOpen(!isMobileMenuOpen)
  }, [isMobileMenuOpen])
  const handleProfileMenuButton = useCallback(() => {
    setMobileMenuOpen(false)
    setProfileMenuOpen(!isProfileMenuOpen)
  }, [isProfileMenuOpen])

  const [avatarMenuInsideClickRef, mobileMenuInsideClickRef] =
    useOnOutsideClick<HTMLDivElement>(
      () => {
        isMobileMenuOpen && setMobileMenuOpen(false)
        isProfileMenuOpen && setProfileMenuOpen(false)
      },
      [isMobileMenuOpen, isProfileMenuOpen],
      2
    )

  return (
    <nav className="border-b border-gray-300">
      <div className="mx-auto bg-white px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-12 justify-between">
          {/* <!-- Mobile menu button --> */}
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen}
              onClick={handleMobileMenuButton}
            >
              {isMobileMenuOpen ? (
                <>
                  <span className="sr-only">Close main menu</span>
                  <X size={24} />
                </>
              ) : (
                <>
                  <span className="sr-only">Open main menu</span>
                  <Menu size={24} />
                </>
              )}
            </button>

            {/* Mobile side menu */}
            <Drawer
              open={isMobileMenuOpen}
              width="16rem"
              level={null}
              handler={false}
              wrapperClassName="sm:hidden"
            >
              <div
                className="h-full bg-gray-800"
                ref={mobileMenuInsideClickRef}
              >
                <CommunityMobileMenu />
              </div>
            </Drawer>
          </div>

          {/* <!-- Menu --> */}
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex shrink-0 items-center">
              <Link to={dashboardPath()} className="focus:underline">
                <img
                  className="block h-8 w-auto lg:hidden"
                  src={logo}
                  alt="Community"
                />
                <div className="hidden font-semibold lg:block">
                  {community?.name}
                </div>
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8" />
          </div>

          {/* Right-side buttons */}
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="relative ml-3">
              <NotificationBell />
            </div>
            {/* <!-- Profile dropdown --> */}
            <div className="relative ml-3">
              <UserAvatarMenu
                ref={avatarMenuInsideClickRef}
                open={isProfileMenuOpen}
                onAvatarClick={handleProfileMenuButton}
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
