import { EntityId } from '@reduxjs/toolkit'
import {
  ApiModel_ExperienceMembership,
  useSWR_ExperienceMemberships,
} from '../hooks/useAPI'

export const useFindExperienceMembership = (
  membershipId: EntityId,
  experienceId: EntityId
): ApiModel_ExperienceMembership | undefined => {
  const perPage = 9999
  const { data } = useSWR_ExperienceMemberships({
    experienceId,
    perPage,
  })
  const membership = data?.data.experience_memberships.find(
    membership => membership.id === membershipId
  )
  return membership
}
