import React, { FunctionComponent, useEffect } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { mutate } from 'swr'
import { apiExperiencePath } from '../../helpers/apiPaths'
import { getSelectedExperienceId } from '../../selectors/experiences'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { useSelectorOrThrow } from '../../utils/useSelectorOrThrow'
import { titleClassName } from '../ExperienceSection/ExperienceSectionTitle'
import { SpinnerIcon } from '../SpinnerIcon/SpinnerIcon'

interface SavingStateProps {
  title: string
  onSuccess: () => void
  onFail: (title: string) => void
}

export const SavingState: FunctionComponent<SavingStateProps> = ({
  title,
  onSuccess,
  onFail,
}) => {
  const flashMessages = useFlashMessages()
  const experienceId = useSelectorOrThrow(getSelectedExperienceId)
  useEffect(
    () => {
      ;(async function postSection() {
        const response = await apiPostSection(title, experienceId)
        if (response.ok) {
          await mutate(apiExperiencePath(experienceId))
          onSuccess()
        } else {
          flashMessages.show({
            type: 'error',
            text: "Can't save section.",
          })
          onFail(title)
        }
      })()
    },
    // This effect must run only once, so no deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return (
    <div className="add-section">
      <div />
      <div className={titleClassName}>{title}</div>
      <SpinnerIcon className="animate-spin" />
    </div>
  )
}

export const apiPostSection = (
  title: string,
  experienceId: EntityId
): Promise<Response> => {
  return fetch('/api/v1/experience_sections', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      experience_section: {
        title: title,
        experience_id: experienceId,
      },
    }),
  })
}
