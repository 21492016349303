import React, { FunctionComponent } from 'react'

export const SideMenu: FunctionComponent = props => {
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-grow flex-col">
        <nav className="flex-1 space-y-1 py-4 px-4" aria-label="Side menu">
          {props.children}
        </nav>
      </div>
    </div>
  )
}
