import { DefaultRootState, useSelector } from 'react-redux'

/**
 * USE SPARINGLY.
 *
 * This is not a replacement for useSelector; it is meant for data which must be
 * present for anything to work, such as communityId. If at all possible,
 * handle possible undefined values instead.
 *
 */
export function useSelectorOrThrow<
  TState = DefaultRootState,
  TSelected = unknown
>(
  selector: (state: TState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): NonNullable<TSelected> {
  const value = useSelector(selector, equalityFn)
  if (value == null) throw new UseSelectorOrThrowError(selector, equalityFn)
  return value as NonNullable<TSelected>
}

export class UseSelectorOrThrowError<
  TState = DefaultRootState,
  TSelected = unknown
> extends Error {
  selector: (state: TState) => TSelected
  equalityFn?: (left: TSelected, right: TSelected) => boolean

  constructor(
    selector: (state: TState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
  ) {
    super('useSelectorOrThrow found a null or undefined value')
    this.selector = selector
    this.equalityFn = equalityFn
  }
}
