import React from 'react'
import { TRenderElementProps } from '@udecode/plate'
import classNames from 'classnames'
import { useFocused, useSelected } from 'slate-react'

export const HorizontalRuleElement = ({
  attributes,
  children,
}: TRenderElementProps): JSX.Element => {
  const selected = useSelected()
  const focused = useFocused()

  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <hr
          className={classNames({
            'my-4 h-px border-black': true,
            'ring-2': selected && focused,
          })}
        />
      </div>
      {children}
    </div>
  )
}
