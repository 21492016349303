import { StyledIconBase } from '@styled-icons/styled-icon'
import styled from 'styled-components'

// Base style that applies to all @styled-icons
// https://github.com/styled-icons/styled-icons#base-icon-styles
export const IconStyleWrapper = styled.div`
  ${StyledIconBase} {
    display: block;
  }
`
