export const hotjar = (...args: any[]): any => {
  ;(function (h: any, o: any, t: any, j: any) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(...args)
      }
    h._hjSettings = { hjid: import.meta.env.VITE_HOTJAR_ID as any, hjsv: 6 }
    const a = o.getElementsByTagName('head')[0]
    const r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}
