/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck
import type { Learninx_Presenters_Users_Me } from '../models/Learninx_Presenters_Users_Me';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeService {

    /**
     * Get current user profile and associated communities
     * @returns Learninx_Presenters_Users_Me Get current user profile and associated communities
     * @throws ApiError
     */
    public static getV2Me(): CancelablePromise<Learninx_Presenters_Users_Me> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/me',
        });
    }

}
