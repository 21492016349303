/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck
import type { Learninx_ExperienceMembershipComments_Presenters_List } from '../models/Learninx_ExperienceMembershipComments_Presenters_List';
import type { Learninx_ExperienceMembershipComments_Presenters_Record } from '../models/Learninx_ExperienceMembershipComments_Presenters_Record';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExperienceFeedbackService {

    /**
     * @returns void
     * @throws ApiError
     */
    public static deleteV2ExperienceFeedbackId({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/experience_feedback/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Experience Feedback for a given member & page
     * @returns Learninx_ExperienceMembershipComments_Presenters_List List Experience Feedback for a given member & page
     * @throws ApiError
     */
    public static getV2ExperienceFeedback({
        experienceDocId,
        experienceMembershipId,
    }: {
        /**
         * ID of associated page
         */
        experienceDocId: string,
        /**
         * ID of recipient's membership
         */
        experienceMembershipId: string,
    }): CancelablePromise<Learninx_ExperienceMembershipComments_Presenters_List> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/experience_feedback',
            query: {
                'experience_doc_id': experienceDocId,
                'experience_membership_id': experienceMembershipId,
            },
        });
    }

    /**
     * Create Experience Feedback
     * @returns Learninx_ExperienceMembershipComments_Presenters_Record Create Experience Feedback
     * @throws ApiError
     */
    public static postV2ExperienceFeedback({
        content,
        experienceMembershipId,
        experienceDocId,
    }: {
        /**
         * Comment body
         */
        content: string,
        /**
         * ID of associated membership
         */
        experienceMembershipId: string,
        /**
         * ID of associated page
         */
        experienceDocId: string,
    }): CancelablePromise<Learninx_ExperienceMembershipComments_Presenters_Record> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/experience_feedback',
            formData: {
                'content': content,
                'experience_membership_id': experienceMembershipId,
                'experience_doc_id': experienceDocId,
            },
        });
    }

}
