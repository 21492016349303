import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { LearningOutcome } from '../types/entities'

export type LearningOutcomesState = EntityState<LearningOutcome>

export const learningOutcomesAdapter = createEntityAdapter<LearningOutcome>()

export const initialState =
  learningOutcomesAdapter.getInitialState<LearningOutcomesState>({
    ids: [],
    entities: {},
  })

export const learningOutcomesSlice = createSlice({
  name: 'learningOutcomes',
  initialState,
  reducers: {
    upsertManyLearningOutcomes: learningOutcomesAdapter.upsertMany,
    updateOneLearningOutcomes: learningOutcomesAdapter.updateOne,
  },
})

export const { upsertManyLearningOutcomes, updateOneLearningOutcomes } =
  learningOutcomesSlice.actions

export const { reducer } = learningOutcomesSlice
