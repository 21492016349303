import React from 'react'
import { TElement, TRenderElementProps } from '@udecode/plate'
import classNames from 'classnames'
import { useFocused, useReadOnly, useSelected } from 'slate-react'
import { ELEMENT_IFRAME } from './createIframePlugin'

export type IframeElement = TElement<{
  type: ELEMENT_IFRAME
  code: string
}>

export const IframeElement = ({
  attributes,
  children,
  element,
}: TRenderElementProps<IframeElement>): JSX.Element => {
  const readOnly = useReadOnly()
  const selected = useSelected()
  const focused = useFocused()

  return (
    <div {...attributes}>
      <div
        contentEditable={false}
        className={classNames({
          'my-4 flex justify-center': true,
          'ring-4': selected && focused,
          'pointer-events-none select-none': !readOnly,
        })}
        dangerouslySetInnerHTML={{ __html: element.code }}
      />
      {children}
    </div>
  )
}
