import { createPluginFactory } from '@udecode/plate'

export const ELEMENT_TEXT_AREA_FIELD = 'text_area_field'
export type ELEMENT_TEXT_AREA_FIELD = typeof ELEMENT_TEXT_AREA_FIELD

export const createTextAreaFieldlugin = createPluginFactory({
  key: ELEMENT_TEXT_AREA_FIELD,
  isElement: true,
  isVoid: true,
})
