import React, { FunctionComponent, useCallback, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useUnmount } from 'react-use'
import { EntityId } from '@reduxjs/toolkit'
import { HeadingToolbar, Plate, TNode } from '@udecode/plate'
import { mutate } from 'swr'
import { useDebouncedCallback } from 'use-debounce'
import { apiDocumentPath, apiExperiencePath } from '../../helpers/apiPaths'
import { ApiModel_ExperienceDoc } from '../../hooks/useAPI'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { ContentCard } from '../ContentCard/ContentCard'
import { createDefaultValue, editableProps, plugins } from './config/config'
import { HoverToolbar } from './HoverToolbar'
import { EditorToolbar } from './Toolbars'

interface DocumentEditorProps {
  readOnly: boolean
  document: ApiModel_ExperienceDoc
  experienceId: EntityId
}

export const DocumentEditor: FunctionComponent<DocumentEditorProps> = ({
  document,
  readOnly,
  experienceId,
}) => {
  const [dirtyValue, setDirtyValue] = useState<TNode[] | null>(null)
  const flashMessages = useFlashMessages()
  const persistDocument = useCallback(
    async (document: ApiModel_ExperienceDoc, unserialized: TNode[]) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Saving doc: ', unserialized)
      }
      const response = await fetch(apiDocumentPath(document.id), {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          experience_doc: {
            content: unserialized,
          },
        }),
      })
      if (response.ok) {
        await mutate(apiExperiencePath(experienceId))
        await mutate(apiDocumentPath(document.id))
        document.content = unserialized
      } else {
        flashMessages.show({
          type: 'error',
          text: `Could not save page: ${document.title}`,
        })
      }
    },
    [experienceId, flashMessages]
  )

  const persistDocumentDebounced = useDebouncedCallback(
    (value: TNode[]) => {
      persistDocument(document, value)
      setDirtyValue(null)
    },
    2000,
    { maxWait: 5000 }
  )

  const handleChange = useCallback(
    (value: TNode[]) => {
      setDirtyValue(value)
      persistDocumentDebounced(value)
    },
    [setDirtyValue, persistDocumentDebounced]
  )

  const checkDirtyDocAndSave = useCallback(() => {
    if (dirtyValue) {
      persistDocument(document, dirtyValue)
      setDirtyValue(null)
    }
  }, [document, dirtyValue, persistDocument])

  // Auto-save document
  useUnmount(() => checkDirtyDocAndSave())

  const initialValue: TNode[] = document.content?.length
    ? document.content
    : createDefaultValue()

  return (
    <ContentCard className="p-6">
      <DndProvider backend={HTML5Backend}>
        <Plate
          id={document.id as string}
          initialValue={initialValue}
          onChange={handleChange}
          plugins={plugins}
          editableProps={{ ...editableProps, readOnly }}
        >
          {readOnly || <HoverToolbar />}
          {readOnly || (
            <div className="sticky top-0 z-20 -mx-6 -mt-6 mb-4 bg-white pb-0 sm:top-16 sm:z-1">
              <HeadingToolbar styles={{ root: { margin: '0', padding: '0' } }}>
                <EditorToolbar />
              </HeadingToolbar>
            </div>
          )}
        </Plate>
      </DndProvider>
    </ContentCard>
  )
}
