import { PlateEditor } from '@udecode/plate'
import { insertEmbedElement, isEmbedUrl } from '../embed'
import { insertImage, isImageUrl } from '../image'

// Called by a click event on the toolbar button.
export const promptUrl = (editor: PlateEditor): void => {
  const url = window.prompt(
    'Insert media by URL: images, audio, YouTube, SoundCloud, Google Maps and more.'
  )
  if (!url) return
  else if (isEmbedUrl(url)) insertEmbedElement(editor, url)
  else if (isImageUrl(url)) insertImage(editor, url)
  else alert('Sorry, this URL is not supported.')
}
