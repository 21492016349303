import React, { FunctionComponent } from 'react'
import { useSWR_MyExperienceMemberships } from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useToggle } from '../../hooks/useToggle'
import { Dropdown } from '../Dropdown/Dropdown'
import { ExperienceAttendeeDropdown_DropdownMenu_DoneButton } from '../ExperienceAttendeeDropdown/ExperienceAttendeeDropdown_DropdownMenu_DoneButton'
import { ExperienceStatus_MenuButton } from './ExperienceStatus'
import { SetSavingProps } from './ExperienceStatus'
import { ExperienceStatus_DropdownMenu_ArchiveButton } from './ExperienceStatus_DropdownMenu_ArchiveButton'
import { ExperienceStatus_DropdownMenu_DraftButton } from './ExperienceStatus_DropdownMenu_DraftButton'

export const ExperienceStatus_PublishedView_DropdownMenu: FunctionComponent<
  SetSavingProps
> = ({ setSaving }) => {
  const experienceId = useExperienceId()
  const { data: membershipData } = useSWR_MyExperienceMemberships(experienceId)

  const [menuOpen, toggleMenuOpen] = useToggle(false)

  if (!membershipData) return null

  const membership = membershipData.data.experience_memberships[0]

  return (
    <div id="status-dropdown-published" className="relative">
      <ExperienceStatus_MenuButton onClick={toggleMenuOpen} />
      {menuOpen && (
        <Dropdown>
          {membership &&
            !membership.done_at &&
            membership.access_level === 'attendee' && (
              <ExperienceAttendeeDropdown_DropdownMenu_DoneButton />
            )}
          <ExperienceStatus_DropdownMenu_DraftButton setSaving={setSaving} />
          <ExperienceStatus_DropdownMenu_ArchiveButton setSaving={setSaving} />
        </Dropdown>
      )}
    </div>
  )
}
