import React, { ReactElement, useMemo } from 'react'
import Select, {
  defaultTheme,
  GroupTypeBase,
  OptionTypeBase,
  Props,
  StylesConfig,
  Theme,
} from 'react-select'
import classNames from 'classnames'

export function ThemedSelect<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: Props<OptionType, IsMulti, GroupType> & { isFilter?: boolean }
): ReactElement {
  const themeConfig = useMemo<Theme>(
    () => ({
      ...defaultTheme,
      borderRadius: 6,
      colors: {
        ...defaultTheme.colors,
        primary: props.isFilter ? 'transparent' : 'rgb(99, 102, 241)',
        primary25: props.isFilter ? 'transparent' : 'rgb(99, 102, 241)',
        primary50: props.isFilter ? 'transparent' : 'rgb(99, 102, 241)',
        primary75: props.isFilter ? 'transparent' : 'rgb(99, 102, 241)',
        neutral20: 'rgb(209, 213, 219)',
      },
    }),
    [props.isFilter]
  )

  const styles = useMemo<StylesConfig<OptionType, IsMulti, GroupType>>(
    () => ({
      valueContainer: styles => ({
        ...styles,
        flexWrap: 'nowrap',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (styles, state) => ({
        ...styles,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,
      }),
      placeholder: styles => ({
        ...styles,
        color: '#6B7280',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }),
      option: (styles, state) => ({
        ...styles,
        ...(state.isDisabled
          ? {
              color: 'rgb(17, 24, 39)',
              backgroundColor: 'rgb(229, 231, 235)',
            }
          : {
              color: state.isFocused && !props.isFilter ? 'white' : 'black',
              backgroundColor:
                state.isFocused && !props.isFilter
                  ? 'rgb(99, 102, 241)'
                  : 'transparent',
            }),
        cursor: 'pointer',
      }),
      control: (styles, state) => ({
        ...styles,
        cursor: 'pointer',
        border: props.isInvalid ? '1px solid #ef4444' : undefined,
        ...(state.selectProps.isFilter && { display: 'none' }),
        ...(state.selectProps.noBorder && {
          border: 'none',
          boxShadow: 'none',
        }),
      }),
      menu: (styles, state) => ({
        ...styles,
        ...(state.selectProps.isFilter && {
          boxShadow: 'none',
          position: 'relative',
          marginTop: '0px',
        }),
      }),
      // control: styles => {
      //   return meta.touched && meta.error
      //     ? { ...styles, border: '1px solid #ef4444' }
      //     : styles
      // },
    }),
    [props.isFilter, props.isInvalid]
  )

  return (
    <Select
      {...props}
      styles={styles}
      theme={themeConfig}
      className={classNames(
        'react-select sm:text-sm',
        !props.isFilter && !props.noBorder && 'shadow-sm'
      )}
    />
  )
}
