import { FunctionComponent, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { useDebouncedCallback } from 'use-debounce'

export const AutoSubmit: FunctionComponent = (): null => {
  const { values, setSubmitting, submitForm } = useFormikContext()

  const stringifiedValues = JSON.stringify(values)

  const [oldStringifiedValues, setOldStrifiedValues] =
    useState(stringifiedValues)

  const debouncedSubmit = useDebouncedCallback(() => {
    setSubmitting(true)
    submitForm()
  }, 500)

  useEffect(() => {
    if (stringifiedValues === oldStringifiedValues) return
    setOldStrifiedValues(stringifiedValues)
    debouncedSubmit()
  }, [debouncedSubmit, stringifiedValues, oldStringifiedValues])

  return null
}
