import React from 'react'

export function ExperienceAddMembersPageLoading(): JSX.Element {
  return (
    <div className="flex flex-col space-y-8">
      <div className="skeleton">.</div>

      <div className="space-y-2">
        <div className="skeleton">.</div>
        <div className="skeleton">.</div>
      </div>

      <div className="flex justify-end space-x-2">
        <div className="skeleton h-9 w-20">.</div>
        <div className="skeleton h-9 w-20">.</div>
      </div>
    </div>
  )
}
