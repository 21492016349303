import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Bell } from '@styled-icons/heroicons-outline'
import { useBellNotifications, useReadBellNotifications } from './api'

export const NotificationBell: FunctionComponent = () => {
  const unread = useBellNotifications()
    .data?.pages?.flatMap(p => p.notifications)
    ?.some(n => !n?.read_at)

  const markRead = useReadBellNotifications()

  return (
    <Link
      to="/notifications"
      title="Notifications"
      className="block h-8 w-8 rounded-full bg-gray-100 p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={() => markRead.mutate()}
    >
      <div className="relative">
        {unread && (
          <div className="absolute -top-0.5 -right-0.5 h-2 w-2 rounded-full bg-red-600"></div>
        )}
      </div>
      <Bell
        className={`transition ${
          unread
            ? 'text-gray-800 hover:text-gray-600'
            : 'text-gray-500 hover:text-gray-400'
        }`}
      ></Bell>
    </Link>
  )
}
