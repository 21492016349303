import { EntityId } from '@reduxjs/toolkit'
import { ApiResponse_ExperienceDoc } from '../../hooks/useAPI'
import { documentSchema } from '../../schemas/document'
import { reduxFetch } from '../../utils/reduxFetch'
import { createDefaultValue } from '../DocumentEditor/config/config'

export const addExperienceDocumentFetch = (
  sectionId: EntityId
): Promise<ApiResponse_ExperienceDoc> => {
  const fetch = reduxFetch({ experienceDoc: documentSchema })
  return fetch('/api/v1/experience_docs', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      experience_doc: {
        title: 'Untitled',
        parent_id: sectionId,
        content: JSON.stringify(createDefaultValue()),
      },
    }),
  })
}
