import React, { FunctionComponent } from 'react'
import { CommunitySideMenu } from '../CommunitySideMenu/CommunitySideMenu'
import { CommunityTopMenu } from '../CommunityTopMenu/CommunityTopMenu'
import { NarrowLayout } from '../NarrowLayout/NarrowLayout'

export const CommunityLayout: FunctionComponent = props => {
  return (
    <NarrowLayout
      topComponent={<CommunityTopMenu />}
      sideComponent={<CommunitySideMenu />}
    >
      {props.children}
    </NarrowLayout>
  )
}
