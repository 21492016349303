import React from 'react'
import { FunctionComponent } from 'react'
import { useHistory } from 'react-router'
import { components, SingleValueProps } from 'react-select'
import { EntityId } from '@reduxjs/toolkit'
import invariant from 'tiny-invariant'
import { experienceEvidenceDetailPath } from '../../helpers/paths'
import { useSWR_ExperienceMemberships } from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useMembershipId } from '../../hooks/useMembershipId'
import { ThemedSelect } from '../ThemedSelect/ThemedSelect'
import { UserAvatar } from '../UserAvatar/UserAvatar'

type MemberOption = {
  value: EntityId | undefined
  label: string
}

export const EvidenceMemberSwitch: FunctionComponent = () => {
  const experienceId = useExperienceId()
  const memberships = useSWR_ExperienceMemberships({
    experienceId,
    perPage: 999,
  })
  const options = memberships.data?.data.experience_memberships
    .filter(m => !['collaborator', 'creator'].includes(m.access_level))
    .map(m => ({
      value: m.id,
      label: `${m.first_name} ${m.last_name}`,
    }))

  const membershipId = useMembershipId()
  const membership = memberships.data?.data.experience_memberships.find(
    m => m.id === membershipId
  )
  const defaultValue = {
    value: membership?.id,
    label: `${membership?.first_name} ${membership?.last_name}`,
  }

  const history = useHistory()
  const handleChange = (value: MemberOption | null) => {
    const membershipId = value?.value
    invariant(membershipId)
    history.push(experienceEvidenceDetailPath(experienceId, membershipId))
  }

  const OptionComponent: FunctionComponent<any> = ({
    innerRef,
    innerProps,
    children,
    value,
  }) => {
    const membership = memberships.data?.data.experience_memberships.find(
      m => m.id === value
    )
    return (
      <div ref={innerRef} {...innerProps}>
        <div className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-indigo-500 hover:text-white">
          <div>
            <UserAvatar size="small" user={{ ...membership }} />
          </div>
          <div>{children}</div>
        </div>
      </div>
    )
  }

  const SingleValueComponent: FunctionComponent<
    SingleValueProps<MemberOption>
  > = ({ children, ...props }) => {
    return (
      <components.SingleValue
        {...props}
        className="flex items-center space-x-2 px-2 py-2"
      >
        <div>
          <UserAvatar size="small" user={{ ...membership }} />
        </div>
        <div>{children}</div>
      </components.SingleValue>
    )
  }

  return (
    <div className="selected-member">
      <ThemedSelect
        options={options}
        defaultValue={defaultValue}
        onChange={handleChange}
        components={{
          Option: OptionComponent,
          SingleValue: SingleValueComponent,
        }}
      />
    </div>
  )
}
