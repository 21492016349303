import React, { FunctionComponent } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { useSWR_Feed } from '../../hooks/useAPI'
import { DiscussionFeedMessages } from './DiscussionFeedMessages'
import { DiscussionFeedTitle } from './DiscussionFeedTitle'

export const DiscussionFeedContainer: FunctionComponent<{
  experienceId: string
  discussionFeedId: EntityId
}> = ({ discussionFeedId, experienceId }) => {
  const response = useSWR_Feed(discussionFeedId as EntityId)
  if (!response.data) return null

  return (
    <>
      <DiscussionFeedTitle
        discussionFeed={response.data.data.discussion_feed}
        experienceId={experienceId}
      />
      <DiscussionFeedMessages
        discussionFeed={response.data.data.discussion_feed}
        experienceId={experienceId}
      />
    </>
  )
}
