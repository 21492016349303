import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

export interface UserAvatarProps {
  user: {
    avatar_url?: string
    first_name?: string
    last_name?: string
  }
  className?: string
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl'
}

export const UserAvatar: FunctionComponent<UserAvatarProps> = props => {
  const size = props.size || 'medium'
  const avatarUrl = props.user.avatar_url
  const firstName = props.user.first_name || ''
  const lastName = props.user.last_name || ''

  const wrapperClassName = classNames(
    'rounded-full overflow-hidden flex items-center bg-white',
    {
      'h-4 w-4': size === 'xs',
      'h-6 w-6': size === 'small',
      'w-8 h-8': size === 'medium',
      'h-10 w-10': size === 'large',
      'h-14 w-14': size === 'xl',
      'h-20 w-20': size === 'xxl',
    },
    props.className
  )

  const initialsClassName = classNames(
    'inline-flex items-center justify-center rounded-full bg-gray-500 font-medium leading-none text-white',
    {
      'min-h-4 h-4 min-w-4 w-4 text-[0.5rem]': size === 'xs',
      'min-h-6 h-6 min-w-6 w-6 text-xs': size === 'small',
      'min-h-8 h-8 min-w-8 w-8 text-md': size === 'medium',
      'min-h-10 h-10 min-w-10 w-10 text-xl': size === 'large',
      'min-h-14 h-14 min-w-14 w-14 text-xl': size === 'xl',
      'min-h-20 h-20 min-w-20 w-20 text-xl': size === 'xxl',
    },
    props.className
  )

  return avatarUrl ? (
    <div className={wrapperClassName}>
      <img
        className="min-h-full min-w-full object-cover"
        src={avatarUrl}
        alt="Avatar"
      />
    </div>
  ) : (
    <span className={initialsClassName}>
      <span>
        {firstName[0]}
        {lastName[0]}
      </span>
    </span>
  )
}
