import React, { forwardRef, FunctionComponent, MouseEventHandler } from 'react'

export interface DropdownItemProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const DropdownItem: FunctionComponent<DropdownItemProps> = forwardRef<
  HTMLDivElement,
  DropdownItemProps
>((props, ref) => {
  return (
    <div className="py-1" ref={ref}>
      <button
        onClick={props.onClick}
        className="flex w-full cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:underline focus:outline-none"
      >
        {props.children}
      </button>
    </div>
  )
})

DropdownItem.displayName = 'DropdownItem'
