import {
  addMessagesToConversation,
  addOneConversation,
  updateOneConversation,
} from '../slices/conversations'
import { store } from '../store'
import { client } from '../websocket/cableClient'

export function subscribeToConversations(): () => void {
  const subscription = client.subscriptions.create('new_conversation', {
    received: data => {
      store.dispatch(addOneConversation(data))
    },
  })

  return () => subscription.unsubscribe()
}

export function subscribeToConversationUpdates(): () => void {
  const subscription = client.subscriptions.create('updated_conversation', {
    received: ({ id, ...changes }) => {
      store.dispatch(updateOneConversation({ id, changes }))
    },
  })

  return () => subscription.unsubscribe()
}

export function subscribeToMessages(): () => void {
  const subscription = client.subscriptions.create('new_message', {
    received: message => {
      store.dispatch(
        addMessagesToConversation({
          conversationId: message.conversation_id,
          messages: [message],
        })
      )
    },
  })

  return () => subscription.unsubscribe()
}

export function subscribeToOnlinePresence(): () => void {
  const subscription = client.subscriptions.create('online_presence', {
    received: () => {
      // Future releases
    },
  })

  return () => subscription.unsubscribe()
}
