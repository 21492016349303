import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { NamedProps } from 'react-select'
import classNames from 'classnames'
import { Formik } from 'formik'
import { ApiModel_ExperienceMembership } from '../../hooks/useAPI'
import { useUpdateMembership } from '../../hooks/useUpdateMembership'
import { SelectField } from '../SelectField/SelectField'

const accessLevelLabels = {
  creator: 'Creator',
  collaborator: 'Co-creator',
  attendee: 'Attendee',
  pending: 'Pending',
}

type AccessLevel = ApiModel_ExperienceMembership['access_level']

export const ExperienceMembersPageAccessLevelSwitcher: FunctionComponent<{
  user: ApiModel_ExperienceMembership
  canEdit?: boolean
}> = ({ user, canEdit }) => {
  const enable = canEdit && user.access_level != 'creator'

  const accessLevels = useMemo((): AccessLevel[] => {
    switch (user.access_level) {
      case 'creator':
        return ['creator', 'collaborator', 'attendee']
      case 'pending':
        return ['pending']
      default:
        return ['collaborator', 'attendee']
    }
  }, [user.access_level])

  const isOptionDisabled = useCallback<
    NonNullable<NamedProps['isOptionDisabled']>
  >(option => option.value === 'creator', [])

  const options = useMemo(
    () =>
      accessLevels.map(level => ({
        label: accessLevelLabels[level],
        value: level,
      })),
    [accessLevels]
  )

  const initialValues = useMemo(
    () => ({ access_level: user.access_level }),
    [user.access_level]
  )

  const updateMembership = useUpdateMembership()

  const handleSubmit = useCallback(
    async (formValues, helpers) => {
      await updateMembership(
        user.id,
        formValues,
        `Successfully set ${user.first_name} ${user.last_name} to ${formValues.access_level}`
      )

      helpers.setSubmitting(false)
    },
    [updateMembership, user]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formik => (
        <div
          className={classNames('access-switcher flex flex-col', {
            'animate-pulse': formik.isSubmitting,
          })}
        >
          <SelectField
            inputId={user.id as string}
            className="block"
            name="access_level"
            onChange={formik.submitForm}
            options={options}
            isDisabled={
              formik.isSubmitting || !enable || user.access_level === 'pending'
            }
            isOptionDisabled={isOptionDisabled}
            isClearable={false}
            ariaLabel="Access Level"
            isSearchable={false}
            noBorder
          />
        </div>
      )}
    </Formik>
  )
}
