import React, { FunctionComponent, useCallback } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { mutate } from 'swr'
import { apiExperiencePath, apiSectionPath } from '../../helpers/apiPaths'
import { ExperienceSectionVisibilityButton } from './ExperienceSectionVisibilityButton'

export interface ExperienceSectionSectionVisibilityButtonProps {
  experienceId: EntityId
  sectionId: EntityId
  hidden: boolean
  setState: React.Dispatch<React.SetStateAction<any>>
}

export const ExperienceSectionSectionVisibilityButton: FunctionComponent<
  ExperienceSectionSectionVisibilityButtonProps
> = ({ experienceId, sectionId, hidden, setState }) => {
  const handleClick = useCallback(
    async function toggleVisibility(event) {
      event.preventDefault()
      await updateVisibility(sectionId, !hidden)
      setState('initial')
      mutate(apiExperiencePath(experienceId))
    },
    [experienceId, sectionId, hidden, setState]
  )
  return (
    <ExperienceSectionVisibilityButton
      type="section"
      hidden={hidden}
      onClick={handleClick}
    />
  )
}

async function updateVisibility(sectionId: EntityId, hidden: boolean) {
  await fetch(apiSectionPath(sectionId), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      experience_section: {
        hidden: hidden,
      },
    }),
  })
}
