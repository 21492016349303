import React, { FunctionComponent } from 'react'
import { experienceEvidencesPath } from '../../helpers/paths'
import { useSWR_Experience } from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { TopMenuBackArrow } from '../TopMenuBackArrow/TopMenuBackArrow'
import { ExperienceTopMenuOverview } from './ExperienceTopMenuOverview'
import { ExperienceTopMenuWrapper } from './ExperienceTopMenuWrapper'

export const ExperienceTopMenuEvidence: FunctionComponent = () => {
  const experienceId = useExperienceId()
  const { data } = useSWR_Experience(experienceId)
  if (!data) return null
  const title = `${data.data.experience.title || 'Untitled'} - Evidence`

  return (
    <ExperienceTopMenuWrapper>
      <TopMenuBackArrow
        to={experienceEvidencesPath(experienceId)}
        title="Back to the evidence list"
      />
      <ExperienceTopMenuOverview title={title} />
    </ExperienceTopMenuWrapper>
  )
}
