import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types/store'
import { getSortedRecordPropertiesAll } from './recordProperties'

export const getCommunityUserRecordProperties = createSelector(
  getSortedRecordPropertiesAll,
  recordProperties =>
    recordProperties.filter(
      recordProperty => recordProperty.recordType === 'community_user'
    )
)

export const getSearchableCommunityUserRecordProperties = createSelector(
  getCommunityUserRecordProperties,
  recordProperties =>
    recordProperties.filter(recordProperty => recordProperty.config.searchable)
)

export const getCommunityUserGroupRecordProperty = createSelector(
  getCommunityUserRecordProperties,
  recordProperties =>
    recordProperties.find(recordProperty => recordProperty.key === 'group')
)
export const getCommunityUserGroupValues = createSelector(
  getCommunityUserGroupRecordProperty,
  recordProperty => recordProperty?.config.values || []
)
export const getCommunityUserGroupValueNameByKey = createSelector(
  getCommunityUserGroupValues,
  (_: RootState, key: string) => key,
  (groupValues, key) => groupValues.find(value => value.key === key)?.value
)

export const getCommunityUserMemberTypeRecordProperty = createSelector(
  getCommunityUserRecordProperties,
  recordProperties =>
    recordProperties.find(
      recordProperty => recordProperty.key === 'member_type'
    )
)
export const getCommunityUserMemberTypeValues = createSelector(
  getCommunityUserMemberTypeRecordProperty,
  recordProperty => recordProperty?.config.values || []
)
export const getCommunityUserMemberTypeValueNameByKey = createSelector(
  getCommunityUserMemberTypeValues,
  (_: RootState, key: string) => key,
  (memberTypeValues, key) =>
    memberTypeValues.find(value => value.key === key)?.value
)
