import { useHistory } from 'react-router'
import { createConversationFetch } from '../components/MessengerPage/createConversationFetch'
import { conversationPath } from '../helpers/paths'
import { useFlashMessages } from '../utils/useFlashMessages'

export const useFindOrCreateConversation = (): any => {
  const history = useHistory()
  const flashMessages = useFlashMessages()

  return (communityUser: any) => {
    createConversationFetch([communityUser.userId])
      .then(res => (res.ok ? Promise.resolve(res) : Promise.reject(res)))
      .then(res => res.json())
      .then(json => history.push(conversationPath(json.data.conversation.id)))
      .catch(json => {
        const key = Object.keys(json.errors)[0]
        const error = json.errors[key]
        flashMessages.show({
          type: 'error',
          text: error || 'Something went wrong',
        })
      })
  }
}
