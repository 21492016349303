import React, { FunctionComponent, InputHTMLAttributes } from 'react'
import classNames from 'classnames'

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  description?: string
}

export const Checkbox: FunctionComponent<CheckboxProps> = props => {
  const { className, ...otherProps } = props
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          className={classNames(
            'h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500',
            className
          )}
          {...otherProps}
        />
      </div>
      {props.label ||
        (props.description && (
          <div className="ml-3 text-sm">
            {props.label && (
              <label htmlFor="comments" className="font-medium text-gray-700">
                {props.label}
              </label>
            )}
            {props.description && (
              <p className="text-gray-500">{props.description}</p>
            )}
          </div>
        ))}
    </div>
  )
}
