import React from 'react'
import classNames from 'classnames'
import { ButtonContent, ButtonContentProps } from './ButtonContent'
import { ButtonSize, ButtonTheme } from './types'

type useButtonProps<ElementProps> = {
  active?: boolean
  children?: React.ReactNode
  className?: string
  fluid?: boolean
  disabled?: boolean
  left?: boolean
  size?: ButtonSize
  theme?: ButtonTheme
} & React.PropsWithChildren<ButtonContentProps & ElementProps>

export function useButton<ElementProps>({
  active,
  children,
  className,
  fluid,
  icon,
  iconSize,
  left,
  loading,
  size = children ? 'base' : 'icon',
  theme = 'primary',
  ...props
}: useButtonProps<ElementProps>): {
  content: React.ReactNode
  elementProps: ElementProps
} {
  const classes = classNames(
    'btn',
    `btn--${size}`,
    `btn--${theme}`,
    className,
    {
      'btn--fluid': fluid,
      'btn--center': !left,
      'btn--active': active,
      'btn--disabled': props.disabled,
    }
  )

  const elementProps = {
    ...(loading && { 'aria-label': 'Loading' }),
    ...props,
    className: classes,
  } as ElementProps

  const content = (
    <ButtonContent loading={loading} icon={icon} iconSize={iconSize}>
      {children}
    </ButtonContent>
  )

  return { content, elementProps }
}
