/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck

export type Learninx_Experiences_Presenters_V2ListItem = {
    /**
     * ID of the experience
     */
    id: string;
    /**
     * Title of the LX
     */
    title?: string;
    /**
     * Thumbnail urls
     */
    thumbnail: any;
    /**
     * Properties of the experience
     */
    properties: any;
    /**
     * Publishing status of the experience
     */
    status: Learninx_Experiences_Presenters_V2ListItem.status;
    /**
     * User is a member of the experience
     */
    is_member: boolean;
    /**
     * Marked complete by user, and confirmed
     */
    is_complete: boolean;
    /**
     * Marked complete by user, but not confirmed
     */
    is_done: boolean;
    /**
     * Current user's access level
     */
    access_level?: Learninx_Experiences_Presenters_V2ListItem.access_level;
};

export namespace Learninx_Experiences_Presenters_V2ListItem {

    /**
     * Publishing status of the experience
     */
    export enum status {
        DRAFT = 'draft',
        PUBLISHED = 'published',
        ARCHIVE = 'archive',
    }

    /**
     * Current user's access level
     */
    export enum access_level {
        CREATOR = 'creator',
        COLLABORATOR = 'collaborator',
        ATTENDEE = 'attendee',
        PENDING = 'pending',
    }


}

