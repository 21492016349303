import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

export type TableProps = React.TableHTMLAttributes<HTMLTableElement>

export const Table: FunctionComponent<TableProps> = ({
  className,
  ...rest
}) => {
  return (
    <div className="overflow-x-auto">
      <div className="inline-block w-full align-middle">
        <table
          {...rest}
          className={classNames(
            'w-full divide-y divide-gray-200 border-b border-t border-gray-200',
            className
          )}
        />
      </div>
    </div>
  )
}
