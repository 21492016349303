import React, { FunctionComponent } from 'react'
import { CheckCircle } from '@styled-icons/heroicons-outline'
import {
  useSWR_Experience,
  useSWR_MyExperienceMemberships,
} from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'

export const ExperienceAlertBanner: FunctionComponent = () => {
  const experienceId = useExperienceId()
  const response = useSWR_Experience(experienceId)
  const experience = response.data?.data.experience
  const { data: membershipData } = useSWR_MyExperienceMemberships(experienceId)

  if (!membershipData || !experience) return null

  const membership = membershipData.data.experience_memberships[0]

  if (!membership) return null

  const containerStyles = [
    'text-sm',
    'border',
    'shadow',
    'flex',
    'flex-col',
    'flex-wrap',
    'sm:flex-nowrap',
    'justify-center',
    'sm:justify-between',
    'gap-2',
    'my-4',
    'p-4',
    'rounded-md',
  ].join(' ')

  return (
    <div>
      {experience.status !== 'published' &&
        membership.access_level === 'attendee' && (
          <div className={containerStyles + ' bg-red-50'}>
            <h2 className="text-base font-semibold">
              This LX is no longer published. You can still view your
              completions and feedback but you cannot interact further with it
              until it is published again.
            </h2>
          </div>
        )}

      {membership.done_at && !membership.confirmed_at && (
        <div className={containerStyles + ' bg-blue-50'}>
          <h2 className="text-base font-semibold">
            You marked this LX as done
          </h2>
          <ul className="ml-4 list-disc">
            <li>confirmation from the LX Creators is pending</li>
            <li>
              your completion of the Big Ideas and Skills hasn&apos;t been
              approved yet
            </li>
          </ul>
        </div>
      )}

      {membership.confirmed_at && (
        <div className={containerStyles + ' bg-green-50'}>
          <h2 className="flex items-center space-x-1 text-base font-medium text-green-900">
            <CheckCircle size={28} />
            <span>You have completed this LX</span>
          </h2>
        </div>
      )}
    </div>
  )
}
