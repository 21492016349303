import { BackButtonUrlState } from '../slices/backButtonUrl'
import { CollapsedSectionIdsState } from '../slices/collapsedSectionIds'
import { RequestsState } from '../slices/requests'
import { EntitiesState, RootState } from '../types/store'

export const getEntities = (state: RootState): EntitiesState => state.entities

export const getRequests = (state: RootState): RequestsState => state.requests

export const getCollapsedSectionIds = (
  state: RootState
): CollapsedSectionIdsState => state.collapsedSectionIds

export const getBackButtonUrl = (state: RootState): BackButtonUrlState =>
  state.backButtonUrl
