import { createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit'

export type ExperienceFieldIdToInputId = Record<EntityId, EntityId>

export const initialState: ExperienceFieldIdToInputId = {}

export const slice = createSlice({
  name: 'experienceFieldIdToInputId',
  initialState: initialState as ExperienceFieldIdToInputId,
  reducers: {
    setExperienceFieldIdToInputId: (
      _,
      action: PayloadAction<ExperienceFieldIdToInputId>
    ) => action.payload,
  },
})

export const { setExperienceFieldIdToInputId } = slice.actions

export const { reducer } = slice
