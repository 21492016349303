import React, {
  ChangeEventHandler,
  FunctionComponent,
  KeyboardEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'tippy.js/dist/tippy.css'
import { EntityId } from '@reduxjs/toolkit'
import {
  Check,
  ChevronDown,
  ChevronRight,
  EyeOff,
  Pencil,
  Trash,
  X,
} from '@styled-icons/heroicons-outline'
import { DragHandle } from '@styled-icons/material'
import Tippy, { useSingleton } from '@tippyjs/react'
import classNames from 'classnames'
import { useFormik } from 'formik'
import { mutate } from 'swr'
import {
  apiExperiencePath,
  apiExperienceTrashPath,
} from '../../helpers/apiPaths'
import { useOnOutsideClick } from '../../hooks/useOnClickOutside'
import { getCollapsedSectionIds } from '../../selectors/base'
import { getSelectedExperience } from '../../selectors/experiences'
import { toggleSectionCollapsedId } from '../../slices/collapsedSectionIds'
import { fetchDelete } from '../../utils/fetchJson'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { useSelectorOrThrow } from '../../utils/useSelectorOrThrow'
import { Dropdown } from '../Dropdown/Dropdown'
import { DropdownItem } from '../Dropdown/DropdownItem'
import { renameExperienceSectionFetch } from '../ExperienceSideMenu/renameExperienceSectionFetch'
import { SpinnerIcon } from '../SpinnerIcon/SpinnerIcon'
import { ExperienceSectionSectionVisibilityButton } from './ExperienceSectionSectionVisibilityButton'
import { ExperienceSectionStateIcon } from './ExperienceSectionStateIcon'

export interface ExperienceSectionTitleProps {
  sectionId: EntityId
  title: string
  hidden: boolean
  canEditExperience?: boolean
  isDragging?: boolean
  enableHoverStyles?: boolean
}

export interface ExperienceSectionTitleRenameFormValues {
  name: string
}

export type ExperienceSectionTitleStates =
  | 'initial'
  | 'editing'
  | 'submitting'
  | 'success'
  | 'error'
  | 'menu'

export const ExperienceSectionTitle: FunctionComponent<
  ExperienceSectionTitleProps
> = ({
  sectionId,
  title,
  hidden,
  canEditExperience,
  isDragging,
  enableHoverStyles,
  ...dragHandleProps
}) => {
  const dispatch = useDispatch()
  const isSectionCollapsed = useSelector(getCollapsedSectionIds)[sectionId]
  const flashMessages = useFlashMessages()

  const toggleCollapsed = useCallback(() => {
    dispatch(toggleSectionCollapsedId(sectionId))
  }, [dispatch, sectionId])

  const selectedExperience = useSelectorOrThrow(getSelectedExperience)

  const [state, setState] = useState<ExperienceSectionTitleStates>('initial')
  const isRenaming = state === 'editing' || state === 'submitting'

  const formik = useFormik<ExperienceSectionTitleRenameFormValues>({
    enableReinitialize: true,
    initialValues: {
      name: title.toLowerCase() === 'untitled' ? '' : title,
    },
    onSubmit: async (formValues, helpers) => {
      const isNameBlank = formValues.name.replaceAll(' ', '').length === 0
      const isNameSame = formValues.name === title
      if (isNameSame || isNameBlank) {
        cancelRenaming()
        return
      }
      setState('submitting')
      try {
        await renameExperienceSectionFetch(sectionId, formValues.name)
        await mutate(apiExperiencePath(selectedExperience.id))
        setState('initial')
      } catch {
        setState('error')
        helpers.resetForm()
      }
    },
  })

  const handleOnNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value } }) =>
      void formik.setFieldValue('name', value.toLowerCase()),
    [formik]
  )

  const handleOnMenuIconClick = useCallback(
    () => setState(state === 'menu' ? 'initial' : 'menu'),
    [state]
  )

  const handleOnRenameClick = useCallback(() => setState('editing'), [])

  const handleOnDeleteClick = useCallback(async () => {
    setState('submitting')
    const response = await fetchDelete(
      `/api/v1/experience_sections/${sectionId}`
    )

    if (response.ok) {
      await mutate(apiExperiencePath(selectedExperience.id))
      await mutate(apiExperienceTrashPath(selectedExperience.id))
      flashMessages.show({
        type: 'success',
        text: `Section deleted: ${title}`,
      })
    } else {
      flashMessages.show({
        type: 'error',
        text: `Section could not be deleted: ${title}`,
      })
      setState('error')
    }
  }, [flashMessages, sectionId, selectedExperience.id, title])

  const cancelRenaming = useCallback(() => {
    formik.resetForm()
    setState('initial')
  }, [formik])

  const handleOnNameFieldKeyDown = useCallback<
    KeyboardEventHandler<HTMLInputElement>
  >(
    event => {
      if (event.key == 'Enter') {
        event.preventDefault()
        formik.submitForm()
      } else if (event.key == 'Escape') {
        event.stopPropagation() // avoid closing the drawer menu in Layout.tsx
        event.preventDefault()
        cancelRenaming()
      }
    },
    [formik, cancelRenaming]
  )

  const [dotsRef, menuRef] = useOnOutsideClick(
    () => setState('initial'),
    [sectionId],
    2
  )

  const [formRef] = useOnOutsideClick(formik.submitForm, [sectionId], 2)

  const dynamicTitle = useMemo(
    () =>
      state === 'editing' || state === 'submitting'
        ? formik.getFieldProps('name').value
        : title,
    [formik, title, state]
  )

  const [tippySource, tippyTarget] = useSingleton()
  const iconStyle = useMemo(() => ({ display: 'block' }), [])

  return (
    <div className="group relative mb-2 w-full select-none">
      <Tippy singleton={tippySource} delay={500} />
      {isRenaming ? (
        <form
          className="section flex w-full space-x-2 text-gray-400"
          onSubmit={formik.handleSubmit}
          ref={formRef as any}
        >
          <div />
          <input
            autoFocus
            name="name"
            type="text"
            className={inputClassName}
            onChange={handleOnNameChange}
            onKeyDown={handleOnNameFieldKeyDown}
            placeholder={title}
            value={formik.values.name}
            disabled={state === 'submitting'}
          />
          {state === 'submitting' ? (
            <SpinnerIcon size={16} className="animate-spin" />
          ) : (
            <>
              <Tippy content="Confirm" singleton={tippyTarget}>
                <button
                  type="submit"
                  className="focus:text-gray-600"
                  onClick={formik.submitForm}
                >
                  <Check size={16} style={iconStyle} />
                </button>
              </Tippy>
              <Tippy content="Cancel" singleton={tippyTarget}>
                <button
                  className="focus:text-gray-600"
                  onClick={cancelRenaming}
                >
                  <X size={16} style={iconStyle} />
                </button>
              </Tippy>
            </>
          )}
        </form>
      ) : (
        <div className="sidemenu-link-wrapper section flex w-full items-center space-x-2 py-2 text-gray-500">
          <button
            onClick={toggleCollapsed}
            className="sidemenu-link-button section-title flex flex-grow cursor-pointer items-center focus:underline focus:outline-none"
            title="Toggle Section"
          >
            <div className="flex-none flex-grow-0 cursor-pointer">
              {isSectionCollapsed ? (
                <ChevronRight size={16} />
              ) : (
                <ChevronDown size={16} />
              )}
            </div>
            <div className={classNames(titleClassName, 'text-left')}>
              {dynamicTitle}
            </div>
          </button>
          {canEditExperience && (
            <>
              {hidden && (
                <Tippy content="Invisible for attendees" placement="bottom">
                  <EyeOff
                    size={18}
                    className="section-hidden-indicator flex-none"
                  />
                </Tippy>
              )}
              <div
                className={classNames(
                  'sidemenu-link-button section-actions-button sm:hidden',
                  {
                    'group-hover:flex': enableHoverStyles,
                  }
                )}
              >
                <ExperienceSectionStateIcon
                  state={state}
                  ref={dotsRef as any}
                  onMenuClick={handleOnMenuIconClick}
                />
              </div>
              <button
                className={classNames('sidemenu-link-button', {
                  'group-hover:flex': enableHoverStyles,
                  'sm:hidden': !isDragging,
                })}
                {...dragHandleProps}
                tabIndex={-1}
              >
                <DragHandle size={16} className="text-gray-500" />
              </button>
            </>
          )}
        </div>
      )}

      {state === 'menu' && (
        <Dropdown ref={menuRef as any} marginTop={0}>
          <ExperienceSectionSectionVisibilityButton
            experienceId={selectedExperience.id}
            sectionId={sectionId}
            hidden={hidden}
            setState={setState}
          />
          <DropdownItem onClick={handleOnRenameClick}>
            <Pencil
              size={20}
              className="mr-3 text-gray-400 focus:underline focus:outline-none group-hover:text-gray-500"
            />
            Rename
          </DropdownItem>
          <DropdownItem onClick={handleOnDeleteClick}>
            <Trash
              size={20}
              className="mr-3 text-gray-400 focus:underline focus:outline-none group-hover:text-gray-500"
            />
            Delete
          </DropdownItem>
        </Dropdown>
      )}
    </div>
  )
}

export const titleClassName =
  'flex-grow break-all uppercase text-xs font-semibold truncate'

export const inputClassName = classNames(
  titleClassName,
  'inline p-0 border-0 ring-1 bg-white shadow-gray-200 h-4'
)
