import React, { AnchorHTMLAttributes, FunctionComponent } from 'react'
import { ButtonBaseProps } from './types'
import { useButton } from './useButton'

type ButtonAnchorProps = ButtonBaseProps &
  AnchorHTMLAttributes<HTMLAnchorElement>

/**
 * Anchor tag with Button styles. Use for external links.
 *
 * Don't pass simple icons as children, pass them as an icon prop.
 * If you do pass children, ensure that they contain a meaningful description.
 * Otherwise, pass a meaningful description using one of the other accessible
 * attributes (listed in src/Button/types.ts - DiscernibleText).
 */
export const ButtonAnchor: FunctionComponent<ButtonAnchorProps> = props => {
  const { content, elementProps } = useButton(props)

  return props.disabled ? (
    <span {...elementProps}>{content}</span>
  ) : (
    <a {...elementProps}>{content}</a>
  )
}
