import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  Learninx_Notifications_Presenters_V2ListItem,
  NotificationsService,
} from '../../generatedFetchers'
import {
  getNextPageParam,
  getPreviousPageParam,
  usePostError,
} from '../../utils/queryFns'

const { getV2Notifications: list, postV2NotificationsReads: markRead } =
  NotificationsService

// Sorry for the bad name, Notification conflicts with the browser Notification
export type LLNotification = Learninx_Notifications_Presenters_V2ListItem
type listParams = Parameters<typeof list>[0]
export const notificationsQueryKey = (params: listParams) => [
  'notifications',
  params,
]

export const dashboardNotifications = [
  'task_due',
  'task_submission',
  'membership_request',
]

const useNotifications = (
  params: Omit<listParams, 'paginationPage'>,
  qkey?: QueryKey
) => {
  return useInfiniteQuery({
    queryKey: qkey ?? notificationsQueryKey(params),
    getNextPageParam,
    getPreviousPageParam,

    queryFn: ({ pageParam }) => {
      return list({
        ...params,
        paginationPage: pageParam,
      })
    },
  })
}

export const useBellNotifications = (
  params?: Omit<listParams, 'only' | 'except' | 'paginationPage'>
) => {
  return useNotifications({ ...params, except: dashboardNotifications })
}

export const useDashboardNotifications = (category: string) => {
  return useNotifications({ only: [category], excludeRead: true }, [
    'notifications',
    category,
  ])
}

export const useReadDashboardNotifications = (category?: string) => {
  const client = useQueryClient()

  return useMutation({
    mutationFn: markRead,
    onError: usePostError(),
    onSettled: () => client.invalidateQueries(['notifications', category]),
  })
}

export const useReadBellNotifications = () => {
  const client = useQueryClient()
  const query = { except: dashboardNotifications }

  return useMutation({
    mutationFn: () => markRead(query),
    onError: usePostError(),
    onSettled: () => client.invalidateQueries(notificationsQueryKey(query)),
  })
}
