import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router'
import { EntityId } from '@reduxjs/toolkit'
import {
  ChatAlt2,
  ClipboardList,
  DocumentText,
} from '@styled-icons/heroicons-outline'
import { Check, ExclamationCircle, Plus } from '@styled-icons/heroicons-solid'
import Tippy from '@tippyjs/react'
import { stringifyUrl } from 'query-string'
import { mutate } from 'swr'
import { apiExperiencePath } from '../../helpers/apiPaths'
import {
  discussionFeedsPath,
  experienceDocumentEditPath,
  noticeBoardsPath,
} from '../../helpers/paths'
import { getSelectedExperienceId } from '../../selectors/experiences'
import { useSelectorOrThrow } from '../../utils/useSelectorOrThrow'
import { Button } from '../Button/Button'
import { AutocloseDropDown } from '../Dropdown/Dropdown'
import { DropdownItem } from '../Dropdown/DropdownItem'
import { useAddDiscussionFeedFetch } from './addDiscussionFeedFetch'
import { addExperienceDocumentFetch } from './addExperienceDocumentFetch'

export interface ExperienceSideMenuPageButtonProps {
  sectionId: EntityId
}

export type ExperienceSideMenuPageButtonStates =
  | 'initial'
  | 'submitting'
  | 'success'
  | 'error'

export const ExperienceSideMenuAddPageButton: FunctionComponent<
  ExperienceSideMenuPageButtonProps
> = ({ sectionId }) => {
  const [state, setState] =
    useState<ExperienceSideMenuPageButtonStates>('initial')
  useEffect(
    function temporaryStateReset() {
      if (state === 'success' || state === 'error') {
        const resetState = () => setState('initial')
        const timeoutId = setTimeout(resetState, 1000)
        return () => clearTimeout(timeoutId)
      }
    },
    [state]
  )

  const history = useHistory()
  const experienceId = useSelectorOrThrow(getSelectedExperienceId)

  const handleOnAddDocumentClick = useCallback(async () => {
    setState('submitting')
    try {
      const response = await addExperienceDocumentFetch(sectionId)
      await mutate(apiExperiencePath(experienceId))
      setState('success')
      history.push(
        stringifyUrl({
          url: experienceDocumentEditPath(
            experienceId,
            response.data.experience_doc.id
          ),
          query: { new: 1 },
        })
      )
    } catch {
      setState('error')
    }
  }, [experienceId, history, sectionId])

  const addDiscussionFeedFetch = useAddDiscussionFeedFetch()
  const handleOnAddDiscussionFeedClick = useCallback(
    async type => {
      setState('submitting')
      try {
        const response = await addDiscussionFeedFetch(sectionId, type)
        await mutate(apiExperiencePath(experienceId))
        setState('success')
        history.push(
          stringifyUrl({
            url:
              type === 'feed'
                ? discussionFeedsPath(experienceId, response.payload.id)
                : noticeBoardsPath(experienceId, response.payload.id),
            query: { new: 1 },
          })
        )
      } catch {
        setState('error')
      }
    },
    [experienceId, history, sectionId, addDiscussionFeedFetch]
  )

  const icon = useMemo(() => {
    switch (state) {
      case 'error':
        return ExclamationCircle
      case 'success':
        return Check
      default:
        return Plus
    }
  }, [state])

  const [dropDownOpen, setDropDownOpen] = useState(false)
  const openDropDown = useCallback(() => setDropDownOpen(true), [])
  const closeDropDown = useCallback(() => setDropDownOpen(false), [])

  return (
    <>
      <div className="relative">
        <AutocloseDropDown isOpen={dropDownOpen} onClose={closeDropDown}>
          <DropdownItem onClick={handleOnAddDocumentClick}>
            <Tippy placement="right" content="Creates a new content page">
              <div className="content-page-button flex items-center space-x-1">
                <DocumentText className="h-6 w-6 text-gray-500" />
                <span>Content Page</span>
              </div>
            </Tippy>
          </DropdownItem>
          <DropdownItem onClick={() => handleOnAddDiscussionFeedClick('feed')}>
            <Tippy
              placement="right"
              content="Creates a new discussion feed page"
            >
              <div className="discussion-feed-button flex items-center space-x-1">
                <ChatAlt2 className="h-6 w-6 text-gray-500" />
                <span>Discussion Feed</span>
              </div>
            </Tippy>
          </DropdownItem>
          <DropdownItem
            onClick={() => handleOnAddDiscussionFeedClick('noticeboard')}
          >
            <Tippy placement="right" content="Creates a new notice board page">
              <div className="discussion-feed-button flex items-center space-x-1">
                <ClipboardList className="h-6 w-6 text-gray-500" />
                <span>Notice Board</span>
              </div>
            </Tippy>
          </DropdownItem>
        </AutocloseDropDown>
      </div>
      <Button
        loading={state === 'submitting'}
        icon={icon}
        onClick={openDropDown}
        fluid
        theme="text"
        className="hover:bg-gray-100"
      >
        Add page
      </Button>
    </>
  )
}
