import React from 'react'
import { TElement } from '@udecode/plate'
import { SlateElementComponent } from '../../config/types'
import {
  CalloutChildrenDiv,
  CalloutDiv,
  CalloutEmojiDiv,
  CalloutNimbleEmoji,
} from '../callout'
import { CalloutRoleSwitch } from '../CalloutRoleSwitch'
import { inputClassName } from '../formFieldClasses'
import { ELEMENT_FILE_FIELD } from './createFileFieldPlugin'
import { FileFieldInput } from './FileFieldInput'

export type FileFieldElement = TElement<{
  type: ELEMENT_FILE_FIELD
  fieldId: string
}>

export const FileFieldElement: SlateElementComponent = ({
  attributes,
  element,
  children,
}) => {
  return (
    <div {...attributes}>
      <CalloutRoleSwitch>
        <CalloutDiv>
          <CalloutEmojiDiv>
            <CalloutNimbleEmoji emoji={':floppy_disk:'} />
          </CalloutEmojiDiv>
          <CalloutChildrenDiv>
            <FileFieldInput
              id={element.fieldId}
              fieldId={element.fieldId}
              placeholder="No file selected"
              className={inputClassName}
            />
          </CalloutChildrenDiv>
        </CalloutDiv>
      </CalloutRoleSwitch>
      {children}
    </div>
  )
}
