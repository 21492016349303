import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

export interface PageItemProps {
  page: number
  active?: boolean
  onClick?: (page: number) => void
}

export const PageItem: FunctionComponent<PageItemProps> = props => {
  return (
    <button
      className={classNames(
        'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium hover:bg-gray-50',
        {
          'text-blue-600': props.active,
          'text-gray-700': !props.active,
        }
      )}
      onClick={() => {
        props.onClick && props.onClick(props.page)
      }}
    >
      {props.page}
    </button>
  )
}
