import React, {
  ButtonHTMLAttributes,
  forwardRef,
  FunctionComponent,
  useState,
} from 'react'
import { DotsHorizontal } from '@styled-icons/heroicons-solid'
import classNames from 'classnames'
import { getSelectedExperience } from '../../selectors/experiences'
import { useSelectorOrThrow } from '../../utils/useSelectorOrThrow'
import { Button, ButtonProps } from '../Button/Button'
import { ButtonAttributes } from '../Button/types'
import { SpinnerIcon } from '../SpinnerIcon/SpinnerIcon'
import { ExperienceStatus_ArchivedView } from './ExperienceStatus_ArchivedView'
import { ExperienceStatus_DraftView } from './ExperienceStatus_DraftView'
import { ExperienceStatus_PublishedView } from './ExperienceStatus_PublishedView'

export const ExperienceStatus: FunctionComponent = () => {
  const [saving, setSaving] = useState(false)
  const experience = useSelectorOrThrow(getSelectedExperience)
  if (saving) return <SpinnerIcon className="animate-spin" />
  switch (experience.status) {
    case 'draft':
      return <ExperienceStatus_DraftView setSaving={setSaving} />
    case 'published':
      return <ExperienceStatus_PublishedView setSaving={setSaving} />
    case 'archived':
      return <ExperienceStatus_ArchivedView setSaving={setSaving} />
    default:
      return null
  }
}
export interface SetSavingProps {
  setSaving: React.Dispatch<React.SetStateAction<boolean>>
}

export const ExperienceStatus_Container: FunctionComponent = props => {
  return <div className="hidden items-center gap-2 sm:flex" {...props} />
}

export const ExperienceStatus_StatusText: FunctionComponent<{
  title: string
  textColor: string
}> = ({ title, textColor }) => {
  return (
    <div
      tabIndex={0}
      className={classNames(textColor, 'px-1 text-sm font-semibold')}
    >
      &#9679;&nbsp;{title}
    </div>
  )
}

export const ExperienceStatus_MenuButton: FunctionComponent<
  ButtonAttributes & ButtonHTMLAttributes<HTMLButtonElement>
> = props => {
  return (
    <Button
      theme="dark"
      title="Experience Publishing Menu"
      {...props}
      icon={DotsHorizontal}
    />
  )
}

export const ExperienceStatus_CallToAction = forwardRef<
  HTMLButtonElement,
  ButtonProps
>((props, ref) => {
  return <Button ref={ref} className="whitespace-nowrap" {...props} />
})

ExperienceStatus_CallToAction.displayName = 'ExperienceStatus_CallToAction'
