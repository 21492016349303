import React, { FunctionComponent, useCallback, useState } from 'react'
import Popup from 'reactjs-popup'
import { EntityId } from '@reduxjs/toolkit'
import { Trash } from '@styled-icons/heroicons-outline'
import { Plate } from '@udecode/plate-core'
import { formatDistance } from 'date-fns'
import { mutate } from 'swr'
import { useMe } from '../../features/users/useMe'
import { apiDiscussionFeedMessagesPath } from '../../helpers/apiPaths'
import { useAPI_CanEditExperience } from '../../hooks/useAPI'
import { DiscussionFeedMessage } from '../../types/entities'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { Button } from '../Button/Button'
import { Modal } from '../Modal/Modal'
import { UserAvatar } from '../UserAvatar/UserAvatar'

export const DiscussionFeedMessageCard: FunctionComponent<{
  discussionFeedMessage: DiscussionFeedMessage
  experienceId: EntityId
}> = ({ discussionFeedMessage, experienceId }) => {
  const { user, content } = discussionFeedMessage

  const canEdit = useAPI_CanEditExperience(experienceId)
  const myProfile = useMe()
  const canDelete = canEdit || myProfile?.data?.id === user.id

  const flashMessages = useFlashMessages()

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const openConfirm = useCallback(() => setIsConfirmOpen(true), [])
  const closeConfirm = useCallback(() => setIsConfirmOpen(false), [])

  const handleDelete = useCallback(async () => {
    const response = await fetch(
      `/api/v1/discussion_feed_messages/${discussionFeedMessage.id}`,
      {
        method: 'DELETE',
      }
    )
    if (response.ok) {
      flashMessages.show({
        type: 'success',
        duration: 5000,
        text: 'The message was successfully deleted.',
      })
      await mutate(
        apiDiscussionFeedMessagesPath(discussionFeedMessage.discussion_feed_id)
      )
    } else {
      const data = await response.json()
      const key = Object.keys(data.errors)[0]
      const error = data.errors[key]
      flashMessages.show({
        type: 'error',
        text: error || 'Something went wrong',
      })
    }
  }, [discussionFeedMessage, flashMessages])

  return (
    <div className="mt-14 sm:mt-6 sm:mb-6">
      <div className="rounded bg-white p-8 shadow-sm">
        <div className="flex flex-col">
          <div className="flex">
            <UserAvatar
              size="large"
              className="mr-4 flex-shrink-0"
              user={user}
            />
            <div className="flex flex-col justify-center">
              <span className="text-sm font-medium leading-5">
                {user.first_name} {user.last_name}
              </span>
              <span className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-400">
                {formatDistance(
                  new Date(discussionFeedMessage.created_at),
                  new Date(),
                  {
                    includeSeconds: true,
                    addSuffix: true,
                  }
                )}
              </span>
              <div className="flex flex-1 py-4">
                <Plate
                  id={discussionFeedMessage.id as string}
                  value={content}
                  editableProps={{ readOnly: true }}
                />
              </div>
            </div>
            {canDelete && (
              <>
                <div className="ml-auto cursor-pointer justify-self-end">
                  <Trash size={22} onClick={openConfirm} />
                </div>
                <div>
                  <Popup
                    modal
                    lockScroll
                    position="center center"
                    closeOnDocumentClick={false}
                    closeOnEscape
                    open={isConfirmOpen}
                    onClose={closeConfirm}
                    onOpen={openConfirm}
                  >
                    <Modal
                      icon={<Trash size={24} />}
                      title="Delete Message"
                      description="Are you sure you want to delete this message? Once deleted, you won’t be able to restore it."
                      buttons={
                        <>
                          <Button
                            onClick={() => {
                              closeConfirm()
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              closeConfirm()
                              handleDelete()
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      }
                    />
                  </Popup>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
