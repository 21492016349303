import { createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit'

export type SelectedExperienceIdState = EntityId | undefined

export const initialState: SelectedExperienceIdState = ''

export const selectedExperienceIdSlice = createSlice({
  name: 'selectedExperienceId',
  initialState: initialState as SelectedExperienceIdState,
  reducers: {
    setSelectedExperienceId: (_, action: PayloadAction<EntityId>) =>
      action.payload,
  },
})

export const { setSelectedExperienceId } = selectedExperienceIdSlice.actions

export const { reducer } = selectedExperienceIdSlice
