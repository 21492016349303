import { LocalStorageRootState } from '../types/store'

export const saveState = (state: LocalStorageRootState): void => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (error) {
    // ignore for now
  }
}

export const loadState = (): LocalStorageRootState | undefined => {
  try {
    const serializedState = localStorage.getItem('state')
    return serializedState ? JSON.parse(serializedState) : undefined
  } catch {
    return undefined
  }
}
