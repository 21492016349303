import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router'
import {
  experienceDocumentPath,
  experienceEvidenceDocumentPath,
} from '../../../helpers/paths'
import { useCurrentAttendeeMembership } from '../../../utils/useCurrentAttendeeMembership'

export const RoleSwitch: FunctionComponent<{
  attendee: JSX.Element
  creator: JSX.Element
  evidence: JSX.Element
}> = ({ attendee, creator, evidence }) => {
  const isAttendee = Boolean(useCurrentAttendeeMembership())
  return (
    <Switch>
      <Route path={experienceDocumentPath(':experienceId', ':documentId')}>
        {isAttendee ? attendee : creator}
      </Route>
      <Route
        path={experienceEvidenceDocumentPath(
          ':experienceId',
          ':membershipId',
          ':documentId'
        )}
      >
        {evidence}
      </Route>
    </Switch>
  )
}
