import React, { FunctionComponent, useCallback } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { mutate } from 'swr'
import {
  apiDiscussionFeedPath,
  apiDocumentPath,
  apiExperiencePath,
} from '../../helpers/apiPaths'
import { ExperienceSectionVisibilityButton } from './ExperienceSectionVisibilityButton'

export interface ExperienceSectionPageVisibilityButtonProps {
  experienceId: EntityId
  page: any
  hidden: boolean
  setState: React.Dispatch<React.SetStateAction<any>>
}

export const ExperienceSectionPageVisibilityButton: FunctionComponent<
  ExperienceSectionPageVisibilityButtonProps
> = ({ experienceId, page, hidden, setState }) => {
  const handleClick = useCallback(
    async function toggleVisibility(event) {
      event.preventDefault()
      page.type === 'doc'
        ? await updateDocVisibility(page.id, !hidden)
        : await updateFeedVisibility(page.id, !hidden)
      setState('initial')
      mutate(apiExperiencePath(experienceId))
    },
    [experienceId, page, hidden, setState]
  )
  return (
    <ExperienceSectionVisibilityButton
      type="page"
      hidden={hidden}
      onClick={handleClick}
    />
  )
}

async function updateDocVisibility(documentId: EntityId, hidden: boolean) {
  await fetch(apiDocumentPath(documentId), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      experience_doc: {
        hidden: hidden,
      },
    }),
  })
}

async function updateFeedVisibility(feedId: EntityId, hidden: boolean) {
  await fetch(apiDiscussionFeedPath(feedId), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      discussion_feed: {
        hidden: hidden,
      },
    }),
  })
}
