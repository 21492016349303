import React, { FunctionComponent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import { getCommunityUserMemberTypeValueNameByKey } from '../../selectors/communityUserRecordProperties'
import { RootState } from '../../types/store'

export const ExperienceMembersPageMemberTypeName: FunctionComponent<{
  memberTypeKey: string
}> = ({ memberTypeKey }) => {
  const groupName = useSelector<RootState, string | undefined>(state => {
    return getCommunityUserMemberTypeValueNameByKey(state, memberTypeKey)
  })

  const calculatedGroupName = useMemo(() => {
    return memberTypeKey.split('_').map(capitalize).join(' ')
  }, [memberTypeKey])

  return <span>{groupName || calculatedGroupName}</span>
}
