import React, { FunctionComponent, useMemo } from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import 'tippy.js/dist/tippy.css'
import { Check, X } from '@styled-icons/heroicons-outline'
import Tippy, { useSingleton } from '@tippyjs/react'
import { useOnOutsideClick } from '../../hooks/useOnClickOutside'
import { inputClassName } from '../ExperienceSection/ExperienceSectionTitle'

interface PromptStateProps {
  initialValue: string
  onConfirm: (title: string) => void
  onCancel: () => void
}

export const PromptState: FunctionComponent<PromptStateProps> = ({
  initialValue,
  onConfirm,
  onCancel,
}) => {
  const [input, setInput] = useState<string>(initialValue)
  const handleChange = useCallback(event => setInput(event.target.value), [])
  const handleConfirm = useCallback(() => {
    onConfirm(input.trim() || 'Untitled')
  }, [input, onConfirm])
  const [formRef] = useOnOutsideClick(() =>
    input.trim() ? onConfirm(input) : onCancel()
  )
  const [tippySource, tippyTarget] = useSingleton()
  const iconStyle = useMemo(() => ({ display: 'block' }), [])

  return (
    <form className="add-section" onSubmit={handleConfirm} ref={formRef as any}>
      <div />
      <input
        autoFocus
        className={inputClassName}
        onChange={handleChange}
        placeholder="Untitled"
        type="text"
        value={input}
      />
      <Tippy content="Confirm" singleton={tippyTarget}>
        <button type="submit" className="focus:text-gray-600">
          <Check size={16} style={iconStyle} />
        </button>
      </Tippy>
      <Tippy content="Cancel" singleton={tippyTarget}>
        <button onClick={onCancel} className="focus:text-gray-600">
          <X size={16} style={iconStyle} />
        </button>
      </Tippy>
      <Tippy singleton={tippySource} delay={500} />
    </form>
  )
}
