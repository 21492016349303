import React, { FunctionComponent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'
import useSWR from 'swr'
import invariant from 'tiny-invariant'
import { apiAttachmentPath } from '../../../../helpers/apiPaths'
import { useSWR_Experience } from '../../../../hooks/useAPI'
import { useExperienceId } from '../../../../hooks/useExperienceId'
import { useMembershipId } from '../../../../hooks/useMembershipId'
import { upsertOneExperienceInput } from '../../../../slices/experienceInputs'
import { ExperienceInput } from '../../../../types/entities'
import { useCurrentAttendeeMembership } from '../../../../utils/useCurrentAttendeeMembership'
import { useUpload } from '../../../ImagePicker/useUpload'
import { SpinnerIcon } from '../../../SpinnerIcon/SpinnerIcon'
import { postInput } from '../inputUtils'
import { RoleSwitch } from '../RoleSwitch'
import { useInput } from '../useInputs'

interface FileFieldInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  fieldId: EntityId
}

export const FileFieldInput: FunctionComponent<FileFieldInputProps> = props => {
  return (
    <RoleSwitch
      attendee={<AttendeeVersion {...props} />}
      creator={<CreatorVersion {...props} />}
      evidence={<EvidenceVersion {...props} />}
    />
  )
}

const AttendeeVersion: FunctionComponent<FileFieldInputProps> = ({
  fieldId,
  ...props
}) => {
  const experienceId = useExperienceId()
  const experienceResponse = useSWR_Experience(experienceId)
  const experience = experienceResponse.data?.data.experience
  const membership = useCurrentAttendeeMembership()
  invariant(membership)
  const input = useInput(fieldId, membership.id)
  const { handleChange, isUploading } = useSubmitForFileInput(input)
  const attachmentId = input.unpublished

  if (!experience) return null

  return (
    <div>
      <div className="mb-2">
        <input
          {...props}
          type="file"
          disabled={isUploading || experience.status !== 'published'}
          readOnly={isUploading || experience.status !== 'published'}
          onChange={handleChange}
        />
      </div>
      <div className="min-h-8">
        {isUploading ? (
          <UploadingIndicator />
        ) : attachmentId ? (
          <DownloadButton attachmentId={attachmentId} />
        ) : null}
      </div>
    </div>
  )
}

const CreatorVersion: FunctionComponent<FileFieldInputProps> = () => {
  return <div>File field</div>
}

const EvidenceVersion: FunctionComponent<FileFieldInputProps> = ({
  fieldId,
}) => {
  const membershipId = useMembershipId()
  const input = useInput(fieldId, membershipId)
  const attachmentId = input.unpublished
  return (
    <div className="min-h-8">
      {attachmentId && <DownloadButton attachmentId={attachmentId} />}
    </div>
  )
}

const UploadingIndicator: FunctionComponent = () => {
  return (
    <div className="text-gray-500">
      Uploading
      <SpinnerIcon className="ml-1 inline animate-spin" />
    </div>
  )
}

const DownloadButton: FunctionComponent<{ attachmentId: string }> = ({
  attachmentId,
}) => {
  const { data: previewData } = useSWR(
    attachmentId && apiAttachmentPath(attachmentId)
  )
  const previewUrl = previewData?.data?.attachment?.medium

  const [loading, setLoading] = useState<boolean>(false)
  const handleClick = async () => {
    setLoading(true)
    const res = await fetch(apiAttachmentPath(attachmentId))
    setLoading(false)

    if (!res.ok) {
      alert('Download failed')
      return
    }

    const json = await res.json()
    const url = json.data?.attachment.original
    if (!url) {
      alert('Download failed')
      return
    }
    window.open(url)
  }
  return (
    <button
      disabled={loading}
      onClick={handleClick}
      className="text-blue-500 underline"
    >
      {previewUrl ? (
        <img src={previewUrl} alt="Preview" />
      ) : (
        <span>View file</span>
      )}
    </button>
  )
}

const useSubmitForFileInput = (input: ExperienceInput) => {
  const dispatch = useDispatch()

  const onUploadSuccess = (attachmentId: string) => {
    const newInput: ExperienceInput = {
      ...input,
      unpublished: attachmentId,
    }
    dispatch(upsertOneExperienceInput(newInput))
    postInput(newInput)
  }

  const onUploadError = () => {
    alert('Upload failed')
  }

  const { upload, isUploading } = useUpload({ onUploadSuccess, onUploadError })
  const handleChange = useCallback(e => upload(e.target.files), [upload])
  return { handleChange, isUploading }
}
