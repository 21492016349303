import { Editor, Node, Path, Range, Transforms } from 'slate'

// if the next node is a void node, remove the current node and select the void node
export const withCorrectDeleteForwardBehavior = <T extends Editor>(
  editor: T
): T => {
  const { deleteForward } = editor

  editor.deleteForward = unit => {
    if (
      !editor.selection ||
      !Range.isCollapsed(editor.selection) ||
      editor.selection.anchor.offset !== 0
    ) {
      return deleteForward(unit)
    }

    const parentPath = Path.parent(editor.selection.anchor.path)
    const parentNode = Node.get(editor, parentPath)
    const parentIsEmpty = Node.string(parentNode).length === 0

    if (parentIsEmpty) {
      const nextNodePath = Path.next(parentPath)
      if (Node.has(editor, nextNodePath)) {
        const nextNode = Node.get(editor, nextNodePath)
        if (Editor.isVoid(editor, nextNode)) {
          Transforms.removeNodes(editor)
          Transforms.select(editor, parentPath)
          return
        }
      }
    }

    deleteForward(unit)
  }

  return editor
}
