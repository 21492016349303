import React, { FunctionComponent } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { memberProfileAboutPath } from '../../helpers/paths'
import { ApiModel_ExperienceMembership } from '../../hooks/useAPI'
import { CommunityMemberPageChatButton } from '../CommunityMembersPage/CommunityMemberPageChatButton'
import { StyledLink } from '../StyledLink/StyledLink'
import { TableCell } from '../Table/TableCell'
import { TableRow } from '../Table/TableRow'
import { UserAvatar } from '../UserAvatar/UserAvatar'
import { ExperienceMembersPageAccessLevelSwitcher } from './ExperienceMembersPageAccessLevelSwitcher'
import { ExperienceMembersPageDeleteButton } from './ExperienceMembersPageDeleteButton'
import { ExperienceMembersPageGroupName } from './ExperienceMembersPageGroupName'
import { ExperienceMembersPageMemberTypeName } from './ExperienceMembersPageMemberTypeName'
import { ExperienceMembersPagePendingControls } from './ExperienceMembersPagePendingControls'

export const ExperienceMembersPageTableRow: FunctionComponent<{
  user: ApiModel_ExperienceMembership
  canEdit?: boolean
  afterSubmit?: () => void
  currentUserId?: EntityId
}> = ({ user, canEdit, afterSubmit, currentUserId }) => {
  if (!user) return <></>

  return (
    <TableRow key={user.id}>
      <TableCell>
        <div className="flex min-w-0 items-center space-x-2">
          <div>
            <UserAvatar user={user} />
          </div>
          <StyledLink
            to={memberProfileAboutPath(user.community_user_id)}
            className="whitespace-normal break-words line-clamp-5"
          >
            {user.first_name} {user.last_name}
          </StyledLink>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-col justify-items-center">
          {user.properties?.member_type?.map(memberTypeKey => (
            <ExperienceMembersPageMemberTypeName
              memberTypeKey={memberTypeKey}
              key={memberTypeKey}
            />
          ))}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-col justify-items-center">
          {user.properties?.group?.map(groupKey => (
            <ExperienceMembersPageGroupName
              key={groupKey}
              groupKey={groupKey}
            />
          ))}
        </div>
      </TableCell>
      <TableCell>
        {canEdit && user.access_level === 'pending' ? (
          <ExperienceMembersPagePendingControls
            member={user}
            afterSubmit={afterSubmit}
          />
        ) : (
          <div className="w-36">
            <ExperienceMembersPageAccessLevelSwitcher
              user={user}
              canEdit={canEdit}
            />
          </div>
        )}
      </TableCell>
      <TableCell>
        <div className="flex justify-end space-x-4 text-gray-500">
          {user.community_user_id !== currentUserId && !user.archived_at && (
            <CommunityMemberPageChatButton user={user} />
          )}
          {canEdit && !['creator', 'pending'].includes(user.access_level) && (
            <ExperienceMembersPageDeleteButton
              user={user}
              afterSubmit={afterSubmit}
            />
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}
