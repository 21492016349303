import React, { FunctionComponent } from 'react'
import { Link, LinkProps, matchPath, useLocation } from 'react-router-dom'
import { ButtonBaseProps } from './types'
import { useButton } from './useButton'

const matchAnyPath = (paths: string[], currentPath: string) => {
  return paths.some(
    path => !!matchPath(path, { path: currentPath, exact: true })
  )
}

/**
 * Link with Button styling & behavior. Use for in-app navigation.
 *
 * Don't pass simple icons as children, pass them as an icon prop.
 * If you do pass children, ensure that they contain a meaningful description.
 * Otherwise, pass a meaningful description using one of the other accessible
 * attributes (listed in src/Button/types.ts - DiscernibleText).
 */
export const ButtonLink: FunctionComponent<
  ButtonBaseProps & LinkProps<HTMLAnchorElement>
> = ({ activatedBy = [], ...props }) => {
  const activatedByPaths = [props.to].concat(activatedBy)
  const currentPath = useLocation().pathname

  const active = matchAnyPath(activatedByPaths as string[], currentPath)

  const { content, elementProps } = useButton({ active, ...props })

  if (props.disabled) {
    return <span className={elementProps.className}>{content}</span>
  } else {
    return <Link {...elementProps}>{content}</Link>
  }
}
