import React, { forwardRef, FunctionComponent, ReactNode } from 'react'
import { useOnOutsideClick } from '../../hooks/useOnClickOutside'

export interface DropdownProps {
  children: ReactNode | ReactNode[]
  marginTop?: number
}

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (props, ref) => {
    const marginTop = props.marginTop === undefined ? 8 : props.marginTop
    return (
      <div
        className="absolute right-0 z-10 origin-top-right rounded-md bg-white py-1 shadow-lg"
        ref={ref}
        style={{ marginTop }}
      >
        {props.children}
      </div>
    )
  }
)

Dropdown.displayName = 'Dropdown'

export interface AutocloseDropDownProps extends DropdownProps {
  onClose: () => void
  isOpen: boolean
}

export const AutocloseDropDown: FunctionComponent<AutocloseDropDownProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  const [menuRef] = useOnOutsideClick(onClose, [])

  return <>{isOpen && <Dropdown ref={menuRef as any} {...props} />}</>
}
