import { createPluginFactory } from '@udecode/plate'

export const ELEMENT_FILE_FIELD = 'file_field'
export type ELEMENT_FILE_FIELD = typeof ELEMENT_FILE_FIELD

export const createFileFieldlugin = createPluginFactory({
  key: ELEMENT_FILE_FIELD,
  isElement: true,
  isVoid: true,
})
