import { EntityId } from '@reduxjs/toolkit'
import { insertNodes, PlateEditor } from '@udecode/plate'
import { emptyChildren } from '../../config/config'
import { AttachmentElement } from './AttachmentElement'

export const insertAttachment = (
  editor: PlateEditor,
  attachmentId: EntityId
): void => {
  const attachment: AttachmentElement = {
    attachmentId,
    children: emptyChildren,
    type: 'attachment',
    widthPercent: '60%',
  }

  insertNodes(editor, attachment)
}
