import React, { FunctionComponent, MouseEventHandler } from 'react'
import { Button } from '../Button/Button'

export interface ExperienceAddMembersNoneLeftProps {
  handleCancel?: MouseEventHandler
}

export const ExperienceAddMembersNoneLeft: FunctionComponent<
  ExperienceAddMembersNoneLeftProps
> = ({ handleCancel }) => {
  return (
    <div className="flex flex-col items-center space-y-4 px-4 pt-5 pb-4 sm:min-w-full sm:p-6 sm:pb-4">
      <div className="mt-3 text-center">
        <h3 className="text-lg font-medium text-gray-900">No members to add</h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            You have already added all members
          </p>
        </div>
      </div>
      <div className="mt-5">
        <Button
          className="flex justify-center text-center"
          fluid
          onClick={handleCancel}
        >
          Go back
        </Button>
      </div>
    </div>
  )
}
