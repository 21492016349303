import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { useFocused, useSelected } from 'slate-react'
import { RoleSwitch } from './RoleSwitch'

export const CalloutRoleSwitch: FunctionComponent = ({ children }) => {
  const selected = useSelected()
  const focused = useFocused()

  const SelectableDiv = (
    <div
      contentEditable={false}
      className={classNames({ ring: selected && focused })}
    >
      {children}
    </div>
  )

  const NonSelectableDiv = (
    <div
      contentEditable={false}
      className={classNames({ ring: selected && focused }, 'select-none')}
    >
      {children}
    </div>
  )

  return (
    <RoleSwitch
      attendee={SelectableDiv}
      creator={NonSelectableDiv}
      evidence={NonSelectableDiv}
    />
  )
}
