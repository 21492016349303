import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'
import { useDebouncedCallback } from 'use-debounce'
import { upsertOneExperienceInput } from '../../../slices/experienceInputs'
import { ExperienceInput } from '../../../types/entities'
import { fetchPost } from '../../../utils/fetchJson'

export const temporaryInput = (
  fieldId: EntityId,
  membershipId: EntityId
): ExperienceInput => ({
  id: `temp-${fieldId}-${membershipId}`,
  published: '',
  unpublished: '',
  experienceFieldId: fieldId,
  experienceMembershipId: membershipId,
})

export const postInput = async (input: ExperienceInput): Promise<Response> => {
  return await fetchPost('/api/v1/experience_inputs', {
    body: JSON.stringify({
      experience_input: {
        unpublished: input.unpublished,
        experience_field_id: input.experienceFieldId,
      },
    }),
  })
}

export const useSubmitForTextInput = (
  input: ExperienceInput
): { handleChange: (event: any) => any } => {
  const dispatch = useDispatch()
  const debounceTimeout = 1000
  const handleChangeDebounced = useDebouncedCallback(postInput, debounceTimeout)

  const handleChange = useCallback(
    event => {
      const newInput: ExperienceInput = {
        ...input,
        unpublished: event.target.value,
      }
      dispatch(upsertOneExperienceInput(newInput))
      handleChangeDebounced(newInput)
    },
    [dispatch, handleChangeDebounced, input]
  )

  return { handleChange }
}
