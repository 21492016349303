import { createPluginFactory } from '@udecode/plate'

export const ELEMENT_IMAGE = 'image'
export type ELEMENT_IMAGE = typeof ELEMENT_IMAGE

export const createImagePlugin = createPluginFactory({
  key: ELEMENT_IMAGE,
  isElement: true,
  isVoid: true,
})
