import { EntityId } from '@reduxjs/toolkit'
import { stringifyUrl } from 'query-string'
import { RecordProperty } from '../types/entities'

export const apiAttachmentsPath = (): string => '/api/v1/attachments'

export const apiAttachmentPath = (attachmentId: EntityId): string =>
  `/api/v1/attachments/${attachmentId}`

export const apiExperienceClonePath = (): string => `/api/v1/experiences/clone`

export const apiExperiencePath = (experienceId: EntityId): string =>
  `/api/v1/experiences/${experienceId}`

export const apiExperienceTrashPath = (experienceId: EntityId): string =>
  `/api/v1/experiences/trash/${experienceId}`

export const apiExperienceDocRestorePath = (): string => {
  return '/api/v1/experience_docs/restore'
}

export const apiDiscussionFeedRestorePath = (): string => {
  return '/api/v1/discussion_feeds/restore'
}

export const apiRecordPropertiesPath = (
  communityId: EntityId,
  type: RecordProperty['recordType']
): string =>
  stringifyUrl({
    url: '/api/v1/record_properties',
    query: { community_id: communityId, record_type: type },
  })

export const apiDocumentPath = (documentId: EntityId): string =>
  `/api/v1/experience_docs/${documentId}`

export const apiSectionPath = (sectionId: EntityId): string =>
  `/api/v1/experience_sections/${sectionId}`

export const apiRolesPath = (communityId: EntityId): string =>
  stringifyUrl({
    url: '/api/v1/roles',
    query: { community_id: communityId },
  })

export const apiLearningOutcomesPath = (communityId: EntityId): string =>
  stringifyUrl({
    url: '/api/v1/learning_outcomes',
    query: { community_id: communityId },
  })

export type apiExperienceMembershipsPathParams = {
  experienceId?: EntityId
  userId?: EntityId
  accessLevel?: string
  communityId?: EntityId
  page?: number
  perPage?: number
}

export const apiExperienceMembershipsPath = ({
  experienceId,
  userId,
  page,
  perPage,
  communityId,
}: apiExperienceMembershipsPathParams): string => {
  return stringifyUrl({
    url: '/api/v1/experience_memberships',
    query: {
      experience_id: experienceId,
      community_id: communityId,
      user_id: userId,
      'pagination[page]': page,
      'pagination[per_page]': perPage,
    },
  })
}

export const apiMessagesPath = (conversationId: EntityId): string =>
  `/api/v1/messages?conversation_id=${conversationId}`

export const apiConversationsPath = (): string => `/api/v1/conversations`

export const conversationsReadsPath = (conversationId: EntityId): string =>
  stringifyUrl({
    url: `${apiConversationsPath()}/reads`,
    query: {
      conversation_id: conversationId,
    },
  })

export const apiExperienceInputsPath = (
  experienceDocId: EntityId,
  experienceMembershipId: EntityId
): string =>
  stringifyUrl({
    url: '/api/v1/experience_inputs',
    query: {
      experience_membership_id: experienceMembershipId,
      experience_doc_id: experienceDocId,
    },
  })

export const apiExperienceDocCompletionsPath = (
  experienceId: EntityId
): string =>
  stringifyUrl({
    url: '/api/v1/experience_doc_completions',
    query: { experience_id: experienceId },
  })

export const apiDiscussionFeedsPath = (): string => `/api/v1/discussion_feeds`
export const apiDiscussionFeedPath = (feedId: EntityId): string =>
  `/api/v1/discussion_feeds/${feedId}`
export const apiDiscussionFeedMessagesPath = (feedId?: EntityId): string => {
  const query = feedId ? `?discussion_feed_id=${feedId}` : ''
  return `/api/v1/discussion_feed_messages${query}`
}
