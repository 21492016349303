/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck

export type putV1ExperiencesId = {
    experience: {
        /**
         * Experience title
         */
        title?: string;
        /**
         * Experience description
         */
        description?: string;
        /**
         * ID of thumbnail image
         */
        thumbnail_id?: string;
        /**
         * List of properties and its values
         */
        properties?: any;
        /**
         * Experience status
         */
        status?: putV1ExperiencesId.status;
        /**
         * Learning_outcome ids to associate with Experience
         */
        learning_outcome_ids?: Array<string>;
    };
};

export namespace putV1ExperiencesId {

    /**
     * Experience status
     */
    export enum status {
        DRAFT = 'draft',
        PUBLISHED = 'published',
        ARCHIVED = 'archived',
    }


}

