import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { InformationCircle } from '@styled-icons/heroicons-outline'
import { mutate } from 'swr'
import { apiExperiencePath } from '../../helpers/apiPaths'
import { experienceEditPath } from '../../helpers/paths'
import { useSWR_Experience } from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { getExperienceRecordPropertiesAll } from '../../selectors/experienceRecordProperties'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { Button } from '../Button/Button'
import { ButtonLink } from '../Button/ButtonLink'
import { Modal } from '../Modal/Modal'
import { ExperienceStatus_CallToAction } from './ExperienceStatus'
import { updateExperienceStatus } from './updateExperienceStatus'
export const ExperienceStatus_DraftView_PublishButton: FunctionComponent<{
  setSaving: any
}> = ({ setSaving }) => {
  const flashMessages = useFlashMessages()
  const location = useLocation()
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const experienceId = useExperienceId()
  const experience = useSWR_Experience(experienceId)
  const recordProperties = useSelector(getExperienceRecordPropertiesAll)

  // This little dance prevents an "updating state on an unmounted component"
  // console error.
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  const missingFields = useMemo(() => {
    const present = Object.keys(experience.data?.data.experience.properties)

    const missing = recordProperties
      .filter(prop => prop.config.required && !present.includes(prop.key))
      .map(prop => prop.name)

    if (!experience.data?.data.experience.description) {
      missing.unshift('Description')
    }

    return missing
  }, [recordProperties, experience.data?.data.experience])

  const missingFieldsNotification = useCallback(() => {
    const currentlyEditing =
      location.pathname === experienceEditPath(experienceId)

    if (currentlyEditing) {
      history.replace({
        pathname: location.pathname,
        search: '?flagErrors',
      })
    }

    flashMessages.show({
      type: 'error',
      children: (
        <div className="text-sm font-medium text-red-800">
          <p className="font-bold">
            Your Learning Experience could not be published.
          </p>
          <p className="mb-4 font-bold">
            The following mandatory fields have not been populated
            {currentlyEditing || ' in the overview page'}:
          </p>
          <ul className="list-inside list-disc">
            {missingFields.map((m: string) => (
              <li key={m}>{m}</li>
            ))}
          </ul>
          {currentlyEditing || (
            <>
              <hr className="my-3" />
              <ButtonLink
                to={{
                  pathname: experienceEditPath(experienceId),
                  search: '?flagErrors',
                }}
              >
                Go to the Overview page to populate these fields
              </ButtonLink>
            </>
          )}
        </div>
      ),
    })
  }, [missingFields, flashMessages, history, experienceId, location.pathname])

  const handlePublishButton = useCallback(() => {
    if (missingFields.length > 0) {
      missingFieldsNotification()
    } else {
      setModalOpen(true)
    }
  }, [missingFields, missingFieldsNotification])

  const handleConfirmPublish = useCallback(async () => {
    setSaving(true)
    try {
      const response = await updateExperienceStatus(experienceId, 'published')

      if (!response.ok) {
        throw response
      }

      mutate(apiExperiencePath(experienceId))
      flashMessages.show({
        type: 'success',
        text: 'Your Learning Experience was published successfully.',
      })
    } catch (error: any) {
      if (error.status === 422) {
        missingFieldsNotification()
      } else {
        flashMessages.show({
          type: 'error',
          text: 'Your Learning Experience could not be published.',
        })
      }
    } finally {
      setSaving(false)
    }
  }, [missingFieldsNotification, experienceId, flashMessages, setSaving])

  return (
    <>
      <ExperienceStatus_CallToAction
        id="publish-button"
        onClick={handlePublishButton}
      >
        Publish
      </ExperienceStatus_CallToAction>
      <Popup
        modal
        lockScroll
        closeOnEscape
        open={modalOpen}
        onClose={() => isMounted && setModalOpen(false)}
      >
        {(close: () => void) => {
          const handleConfirm = () => {
            close()
            handleConfirmPublish()
          }
          return (
            <Modal
              icon={<InformationCircle size={24} />}
              title="Publish Learning Experience"
              description="Members you have added to the LX will receive an invitation to join and the LX will now be visible to other community members."
              buttons={
                <>
                  <Button theme="secondary" onClick={() => setModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button onClick={handleConfirm} id="publish-confirm-button">
                    Publish
                  </Button>
                </>
              }
            />
          )
        }}
      </Popup>
    </>
  )
}
