import React, { FunctionComponent, ReactNode, useEffect, useMemo } from 'react'
import Linkify from 'linkify-react'
import { LinkifyExperienceDoc } from './LinkifyExperienceDoc'

export interface LinkifyWrapperProps {
  children: React.ReactElement
}

export const LinkifyWrapper: FunctionComponent<LinkifyWrapperProps> = ({
  children,
}) => {
  const experienceDocLinks: ReactNode[] = useMemo(() => [], [])
  const tags = document.getElementsByTagName('a')
  useEffect(() => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

    for (const tag of tags) {
      const url = new URL(tag.href)
      const path = url.pathname.split('/')
      if (
        tag.href === tag.innerText &&
        path[3] === 'document' &&
        uuidRegex.test(path[4])
      ) {
        experienceDocLinks.push(
          <LinkifyExperienceDoc documentId={path[4]} tag={tag} />
        )
      }
    }
  }, [experienceDocLinks, tags])

  const options = {
    className: 'text-blue-600 hover:underline',
    target: {
      url: '_blank',
    },
  }

  return (
    <>
      <Linkify options={options}>{children}</Linkify>
      {experienceDocLinks.map((component, index) => (
        <React.Fragment key={index}>{component}</React.Fragment>
      ))}
    </>
  )
}
