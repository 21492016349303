import React, { forwardRef, MouseEventHandler, useMemo } from 'react'
import {
  Check,
  DotsHorizontal,
  ExclamationCircle,
} from '@styled-icons/heroicons-solid'
import classNames from 'classnames'
import { SpinnerIcon } from '../SpinnerIcon/SpinnerIcon'
import { ExperienceSectionTitleStates } from './ExperienceSectionTitle'

export interface ExperienceSectionTitleIconProps {
  state: ExperienceSectionTitleStates
  onMenuClick?: MouseEventHandler<Element>
  className?: string
}

export const ExperienceSectionStateIcon = forwardRef<
  any,
  ExperienceSectionTitleIconProps
>((props, ref) => {
  const icon = useMemo(() => {
    const className = classNames('shrink-0', props.className)
    const iconSize = 16
    switch (props.state) {
      case 'success':
        return (
          <Check
            className={classNames('text-green-600', className)}
            size={iconSize}
            ref={ref}
          />
        )
      case 'submitting':
        return (
          <SpinnerIcon
            className={classNames(
              'animate-spin cursor-wait text-gray-400',
              className
            )}
            size={iconSize}
            ref={ref}
          />
        )
      case 'error':
        return (
          <ExclamationCircle
            className={classNames('text-red-600', className)}
            size={iconSize}
            ref={ref}
          />
        )
      default:
        return (
          <button className="sidemenu-link-button" onClick={props.onMenuClick}>
            <DotsHorizontal
              className={classNames(
                'cursor-pointer text-gray-400 hover:text-gray-600',
                className
              )}
              size={iconSize}
              ref={ref}
            />
          </button>
        )
    }
  }, [props.className, props.onMenuClick, props.state, ref])
  return icon
})

ExperienceSectionStateIcon.displayName = 'ExperienceSectionStateIcon'
