import { ParsedUrl, ReactEmbed } from 'react-embed'
import { insertNodes, PlateEditor } from '@udecode/plate'
import { emptyChildren } from '../../config/config'
import { ELEMENT_EMBED, EmbedElement } from '.'

export const insertEmbedElement = (editor: PlateEditor, url: string): void => {
  const embed: EmbedElement = {
    type: ELEMENT_EMBED,
    url: url,
    children: emptyChildren,
  }

  insertNodes(editor, embed)
}

// TODO: find the Right Way™ check for valid URL:
// https://github.com/streamich/react-embed/issues/627
export function isEmbedUrl(url: string): boolean {
  const { blocks, router } = ReactEmbed.defaultProps
  const routerResult = router(blocks, getParsedUrl(url))
  return Boolean(routerResult)
}

function getParsedUrl(rawUrl: string): ParsedUrl {
  const url = new URL(rawUrl)
  const { hostname, pathname, search, hash } = url
  return {
    url: rawUrl,
    hostname,
    pathname,
    search,
    hash,
  }
}
