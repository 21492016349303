/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck

export type postV1Experiences = {
    experience: {
        /**
         * Experience title
         */
        title?: string;
        /**
         * Experience description
         */
        description?: string;
        /**
         * ID of community
         */
        community_id: string;
        /**
         * ID of thumbnail image
         */
        thumbnail_id?: string;
        /**
         * List of properties and its values
         */
        properties?: any;
        /**
         * Experience status
         */
        status?: postV1Experiences.status;
    };
};

export namespace postV1Experiences {

    /**
     * Experience status
     */
    export enum status {
        DRAFT = 'draft',
        PUBLISHED = 'published',
        ARCHIVED = 'archived',
    }


}

