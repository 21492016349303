import { Notifier } from '@airbrake/browser'

export const useAirbrake = (): Notifier => {
  const airbrake = new Notifier({
    projectId: import.meta.env.VITE_AIRBRAKE_APP_ID as any,
    projectKey: import.meta.env.VITE_AIRBRAKE_APP_KEY as any,
    environment: process.env.NODE_ENV,
    remoteConfig: false,
  })

  airbrake.addFilter(e => (import.meta.env.PROD ? e : null))

  return airbrake
}
