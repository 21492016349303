import React, { FunctionComponent } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import { ExperienceAlertBanner } from '../ExperienceOverviewPage/ExperienceAlertBanner'
import { ExperienceSideMenu } from '../ExperienceSideMenu/ExperienceSideMenu'
import { ExperienceTopMenu } from '../ExperienceTopMenu/ExperienceTopMenu'
import { Layout } from '../Layout/Layout'
import { DiscussionFeedContainer } from './DiscussionFeedContainer'

export type DiscussionFeedPageProps = RouteChildrenProps<{
  experienceId?: string
  discussionFeedId?: string
}>

export const DiscussionFeedPage: FunctionComponent<
  DiscussionFeedPageProps
> = props => {
  const discussionFeedId = props.match?.params.discussionFeedId
  const experienceId = props.match?.params.experienceId

  if (!discussionFeedId) return null

  return (
    <Layout
      topComponent={<ExperienceTopMenu />}
      sideComponent={<ExperienceSideMenu />}
    >
      <div className="flex">
        <div className="flex-1">
          <div className="mx-0 mt-14 space-y-4 sm:mx-8 sm:mt-6 sm:mb-6 2xl:mr-64">
            <ExperienceAlertBanner />
            <DiscussionFeedContainer
              discussionFeedId={discussionFeedId}
              experienceId={experienceId as string}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
