import { useDispatch } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'
import { apiDiscussionFeedsPath } from '../../helpers/apiPaths'
import { addOneDiscussionFeed } from '../../slices/discussionFeeds'

export const useAddDiscussionFeedFetch = (): ((
  sectionId: EntityId,
  type: string
) => Promise<any>) => {
  const dispatch = useDispatch()

  return (sectionId: EntityId, type): Promise<any> =>
    fetch(apiDiscussionFeedsPath(), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        discussion_feed: {
          title: 'Untitled',
          parent_id: sectionId,
          ...(type === 'noticeboard' && { is_noticeboard: true }),
        },
      }),
    })
      .then(res => (res.ok ? res.json() : Promise.reject(res.json())))
      .then(res => dispatch(addOneDiscussionFeed(res.data.discussion_feed)))
}
