import React, { FunctionComponent, useCallback } from 'react'
import Popup from 'reactjs-popup'
import { Pencil } from '@styled-icons/heroicons-outline'
import { mutate } from 'swr'
import { apiExperiencePath } from '../../helpers/apiPaths'
import { getSelectedExperienceId } from '../../selectors/experiences'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { useSelectorOrThrow } from '../../utils/useSelectorOrThrow'
import { Button } from '../Button/Button'
import { DropdownItem } from '../Dropdown/DropdownItem'
import { Modal } from '../Modal/Modal'
import { SetSavingProps } from './ExperienceStatus'
import { updateExperienceStatus } from './updateExperienceStatus'

export const ExperienceStatus_DropdownMenu_DraftButton: FunctionComponent<
  SetSavingProps
> = ({ setSaving }) => {
  const experienceId = useSelectorOrThrow(getSelectedExperienceId)
  const flashMessages = useFlashMessages()

  const handleConfirmUnpublish = useCallback(async () => {
    setSaving(true)
    try {
      const response = await updateExperienceStatus(experienceId, 'draft')
      if (!response.ok) throw response.status
      await mutate(apiExperiencePath(experienceId))
      flashMessages.show({
        type: 'success',
        text: 'Your Learning Experience was unpublished successfully.',
      })
    } catch {
      flashMessages.show({
        type: 'error',
        text: 'Your Learning Experience could not be unpublished.',
      })
    }
    setSaving(false)
  }, [experienceId, flashMessages, setSaving])

  return (
    <Popup
      modal
      lockScroll
      closeOnEscape
      trigger={<DropdownItem>Unpublish</DropdownItem>}
    >
      {(close: () => void) => {
        const handleConfirm = () => {
          close()
          handleConfirmUnpublish()
        }
        return (
          <Modal
            icon={<Pencil size={24} />}
            title="Unpublish Learning Experience"
            description="The LX status will go back to Draft and will be hidden to its attendees and members of the community."
            buttons={
              <>
                <Button onClick={close} theme="secondary">
                  Cancel
                </Button>
                <Button onClick={handleConfirm}>Unpublish</Button>
              </>
            }
          />
        )
      }}
    </Popup>
  )
}
