import React, { FunctionComponent, useMemo } from 'react'
import { StyledIcon } from '@styled-icons/styled-icon'
import { SpinnerIcon } from '../SpinnerIcon/SpinnerIcon'

export interface ButtonContentProps {
  loading?: boolean
  icon?: StyledIcon
  iconSize?: number
}

export const ButtonContent: FunctionComponent<ButtonContentProps> = ({
  children,
  loading,
  icon,
  iconSize = icon && !children ? 20 : 14,
}) => {
  const iconComponent = useMemo(() => {
    if (!icon) return null

    const Component = icon

    return <Component size={iconSize} />
  }, [icon, iconSize])

  if (loading) {
    return <SpinnerIcon className="btn__loader" size={iconSize} />
  } else {
    return (
      <>
        {icon && <div className="btn__icon">{iconComponent}</div>}
        {children}
      </>
    )
  }
}
