import { createPluginFactory } from '@udecode/plate'

export const ELEMENT_TEXT_FIELD = 'text_field'
export type ELEMENT_TEXT_FIELD = typeof ELEMENT_TEXT_FIELD

export const createTextFieldlugin = createPluginFactory({
  key: ELEMENT_TEXT_FIELD,
  isElement: true,
  isVoid: true,
})
