import { sortBy } from 'lodash-es'
import { ApiModel_Get_Experience } from '../hooks/useAPI'

export const getDocumentsFromExperience = (
  experience: ApiModel_Get_Experience
): ApiModel_Get_Experience['experience_sections'][0]['experience_docs'] => {
  return experience.experience_sections.flatMap(s =>
    sortBy(s.experience_docs, 'sort_position')
  )
}
