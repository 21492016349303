import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { Community } from '../types/entities'

export type CommunitiesState = EntityState<Community>

export const communitiesAdapter = createEntityAdapter<Community>()

export const initialState =
  communitiesAdapter.getInitialState<CommunitiesState>({
    ids: [],
    entities: {},
  })

export const communitySlice = createSlice({
  name: 'communities',
  initialState,
  reducers: {
    upsertManyCommunities: communitiesAdapter.upsertMany,
  },
})

export const { upsertManyCommunities } = communitySlice.actions

export const { reducer } = communitySlice
