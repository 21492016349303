import React, { FunctionComponent, ReactNode } from 'react'
import { ModalBox } from '../ModalBox/ModalBox'

export interface ModalProps {
  title: string
  description: ReactNode
  buttons: ReactNode
  icon?: ReactNode
}

export const Modal: FunctionComponent<ModalProps> = props => {
  return (
    <ModalBox>
      <div className="px-4 pt-5 pb-4 sm:flex sm:items-start sm:p-6 sm:pb-4">
        {props.icon && (
          <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:mr-4 sm:h-10 sm:w-10">
            {props.icon}
          </div>
        )}
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <h3
            className="text-lg font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            {props.title}
          </h3>
          <div className="mt-2 text-sm font-normal text-gray-500">
            {props.description}
          </div>
        </div>
      </div>
      <div className="flex justify-center space-x-4 bg-gray-50 px-4 py-3 sm:justify-end sm:px-6">
        {props.buttons}
      </div>
    </ModalBox>
  )
}
