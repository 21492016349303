import { PlateEditor } from '@udecode/plate'
import { v4 as uuidv4 } from 'uuid'
import { insertNodeOnEmptyLine } from '../insertNodeOnEmptyLine'
import { ELEMENT_TEXT_AREA_FIELD } from './createTextAreaFieldPlugin'
import { TextAreaFieldElement } from './TextAreaFieldElement'

// Called by a click event on the toolbar button.
export const insertTextAreaField = (editor: PlateEditor): void => {
  const textField: TextAreaFieldElement = {
    type: ELEMENT_TEXT_AREA_FIELD,
    fieldId: uuidv4(),
    children: [{ text: '' }],
  }
  insertNodeOnEmptyLine(editor, textField)
}
