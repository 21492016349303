import produce from '@reduxjs/toolkit/node_modules/immer'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { compact } from 'lodash-es'
import {
  ExperienceFeedbackService,
  Learninx_ExperienceMembershipComments_Presenters_ListItem,
} from '../../../generatedFetchers'
import { usePostError } from '../../../utils/queryFns'
import { useMe } from '../../users/useMe'

const {
  getV2ExperienceFeedback: list,
  deleteV2ExperienceFeedbackId: remove,
  postV2ExperienceFeedback: create,
} = ExperienceFeedbackService

type ListParams = Parameters<typeof list>[0]
type ListResponse = Awaited<ReturnType<typeof list>>
type CreateParams = Parameters<typeof create>[0]
export type ExperienceFeedback =
  Learninx_ExperienceMembershipComments_Presenters_ListItem

const listKey = (params?: ListParams) => {
  return compact(['experienceFeedback', 'list', params])
}

/**
 * Query LX feedback for a given membership and page
 */
export const useExperienceFeedback = (params: ListParams) => {
  return useQuery({
    queryKey: listKey(params),
    queryFn: () => list(params),
  })
}

export const useCreateExperienceFeedback = () => {
  const me = useMe().data
  const client = useQueryClient()

  const onMutate = (newFeedback: CreateParams) => {
    const queryKey = listKey({
      experienceDocId: newFeedback.experienceDocId,
      experienceMembershipId: newFeedback.experienceMembershipId,
    })

    client.cancelQueries({ queryKey })
    const preCallData = client.getQueryData<ListResponse>(queryKey)

    client.setQueryData<ListResponse>(queryKey, old => {
      return produce(old, draft => {
        draft?.feedback?.push({
          id: 'temp',
          author: {
            user_id: '',
            id: '',
            ...me,
          },
          created_at: new Date().toISOString(),
          ...newFeedback,
        })
      })
    })

    return { preCallData, queryKey }
  }

  const onError = usePostError()
  return useMutation({
    mutationFn: create,
    onMutate,
    onError,

    onSettled: (_newFeedback, _errors, _varables, context) => {
      client.invalidateQueries(context?.queryKey)
    },
  })
}

export const useDeleteExperienceFeedback = () => {
  const client = useQueryClient()
  const mutationFn = (id: string) => remove({ id })
  const onMutate = (deletedId: string) => {
    const [queryKey, preCallData] =
      client.getQueriesData<ListResponse>(listKey()).find(([, data]) => {
        return data?.feedback?.find(({ id }) => id === deletedId)
      }) || []

    if (!queryKey || !preCallData) return

    client.cancelQueries({ queryKey })
    client.setQueryData<ListResponse>(queryKey, old => {
      if (!old?.feedback) return
      return produce(old, draft => {
        draft.feedback = draft.feedback?.filter(({ id }) => id !== deletedId)
      })
    })

    return { queryKey, preCallData }
  }
  const onError = usePostError()

  return useMutation({
    mutationFn,
    onMutate,
    onError,

    onSettled: (_item, _errors, _varables, context) => {
      client.invalidateQueries(context?.queryKey)
    },
  })
}
