import React, { FunctionComponent, lazy, Suspense, useEffect } from 'react'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'
import {
  createInstance,
  MatomoProvider,
  useMatomo,
} from '@datapunt/matomo-tracker-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SWRConfig } from 'swr'
import { Airbrake } from './components/AirbrakeBoundary/Airbrake'
import { AuthorizedRoute } from './components/AuthorizedRoute/AuthorizedRoute'
import { ExperienceAddMembersPage } from './components/ExperienceAddMembersPage/ExperienceAddMembersPage'
import { ExperienceMembersPage } from './components/ExperienceMembersPage/ExperienceMembersPage'
import { FlashMessages } from './components/FlashMessages/FlashMessages'
import { IconStyleWrapper } from './components/IconStyleWrapper/IconStyleWrapper'
import { LoadingPage } from './components/LoadingPage/LoadingPage'
import { MessengerSubscriptions } from './components/MessengerPage/MessengerSubscriptions'
import { MissingCommunityPage } from './components/MissingCommunityPage/MissingCommunityPage'
import { SettingsPage } from './components/SettingsPage/SettingsPage'
import { UnauthorizedRoute } from './components/UnauthorizedRoute/UnauthorizedRoute'
import { PreFetchExperiences } from './features/experiences/PreFetch'
import { NotificationsSubscription } from './features/notifications/Subscription'
import {
  conversationPath,
  dashboardPath,
  demoPath,
  discussionFeedsPath,
  experienceAddMembersPath,
  experienceDocumentEditPath,
  experienceDocumentPath,
  experienceEditPath,
  experienceEvidenceDetailPath,
  experienceEvidenceDocumentPath,
  experienceEvidencesPath,
  experienceMembersPath,
  experiencePath,
  experiencesPath,
  experienceTrashPath,
  homePath,
  memberProfileAboutPath,
  memberProfileBitsPath,
  memberProfileLearningPath,
  memberProfilePath,
  membersPath,
  missingCommunityPath,
  noticeBoardsPath,
  notificationsPath,
  settingsPath,
} from './helpers/paths'
import DiscussionFeedsPage from './pages/DiscussionFeedsPage'
import ExperienceEvidenceDocumentPage from './pages/ExperienceEvidenceDocumentPage'
import ExperienceEvidencesPage from './pages/ExperienceEvidencesPage'
import ExperienceTrashPage from './pages/ExperienceTrashPage'
import { store } from './store'
import { hotjar } from './utils/hotjar'

const HomePage = lazy(() => import('./pages/HomePage'))
const CommunityDashboardPage = lazy(
  () => import('./pages/CommunityDashboardPage')
)
const CommunityExperiencesPage = lazy(
  () => import('./pages/CommunityExperiencesPage')
)
const ExperienceDocumentPage = lazy(
  () => import('./pages/ExperienceDocumentPage')
)
const ExperienceEditPage = lazy(() => import('./pages/ExperienceEditPage'))
const ExperienceOverviewPage = lazy(
  () => import('./pages/ExperienceOverviewPage')
)
const CommunityMembersPage = lazy(() => import('./pages/CommunityMembersPage'))
const MemberProfilePage = lazy(() => import('./pages/MemberProfilePage'))
const MessengerPage = lazy(() => import('./pages/MessengerPage'))
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'))
const ExperienceEvidenceDetailPage = lazy(
  () => import('./pages/ExperienceEvidenceDetailPage')
)
const ButtonDemo = lazy(() => import('./pages/ButtonDemo'))

const NotificationsPage = lazy(() => import('./pages/NotificationsPage'))

const queryClient = new QueryClient()

const BaseApp: FunctionComponent = () => {
  const { trackPageView } = useMatomo()
  const { listen } = useHistory()
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar()
    }
    // Disabling matomoTagManager as I don't think it's working right now
    // and no one is managing it right now.
    // matomoTagManager()
  })

  useEffect(() => {
    return listen(location => {
      trackPageView({
        documentTitle: document.getElementsByTagName('title')[0].innerHTML,
        href: location.pathname,
      })
    })
  }, [trackPageView, listen])
  return (
    <Airbrake>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Provider store={store}>
          <MessengerSubscriptions />
          <NotificationsSubscription />

          <PreFetchExperiences />

          <FlashMessages>
            <IconStyleWrapper>
              <Suspense fallback={<LoadingPage />}>
                <Switch>
                  <UnauthorizedRoute
                    exact
                    path={homePath()}
                    component={HomePage}
                  />
                  <UnauthorizedRoute
                    exact
                    path={missingCommunityPath()}
                    component={MissingCommunityPage}
                  />
                  <AuthorizedRoute
                    path={dashboardPath()}
                    component={CommunityDashboardPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experiencesPath()}
                    component={CommunityExperiencesPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experienceEditPath(':experienceId')}
                    component={ExperienceEditPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experienceMembersPath(':experienceId')}
                    component={ExperienceMembersPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experienceAddMembersPath(':experienceId')}
                    component={ExperienceAddMembersPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experienceEvidencesPath(':experienceId')}
                    component={ExperienceEvidencesPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experienceDocumentPath(
                      ':experienceId',
                      ':documentId'
                    )}
                    component={ExperienceDocumentPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experienceDocumentEditPath(
                      ':experienceId',
                      ':documentId'
                    )}
                    component={ExperienceDocumentPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experienceTrashPath(':experienceId')}
                    component={ExperienceTrashPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experiencePath(':experienceId')}
                    component={ExperienceOverviewPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experienceEvidenceDetailPath(
                      ':experienceId',
                      ':membershipId'
                    )}
                    component={ExperienceEvidenceDetailPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={experienceEvidenceDocumentPath(
                      ':experienceId',
                      ':membershipId',
                      ':documentId'
                    )}
                    component={ExperienceEvidenceDocumentPage}
                  />
                  <AuthorizedRoute
                    exact
                    path={memberProfilePath(':memberId')}
                    component={MemberProfilePage}
                  />
                  <AuthorizedRoute
                    exact
                    path={memberProfileAboutPath(':memberId')}
                    component={MemberProfilePage}
                  />
                  <AuthorizedRoute
                    exact
                    path={memberProfileLearningPath(':memberId')}
                    component={MemberProfilePage}
                  />
                  <AuthorizedRoute
                    exact
                    path={memberProfileBitsPath(':memberId')}
                    component={MemberProfilePage}
                  />
                  <AuthorizedRoute
                    exact
                    path={memberProfileBitsPath(
                      ':memberId',
                      ':group',
                      ':outcome'
                    )}
                    component={MemberProfilePage}
                  />
                  <AuthorizedRoute
                    exact
                    path={membersPath()}
                    component={CommunityMembersPage}
                  />
                  <AuthorizedRoute
                    path={conversationPath(':conversationId?')}
                    component={MessengerPage}
                  />
                  <AuthorizedRoute
                    path={notificationsPath()}
                    component={NotificationsPage}
                  />
                  <AuthorizedRoute
                    path={settingsPath()}
                    component={SettingsPage}
                  />
                  <AuthorizedRoute
                    path={discussionFeedsPath(
                      ':experienceId',
                      ':discussionFeedId?'
                    )}
                    component={DiscussionFeedsPage}
                  />
                  <AuthorizedRoute path={demoPath()} component={ButtonDemo} />
                  <AuthorizedRoute
                    path={noticeBoardsPath(
                      ':experienceId',
                      ':discussionFeedId?'
                    )}
                    component={DiscussionFeedsPage}
                  />
                  <Route path="*" component={NotFoundPage} />
                </Switch>
              </Suspense>
            </IconStyleWrapper>
          </FlashMessages>
        </Provider>
      </QueryClientProvider>
    </Airbrake>
  )
}

// Matomo analytics tool
// Backend URL: https://learnlife.matomo.cloud/
const matomoInstance = createInstance({
  urlBase: (import.meta.env.VITE_MATOMO_URL as string) ?? '',
  siteId: parseInt((import.meta.env.VITE_MATOMO_SITEID as string) ?? ''),
  disabled: Boolean(import.meta.env.VITE_MATOMO_DISABLED == 'true'),
  srcUrl: (import.meta.env.VITE_MATOMO_ASSET_URL as string) ?? '',
})

const App: FunctionComponent = () => {
  return (
    <MatomoProvider value={matomoInstance}>
      <Router>
        <SWRConfig
          value={{ fetcher: url => fetch(url).then(res => res.json()) }}
        >
          <BaseApp />
        </SWRConfig>
      </Router>
    </MatomoProvider>
  )
}

export default App
