import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'
import { getCollapsedSectionIds } from '../../selectors/base'

interface Props {
  sectionId: EntityId
}

export const SideMenuSectionWrapper: FunctionComponent<Props> = ({
  sectionId,
  ...props
}) => {
  const isSectionCollapsed = useSelector(getCollapsedSectionIds)[sectionId]
  const className = isSectionCollapsed ? 'mb-4' : 'mb-10'
  return <div className={className} {...props} />
}
