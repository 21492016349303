import React, { FunctionComponent } from 'react'

export interface FieldLabelProps {
  label: string
  required?: boolean
  htmlFor?: string
}

export const FieldLabel: FunctionComponent<FieldLabelProps> = props => {
  return (
    <div className="mb-1 flex items-baseline justify-start">
      <label htmlFor={props.htmlFor} className="block text-sm font-medium">
        {props.label}
        {props.required && <span className="w-3 text-center text-xs">*</span>}
      </label>
    </div>
  )
}
