import React, { FunctionComponent } from 'react'

export const H1Element: FunctionComponent = props => {
  return <h1 {...props} className="py-2 text-3xl font-semibold" />
}

export const H2Element: FunctionComponent = props => {
  return <h2 {...props} className="py-2 text-2xl font-semibold" />
}

export const H3Element: FunctionComponent = props => {
  return <h3 {...props} className="py-2 text-xl font-semibold" />
}
