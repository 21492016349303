import React, { FunctionComponent, TdHTMLAttributes } from 'react'
import classNames from 'classnames'

export type TableCellProps = TdHTMLAttributes<HTMLTableDataCellElement>

export const TableCell: FunctionComponent<TableCellProps> = props => {
  return (
    <td
      {...props}
      className={classNames(
        'whitespace-nowrap px-6 py-4 text-sm text-gray-900',
        props.className
      )}
    />
  )
}
