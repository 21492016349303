import React, { FunctionComponent } from 'react'
import { ChevronLeft } from '@styled-icons/heroicons-solid'

export interface PreviousPageProps {
  onClick: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >['onClick']
}

export const PreviousPage: FunctionComponent<PreviousPageProps> = props => {
  return (
    <button
      onClick={props.onClick}
      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
      title="Previous Page"
    >
      <ChevronLeft size={20} />
    </button>
  )
}
