import React, { FunctionComponent, useEffect, useState } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeyMe, useMe } from '../../features/users/useMe'
import { dashboardPath } from '../../helpers/paths'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export type UnauthorizedRouteProps = RouteProps

export const UnauthorizedRoute: FunctionComponent<
  UnauthorizedRouteProps
> = props => {
  const { failureReason, isSuccess } = useMe()

  const [authorized, setAuthorized] = useState<boolean | null>(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (failureReason == 'ApiError: Unauthorized') {
      setAuthorized(false)
      queryClient.cancelQueries(queryKeyMe)
    } else if (isSuccess) {
      setAuthorized(true)
    }
  }, [failureReason, isSuccess, queryClient])

  if (authorized) return <Redirect to={dashboardPath()} />
  if (authorized === false) return <Route {...props} />
  return <LoadingPage />
}
