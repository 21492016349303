import React, { FunctionComponent } from 'react'
import { useSWR_Document } from '../../hooks/useAPI'
import { useDocumentId } from '../../hooks/useDocumentId'
import { ExperienceDocumentPageTitle } from './ExperienceDocumentPageTitle'

export const ExperienceDocumentPageTitleContainer: FunctionComponent = () => {
  const documentId = useDocumentId()
  const { data } = useSWR_Document(documentId)
  if (!data) return null
  return <ExperienceDocumentPageTitle document={data.data.experience_doc} />
}
