import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { Document } from '../types/entities'

export type DocumentsState = EntityState<Document>

export const documentsAdapter = createEntityAdapter<Document>()

export const initialState = documentsAdapter.getInitialState<DocumentsState>({
  ids: [],
  entities: {},
})

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    upsertManyDocuments: documentsAdapter.upsertMany,
    removeDocumentById: documentsAdapter.removeOne,
    updateOneDocument: documentsAdapter.updateOne,
  },
})

export const { upsertManyDocuments, removeDocumentById, updateOneDocument } =
  documentsSlice.actions

export const { reducer } = documentsSlice
