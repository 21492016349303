import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { ArrowCounterclockwise } from '@styled-icons/fluentui-system-filled'
import { mutate } from 'swr'
import {
  apiDiscussionFeedRestorePath,
  apiExperienceDocRestorePath,
  apiExperiencePath,
  apiExperienceTrashPath,
} from '../../helpers/apiPaths'
import { useSWR_ExperienceTrash } from '../../hooks/useAPI'
import { getSelectedExperienceId } from '../../selectors/experiences'
import { getSelectedExperienceSections } from '../../selectors/sections'
import { TrashDoc } from '../../types/entities'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { useSelectorOrThrow } from '../../utils/useSelectorOrThrow'
import { ExperienceSideMenu } from '../ExperienceSideMenu/ExperienceSideMenu'
import { ExperienceTopMenu } from '../ExperienceTopMenu/ExperienceTopMenu'
import { Layout } from '../Layout/Layout'
import { SpinnerIcon } from '../SpinnerIcon/SpinnerIcon'
import { Table } from '../Table/Table'
import { TableBody } from '../Table/TableBody'
import { TableCell } from '../Table/TableCell'
import { TableHeader } from '../Table/TableHeader'
import { TableHeaderCell } from '../Table/TableHeaderCell'
import { TableRow } from '../Table/TableRow'
import { ToolTip } from '../ToolTip/ToolTip'

export const ExperienceTrashPageContent: FunctionComponent = () => {
  const experienceId = useSelectorOrThrow(getSelectedExperienceId)
  const trash = useSWR_ExperienceTrash(experienceId)
  const untrashedSections = useSelectorOrThrow(getSelectedExperienceSections)
  const [loading, setLoading] = useState<EntityId[]>([])

  const sections: Array<{ id: EntityId; title: string }> = useMemo(() => {
    const trashedSections = trash.data?.data?.sections
    if (trashedSections) {
      return trashedSections.concat(untrashedSections)
    } else {
      return untrashedSections
    }
  }, [trash, untrashedSections])

  const pages = useMemo(() => trash.data?.data?.docs, [trash])
  const flashMessages = useFlashMessages()

  const restorePage = useCallback(
    async (page: TrashDoc) => {
      setLoading([...loading, page.id])
      let restorePath = ''
      switch (page.type) {
        case 'doc':
          restorePath = apiExperienceDocRestorePath()
          break
        case 'discussion_feed':
          restorePath = apiDiscussionFeedRestorePath()
          break
      }
      try {
        const response = await fetch(restorePath, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: page.id,
          }),
        })

        if (response.ok) {
          mutate(apiExperiencePath(experienceId))
          mutate(apiExperienceTrashPath(experienceId))

          flashMessages.show({
            type: 'success',
            text: `Page restored: ${page.title}`,
          })
        } else {
          throw new Error(response.statusText)
        }
      } catch {
        flashMessages.show({
          type: 'error',
          text: `Could not restore page: ${page.title}`,
        })
      } finally {
        setLoading(loading.filter(e => e != page.id))
      }
    },
    [experienceId, loading, setLoading, flashMessages]
  )

  return (
    <Layout
      topComponent={<ExperienceTopMenu />}
      sideComponent={<ExperienceSideMenu />}
    >
      <div className="space-y-4 px-8">
        <div className="flex h-16 items-center justify-between">
          <h1 className="mb-4 mt-4 text-2xl font-medium text-gray-900">
            Trash
          </h1>
        </div>
        <Table id="experience-trash-table">
          <TableHeader>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Restore</TableHeaderCell>
          </TableHeader>
          <TableBody>
            {pages?.map(experiencePage => (
              <TableRow key={experiencePage.id}>
                <TableCell className="leading-4">
                  <span className="font-semibold uppercase text-gray-500">
                    {
                      sections.find(
                        section => section.id === experiencePage.parent_id
                      )?.title
                    }
                  </span>
                  <span className="mx-1.5 border-r-2 border-gray-400" />
                  <span>{experiencePage.title}</span>
                </TableCell>
                <TableCell className="w-8">
                  {loading.includes(experiencePage.id) ? (
                    <SpinnerIcon className="animate-spin cursor-wait text-gray-400" />
                  ) : (
                    <button
                      className="restore-page-button cursor-pointer"
                      onClick={() => restorePage(experiencePage)}
                      aria-label="Restore"
                    >
                      <ToolTip content="Restore">
                        <ArrowCounterclockwise
                          size={18}
                          className="cursor-pointer text-gray-500"
                        />
                      </ToolTip>
                    </button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Layout>
  )
}
