import React, { FunctionComponent } from 'react'
import { ContentCard } from '../ContentCard/ContentCard'
import { ExperienceContentDiv } from '../ExperienceContentDiv/ExperienceContentDiv'
import { ExperienceAlertBanner } from '../ExperienceOverviewPage/ExperienceAlertBanner'
import { ExperienceSideMenu } from '../ExperienceSideMenu/ExperienceSideMenu'
import { ExperienceTopMenu } from '../ExperienceTopMenu/ExperienceTopMenu'
import { Layout } from '../Layout/Layout'
import { PageTitle } from '../PageTitle/PageTitle'
import { Table } from '../Table/Table'
import { TableHeader } from '../Table/TableHeader'
import { TableHeaderCell } from '../Table/TableHeaderCell'

export const ExperienceMembersPageTemplate: FunctionComponent<{
  colCount: number
  actionControls?: React.ReactNode
  children?: React.ReactNode
}> = ({ actionControls, colCount, children }) => {
  // The bottom padding on the Table is a hack - the access switcher dropdown
  // menu misbehaves when it overflows the container.
  return (
    <Layout
      topComponent={<ExperienceTopMenu />}
      sideComponent={<ExperienceSideMenu />}
    >
      <ExperienceContentDiv>
        <ExperienceAlertBanner />
        <div className="flex h-10 items-center justify-between px-6 sm:px-0">
          <PageTitle>Members</PageTitle>
          {actionControls}
        </div>
        <ContentCard className="sm:p-6">
          <Table id="experience-members-table" className="mb-[6rem]">
            <colgroup>
              {[...Array(colCount).keys()].map(i => (
                <col key={i} className="w-auto" />
              ))}
            </colgroup>
            <TableHeader>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Type</TableHeaderCell>
              <TableHeaderCell>Group</TableHeaderCell>
              <TableHeaderCell>Access</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
            </TableHeader>

            {children}
          </Table>
        </ContentCard>
      </ExperienceContentDiv>
    </Layout>
  )
}
