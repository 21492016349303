import React, { FormEventHandler, FunctionComponent } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { Checkbox } from '../Checkbox/Checkbox'
import { UserAvatar } from '../UserAvatar/UserAvatar'

export interface ExperienceAddmembersPageTypeMembersProps {
  members: {
    first_name: string
    last_name: string
    avatar_url: string
    id: EntityId
  }[]
  handleChange: FormEventHandler
  checkedUserIds: EntityId[]
}

export const ExperienceAddmembersPageTypeMembers: FunctionComponent<
  ExperienceAddmembersPageTypeMembersProps
> = ({ members, handleChange, checkedUserIds }) => {
  return (
    <ul>
      {members.map(member => (
        <li key={member.id} className="my-1">
          <label className="flex items-center space-x-2">
            <Checkbox
              id={member.id as string}
              onChange={handleChange}
              checked={checkedUserIds.includes(member.id)}
            />
            <UserAvatar user={{ ...member }} size="small" />
            <span>{`${member.first_name} ${member.last_name}`}</span>
          </label>
        </li>
      ))}
    </ul>
  )
}
