import React, { FunctionComponent, useCallback } from 'react'
import { CheckCircle } from '@styled-icons/heroicons-outline'
import { mutate } from 'swr'
import { apiExperienceMembershipsPath } from '../../helpers/apiPaths'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useMembershipId } from '../../hooks/useMembershipId'
import { useUpdateMembership } from '../../hooks/useUpdateMembership'
import { useFindExperienceMembership } from '../../utils/useFindExperienceMembership'
import { Button } from '../Button/Button'

export const ExperienceEvidenceConfirmAsDone: FunctionComponent = () => {
  const experienceId = useExperienceId()
  const membershipId = useMembershipId()
  const membership = useFindExperienceMembership(membershipId, experienceId)

  const updateMembership = useUpdateMembership()
  const handleOnClick = useCallback(async () => {
    if (!membership) return null
    await updateMembership(
      membership.id,
      { confirmed_at: new Date() },
      `Confirmed ${membership.first_name} ${membership.last_name} has completed this Learning Experience.`
    )
    await mutate(apiExperienceMembershipsPath({ experienceId, perPage: 9999 }))
  }, [membership, updateMembership, experienceId])

  if (!membership || !membership.done_at) return null

  const backgroundColor = membership.confirmed_at ? 'bg-green-50' : 'bg-blue-50'

  return (
    <div
      className={[
        backgroundColor,
        'text-sm',
        'border',
        'shadow',
        'flex',
        'flex-wrap',
        'sm:flex-nowrap',
        'items-center',
        'justify-center',
        'sm:justify-between',
        'gap-2',
        'mt-4',
        'p-4',
        'rounded-md',
      ].join(' ')}
    >
      {membership.confirmed_at ? (
        <h2 className="flex items-center space-x-1 text-base font-medium text-green-900">
          <CheckCircle size={28} />
          <span>
            {membership.first_name} {membership.last_name} has completed this LX
          </span>
        </h2>
      ) : (
        <>
          <p>
            <span className="font-semibold">
              {membership.first_name} {membership.last_name}{' '}
            </span>
            has marked this Learning Experience as done
          </p>
          <Button onClick={handleOnClick} theme="confirm">
            Confirm LX as Done
          </Button>
        </>
      )}
    </div>
  )
}
