import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { RecordProperty } from '../types/entities'

export type RecordPropertiesState = EntityState<RecordProperty>

export const recordPropertiesAdapter = createEntityAdapter<RecordProperty>()

export const initialState =
  recordPropertiesAdapter.getInitialState<RecordPropertiesState>({
    ids: [],
    entities: {},
  })

export const recordPropertiesSlice = createSlice({
  name: 'recordProperties',
  initialState,
  reducers: {
    upsertManyRecordProperties: recordPropertiesAdapter.upsertMany,
  },
})

export const { upsertManyRecordProperties } = recordPropertiesSlice.actions

export const { reducer } = recordPropertiesSlice
