import React from 'react'
import { numberSequence } from '../../utils/numberSequence'
import { Ellipsis } from './Ellipsis'
import { PageItem } from './PageItem'

type onPageChangeType = (page: number) => void
type PaginationOptions = {
  page: number
  boundaryRange: number
  ellipsis: boolean
  siblingRange: number
  totalPages: number
  firstAndLast: boolean
  previousAndNext: boolean
  onPageChange: onPageChangeType
}

const isSimplePagination = (options: PaginationOptions) => {
  const boundaryRangeSize = 2 * options.boundaryRange
  const ellipsisSize = options.ellipsis ? 2 : 1
  const siblingRangeSize = 2 * options.siblingRange

  return (
    1 + ellipsisSize + siblingRangeSize + boundaryRangeSize >=
    options.totalPages
  )
}

export const shouldShowLeftEllipsis = (
  firstGroupEnd: number,
  innerGroupStart: number
): boolean => {
  const prefixPage = innerGroupStart - 1
  return prefixPage !== firstGroupEnd + 1
}

export const shouldShowRightEllipsis = (
  innerGroupEnd: number,
  lastGroupStart: number
): boolean => {
  const suffixPage = innerGroupEnd + 1
  return suffixPage !== lastGroupStart - 1
}

export const createSimpleRange = (
  start: number,
  end: number,
  activePage: number,
  onPageChange?: onPageChangeType
): JSX.Element[] =>
  numberSequence(start, end).map(page => (
    <PageItem
      key={page}
      page={page}
      active={page === activePage}
      onClick={onPageChange}
    />
  ))

export const createComplexRange = (
  options: PaginationOptions
): JSX.Element[] => {
  const ellipsisSize = options.ellipsis ? 1 : 0
  const firstGroupEnd = options.boundaryRange
  const firstGroup = createSimpleRange(
    1,
    firstGroupEnd,
    options.page,
    options.onPageChange
  )

  const lastGroupStart = options.totalPages + 1 - options.boundaryRange
  const lastGroup = createSimpleRange(
    lastGroupStart,
    options.totalPages,
    options.page,
    options.onPageChange
  )

  const innerGroupStart = Math.min(
    Math.max(
      options.page - options.siblingRange,
      firstGroupEnd + ellipsisSize + 1
    ),
    lastGroupStart - ellipsisSize - 2 * options.siblingRange - 1
  )
  const innerGroupEnd = innerGroupStart + 2 * options.siblingRange
  const innerGroup = createSimpleRange(
    innerGroupStart,
    innerGroupEnd,
    options.page,
    options.onPageChange
  )

  return [
    ...firstGroup,
    options.ellipsis &&
    shouldShowLeftEllipsis(firstGroupEnd, innerGroupStart) ? (
      <Ellipsis key="left-ellipsis" />
    ) : (
      <PageItem
        key={innerGroupStart - 1}
        page={innerGroupStart - 1}
        active={innerGroupEnd - 1 === options.page}
        onClick={options.onPageChange}
      />
    ),
    ...innerGroup,
    options.ellipsis &&
    shouldShowRightEllipsis(innerGroupEnd, lastGroupStart) ? (
      <Ellipsis key="right-ellipsis" />
    ) : (
      <PageItem
        key={innerGroupEnd + 1}
        page={innerGroupEnd + 1}
        active={innerGroupEnd + 1 === options.page}
        onClick={options.onPageChange}
      />
    ),
    ...lastGroup,
  ]
}

export const createPaginationItems = (
  options: PaginationOptions
): JSX.Element[] => {
  return isSimplePagination(options)
    ? createSimpleRange(
        1,
        options.totalPages,
        options.page,
        options.onPageChange
      )
    : createComplexRange(options)
}
