import { FunctionComponent, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { client } from '../../websocket/cableClient'
import {
  dashboardNotifications,
  LLNotification,
  notificationsQueryKey,
} from './api'

// When a new notification is received, invalidate the notifications query
// This will cause all notifications to be refetched, but only if there are any
// active observers
export const NotificationsSubscription: FunctionComponent = () => {
  const queryClient = useQueryClient()

  const subscription = client.subscriptions.create('new_notification', {
    received: (notification: LLNotification) => {
      if (dashboardNotifications.includes(notification.category)) {
        queryClient.invalidateQueries(
          ['notifications', notification.category],
          {
            refetchType: 'all',
          }
        )
      } else {
        queryClient.invalidateQueries(
          notificationsQueryKey({ except: dashboardNotifications })
        )
      }
    },
  })

  useEffect(() => subscription.unsubscribe(), [subscription])

  return null
}
