import React, { FunctionComponent } from 'react'
import {
  ExperienceStatus_Container,
  ExperienceStatus_StatusText,
} from './ExperienceStatus'
import { SetSavingProps } from './ExperienceStatus'
import { ExperienceStatus_CopyButton } from './ExperienceStatus_CopyButton'
import { ExperienceStatus_PublishedView_DropdownMenu } from './ExperienceStatus_PublishedView_DropdownMenu'

export const ExperienceStatus_PublishedView: FunctionComponent<
  SetSavingProps
> = ({ setSaving }) => {
  return (
    <ExperienceStatus_Container>
      <ExperienceStatus_StatusText
        title="Published"
        textColor="text-green-400"
      />
      <ExperienceStatus_CopyButton setSaving={setSaving} />
      <ExperienceStatus_PublishedView_DropdownMenu setSaving={setSaving} />
    </ExperienceStatus_Container>
  )
}
