import React, { FunctionComponent } from 'react'
import { DotsHorizontal } from '@styled-icons/heroicons-outline'
import { useSWR_MyExperienceMemberships } from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useToggle } from '../../hooks/useToggle'
import { Button } from '../Button/Button'
import { Dropdown } from '../Dropdown/Dropdown'
import { ExperienceAttendeeDropdown_DropdownMenu_DoneButton } from './ExperienceAttendeeDropdown_DropdownMenu_DoneButton'

export const ExperienceAttendeeDropdown: FunctionComponent = () => {
  const experienceId = useExperienceId()
  const { data: membershipData } = useSWR_MyExperienceMemberships(experienceId)

  const [menuOpen, toggleMenuOpen] = useToggle(false)

  if (!membershipData) return null

  const membership = membershipData.data.experience_memberships[0]

  if (membership?.done_at || membership?.access_level !== 'attendee')
    return null

  return (
    <div className="relative">
      <Button
        id="attendee-dropdown"
        theme="dark"
        title="Attendee options menu"
        onClick={toggleMenuOpen}
        icon={DotsHorizontal}
      />
      {menuOpen && (
        <Dropdown>
          <ExperienceAttendeeDropdown_DropdownMenu_DoneButton />
        </Dropdown>
      )}
    </div>
  )
}
