/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck

export type postV1ExperienceMemberships = {
    experience_memberships: {
        /**
         * ID of experience
         */
        experience_id: string;
        /**
         * List of community user IDs
         */
        community_user_ids: Array<string>;
        /**
         * Used when requesting membership to an LX
         */
        access_level?: postV1ExperienceMemberships.access_level;
    };
};

export namespace postV1ExperienceMemberships {

    /**
     * Used when requesting membership to an LX
     */
    export enum access_level {
        COLLABORATOR = 'collaborator',
        ATTENDEE = 'attendee',
        PENDING = 'pending',
    }


}

