import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { User } from '../types/entities'

export type UsersState = EntityState<User>

export const usersAdapter = createEntityAdapter<User>()

export const initialState = usersAdapter.getInitialState<UsersState>({
  ids: [],
  entities: {},
})

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    upsertManyUsers: usersAdapter.upsertMany,
  },
})

export const { upsertManyUsers } = usersSlice.actions

export const { reducer } = usersSlice
