import React, { FunctionComponent } from 'react'

export const NarrowSideMenu: FunctionComponent = props => {
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-grow flex-col">
        <nav className="flex-1 space-y-2 p-2" aria-label="Side menu">
          {props.children}
        </nav>
      </div>
    </div>
  )
}
