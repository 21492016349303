import React, { useState } from 'react'
import { FunctionComponent } from 'react'
import { Button } from '../../../components/Button/Button'
import { textAreaClassName } from '../../../components/TextAreaField/TextAreaField'
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar'
import { useMe } from '../../users/useMe'
import { useCreateExperienceFeedback } from './api'

export const LXFeedbackForm: FunctionComponent<{
  documentId: string
  membershipId: string
  placeholder?: string
}> = ({ documentId, membershipId, placeholder = 'Share your feedback' }) => {
  const currentUser = useMe().data
  const mutator = useCreateExperienceFeedback()

  const [content, setContent] = useState('')

  const postComment = async () => {
    const oldContent = content
    setContent('')

    mutator
      .mutateAsync({
        content,
        experienceDocId: documentId,
        experienceMembershipId: membershipId,
      })
      .catch(() => setContent(oldContent))
  }

  if (!currentUser) return null

  return (
    <div className="flex">
      <div className="hidden pr-4 sm:block">
        <UserAvatar size="large" user={currentUser} />
      </div>
      <div className="flex-grow">
        <div className="mb-4 flex flex-col gap-2">
          <label htmlFor="comment-box" className="text-sm text-gray-700">
            {placeholder}
          </label>
          <textarea
            id="comment-box"
            value={content}
            disabled={mutator.isLoading}
            readOnly={mutator.isLoading}
            onChange={e => setContent(e.target.value)}
            rows={5}
            className={textAreaClassName}
          />
        </div>
        <div className="float-right">
          <Button
            disabled={mutator.isLoading || !content.length}
            style={{ opacity: content.length ? 1 : 0 }}
            loading={mutator.isLoading}
            onClick={postComment}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}
