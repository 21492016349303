import React, { FunctionComponent } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { ArrowLeft } from '@styled-icons/heroicons-solid'

export type TopMenuBackArrowProps = LinkProps<unknown>

export const TopMenuBackArrow: FunctionComponent<
  TopMenuBackArrowProps
> = props => {
  return (
    <Link
      className="mr-4 h-8 w-8 shrink-0 rounded-full bg-gray-900 text-gray-50 transition-colors hover:bg-gray-700 sm:mr-6"
      {...props}
    >
      <ArrowLeft size={24} className="m-1" />
    </Link>
  )
}
