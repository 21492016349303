import { EntityId } from '@reduxjs/toolkit'
import { Experience } from '../../types/entities'

export const updateExperienceStatus = (
  experienceId: EntityId,
  status: Experience['status']
): Promise<any> => {
  return fetch(`/api/v1/experiences/${experienceId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ experience: { status } }),
  })
}
