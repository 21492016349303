import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { RouteChildrenProps, useHistory, useParams } from 'react-router-dom'
import { Plus } from '@styled-icons/heroicons-solid'
import humps from 'humps'
import { useCurrentCommunity } from '../../features/users/useMe'
import {
  experienceAddMembersPath,
  experienceMembersPath,
  PaginationQuery,
} from '../../helpers/paths'
import { useAPI_CanEditExperience } from '../../hooks/useAPI'
import { useExperienceMembersFetch } from '../../utils/useExperienceMembersFetch'
import { useOnNavigationScroll } from '../../utils/useScrollToTop'
import { ButtonLink } from '../Button/ButtonLink'
import { Pagination } from '../Pagination/Pagination'
import { ExperienceLoader } from '../ReduxFetch/ExperienceLoader'
import { TableFooter } from '../Table/TableFooter'
import { TableFooterCell } from '../Table/TableFooterCell'
import { ExperienceMembersPageTableBody } from './ExperienceMembersPageTableBody'
import { ExperienceMembersPageTemplate } from './ExperienceMembersPageTemplate'

const scrollOptions = { top: 0, left: 0, behavior: 'smooth' as any }

export const ExperienceMembersPage: FunctionComponent<
  RouteChildrenProps
> = () => {
  useOnNavigationScroll(scrollOptions)

  const colCount = 5
  const history = useHistory()
  const { experienceId } = useParams<{ experienceId: string }>()
  const canEdit = useAPI_CanEditExperience(experienceId)
  const membersData = useExperienceMembersFetch()
  const currentUserId = useCurrentCommunity().community_user_id

  const tableBody = useMemo(() => {
    return (
      <ExperienceMembersPageTableBody
        membersData={membersData}
        canEdit={canEdit}
        currentUserId={currentUserId}
        colCount={colCount}
      />
    )
  }, [canEdit, membersData, currentUserId])

  const addMembersButton = useMemo(() => {
    if (!canEdit) return

    return (
      <ButtonLink to={experienceAddMembersPath(experienceId)} icon={Plus}>
        Add members
      </ButtonLink>
    )
  }, [canEdit, experienceId])

  const handlePageChange = useCallback(
    (page: number) => {
      const query: PaginationQuery = {}
      if (page !== 1) {
        query['pagination[page]'] = page
      }
      history.push(experienceMembersPath(experienceId, query))
    },
    [experienceId, history]
  )

  const paginator = useMemo(() => {
    {
      if (membersData.pagination && membersData.pagination.totalPages > 1)
        return (
          <TableFooter>
            <TableFooterCell colSpan={colCount}>
              <Pagination
                pagination={humps.decamelizeKeys(membersData.pagination)}
                onPageChange={handlePageChange}
                boundaryRange={1}
                siblingRange={0}
              />
            </TableFooterCell>
          </TableFooter>
        )
    }
  }, [membersData, handlePageChange])

  return (
    <ExperienceLoader experienceId={experienceId}>
      <ExperienceMembersPageTemplate
        colCount={colCount}
        actionControls={addMembersButton}
      >
        {tableBody}
        {paginator}
      </ExperienceMembersPageTemplate>
    </ExperienceLoader>
  )
}
