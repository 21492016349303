import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { QuestionMarkCircle } from '@styled-icons/heroicons-solid'
import Tippy from '@tippyjs/react'
import { Formik, FormikConfig } from 'formik'
import { stringifyUrl } from 'query-string'
import useSWR from 'swr'
import { useCurrentCommunity } from '../../features/users/useMe'
import { fetchPut } from '../../utils/fetchJson'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { Button } from '../Button/Button'
import { Checkbox } from '../Checkbox/Checkbox'
import { CommunityLayout } from '../CommunityLayout/CommunityLayout'

export type SettingsPageFormValues = {
  id: EntityId
  community_user_id: EntityId
  email_notifications_active: boolean
}

export const SettingsPage: FunctionComponent = () => {
  const flashMessages = useFlashMessages()
  const community = useCurrentCommunity()
  const myCommunityUserId = community?.community_user_id

  const { data, mutate } = useSWR<any, Error>(
    stringifyUrl({ url: `/api/v1/preferences/${myCommunityUserId}` }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const initialValues = useMemo<SettingsPageFormValues>(
    () => data?.data?.preferences,
    [data]
  )

  const handleOnCancel = useCallback(resetForm => {
    resetForm()
  }, [])

  const handleOnSubmit = useCallback<
    FormikConfig<SettingsPageFormValues>['onSubmit']
  >(
    async (formValues, helpers) => {
      const body = JSON.stringify({
        preferences: {
          email_notifications_active: formValues.email_notifications_active,
        },
      })
      const response = await fetchPut(`/api/v1/preferences/${formValues.id}`, {
        body,
      })
      if (!response.ok) {
        const data = await response.json()
        const key = Object.keys(data.errors)[0]
        const error = data.errors[key]
        flashMessages.show({
          type: 'error',
          text: error || 'Something went wrong',
        })
      } else {
        flashMessages.show({
          text: `Preferences successfully updated`,
          type: 'success',
        })
        await mutate()
      }
      helpers.setSubmitting(false)
    },
    [flashMessages, mutate]
  )

  return (
    <CommunityLayout>
      <div className="mx-auto w-full space-y-4 px-1 py-4 sm:px-6 md:w-4/5 lg:w-3/5">
        <Formik<SettingsPageFormValues>
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          enableReinitialize
        >
          {({
            dirty,
            isSubmitting,
            resetForm,
            setFieldValue,
            submitForm,
            values,
          }) => (
            <>
              <h1 className="mt-8 text-2xl font-semibold leading-8">
                Notification Settings
              </h1>
              <div className="mx-auto rounded bg-white p-4 shadow sm:p-8">
                <div className="flex">
                  <div className="w-1/2 text-left text-base font-medium leading-6">
                    How do you want to be notified?
                  </div>
                  <div className="w-1/4 text-center text-base font-medium leading-6">
                    <div className="flex items-center sm:space-x-2">
                      <span>Bell alert</span>
                      <Tippy
                        content={
                          <div>
                            <div className="font-semibold">Bell alert</div>
                            <div className="font-medium text-gray-400">
                              By default, all notifications will be shown in the
                              notifications page and a red dot will appear next
                              to the bell icon when you get a new notification.
                            </div>
                          </div>
                        }
                        placement="bottom"
                      >
                        <QuestionMarkCircle
                          className="text-gray-500"
                          size={20}
                        />
                      </Tippy>
                    </div>
                  </div>
                  <div className="w-1/4 text-center text-base font-medium leading-6">
                    Send email
                  </div>
                </div>
                <div className="row-span-3 mt-2 mb-4 border-b border-gray-300"></div>
                <div className="flex">
                  <div className="w-1/2">All Notifications</div>
                  <div className="flex w-1/4 justify-center"></div>
                  <div className="flex w-1/4 justify-center">
                    <Checkbox
                      onChange={() =>
                        setFieldValue(
                          'email_notifications_active',
                          !values.email_notifications_active
                        )
                      }
                      checked={values?.email_notifications_active}
                    />
                  </div>
                </div>
              </div>
              <div className="flex">
                {dirty && (
                  <div className="ml-auto mr-4 flex space-x-2">
                    <Button onClick={() => handleOnCancel(resetForm)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={submitForm}
                      loading={isSubmitting}
                      type="button"
                      theme="confirm"
                    >
                      {isSubmitting ? 'Submitting' : 'Save'}
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </Formik>
      </div>
    </CommunityLayout>
  )
}
