import React, {
  FunctionComponent,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import {
  CheckCircle,
  Exclamation,
  ExclamationCircle,
  InformationCircle,
  X,
} from '@styled-icons/heroicons-solid'
import { StyledIcon } from '@styled-icons/styled-icon'
import classNames from 'classnames'
import { FlashMessageData, FlashMessagesContext } from './flashMessagesContext'

export type FlashMessageProps = FlashMessageData

export const FlashMessage: FunctionComponent<FlashMessageProps> = props => {
  const messageContext = useContext(FlashMessagesContext)
  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => messageContext.remove(props.id),
    [messageContext, props.id]
  )

  const useClickOutside = (ref: React.RefObject<HTMLInputElement>) =>
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleClose(event)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])

  const wrapperRef = useRef(null)
  useClickOutside(wrapperRef)

  useEffect(() => {
    const timeoutId =
      props.duration &&
      setTimeout(() => void messageContext.remove(props.id), props.duration)
    return () => {
      timeoutId && clearTimeout(timeoutId)
    }
  }, [messageContext, props.duration, props.id])

  const outerClassName = classNames('rounded-md p-4 shadow-lg', {
    'bg-green-50': props.type === 'success',
    'bg-red-50': props.type === 'error',
    'bg-blue-50': props.type === 'info',
    'bg-yellow-50': props.type === 'warning',
  })
  const iconClassName = classNames({
    'shrink-0': true,
    'text-green-400': props.type === 'success',
    'text-red-400': props.type === 'error',
    'text-blue-400': props.type === 'info',
    'text-yellow-400': props.type === 'warning',
  })
  const textClassName = classNames('text-sm font-medium', {
    'text-green-800': props.type === 'success',
    'text-red-800': props.type === 'error',
    'text-blue-800': props.type === 'info',
    'text-yellow-800': props.type === 'warning',
  })
  const buttonClassName = classNames(
    'notification-close-button inline-flex rounded-md',
    {
      'bg-green-50 text-green-500 hover:bg-green-100': props.type === 'success',
      'bg-red-50 text-red-500 hover:bg-red-100': props.type === 'error',
      'bg-blue-50 text-blue-500 hover:bg-blue-100': props.type === 'info',
      'bg-yellow-50 text-yellow-500 hover:bg-yellow-100':
        props.type === 'warning',
    }
  )
  const Icon = useMemo<StyledIcon>(
    () =>
      ({
        success: CheckCircle,
        error: ExclamationCircle,
        info: InformationCircle,
        warning: Exclamation,
      }[props.type]),
    [props.type]
  )
  return (
    <div ref={wrapperRef} className={outerClassName}>
      <div className="flex space-x-3">
        <Icon size={20} className={iconClassName} />
        <div className="flex-grow">
          {props.children || <p className={textClassName}>{props.text}</p>}
        </div>
        <div>
          <button
            type="button"
            className={buttonClassName}
            onClick={handleClose}
            aria-label="Close notification"
          >
            <X size={20} className="fill-current" />
          </button>
        </div>
      </div>
    </div>
  )
}
