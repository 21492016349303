import { PlateEditor } from '@udecode/plate'
import isUrl from 'is-url'
import { Editor, Element as SlateElement, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { LinkElement } from './LinkElement'

// Called by a click event on the toolbar button.
export const promptLink = (editor: PlateEditor): void => {
  if (isLinkActive(editor)) {
    if (confirm('Remove link?')) unwrapLink(editor)
  } else {
    const url = window.prompt('Enter the URL of the link:')
    if (url) insertLink(editor, url)
  }
}

export const insertLink = (editor: PlateEditor, url: string): void => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

export const withLinks = (editor: PlateEditor): PlateEditor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return (element as any).type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export function setLinkUrl({
  editor,
  element,
  url,
}: {
  editor: PlateEditor
  element: LinkElement
  url: string
}): void {
  const path = ReactEditor.findPath(editor, element)
  const newProperties: Partial<LinkElement> = { url }
  Transforms.setNodes(editor, newProperties, { at: path })
}

export const isLinkActive = (editor: PlateEditor): boolean => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      (n as any).type === 'a',
  })
  return !!link
}

export const unwrapLink = (editor: PlateEditor): void => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      (n as any).type === 'a',
  })
}

export const wrapLink = (editor: PlateEditor, url: string): void => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'a',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}
