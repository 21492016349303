import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import {
  ChatAlt2,
  FolderOpen,
  Home,
  UserGroup,
} from '@styled-icons/heroicons-outline'
import logo from '../../../public/logo_transparent.png'
import { useExperiences } from '../../features/experiences/api'
import {
  CommunityMembersPathQuery,
  dashboardPath,
  experiencesPath,
  membersPath,
  messengerPath,
} from '../../helpers/paths'
import { useAPI_CanEditCommunity } from '../../hooks/useAPI'
import { selectAllConversations } from '../../selectors/conversations'
import { useCommunityMembersFetch } from '../../utils/useCommunityMembersFetch'
import { NarrowSideMenu } from '../NarrowSideMenu/NarrowSideMenu'
import { NarrowSideMenuLink } from '../NarrowSideMenu/NarrowSideMenuLink'
import { NarrowSideMenuLogo } from '../NarrowSideMenu/NarrowSideMenuLogo'

export const CommunitySideMenu: FunctionComponent = () => {
  const lxCount = useExperiences({ tab: 'all' })?.data?.pagination?.total_count

  const learningLabel = lxCount ? `Learning (${lxCount})` : 'Learning'

  const membersData = useCommunityMembersFetch(membersPath())
  const membersLabel = membersData.pagination
    ? `Members (${membersData.pagination.totalCount})`
    : 'Members'

  const someConversationsUnread = useSelector(selectAllConversations).some(
    c => c.unread
  )

  const canEdit = useAPI_CanEditCommunity()
  const adminMembersQuery: CommunityMembersPathQuery = {
    'filters[active_status]': 'pending,active',
  }

  return (
    <NarrowSideMenu>
      <NarrowSideMenuLogo
        url={dashboardPath()}
        src={logo}
        alt="Learnlife Logo"
        title="Dashboard"
      />
      <NarrowSideMenuLink label="Home" url={dashboardPath()} icon={Home} />
      <NarrowSideMenuLink
        label="Messages"
        url={messengerPath()}
        icon={ChatAlt2}
        indicator={someConversationsUnread}
      />
      <NarrowSideMenuLink
        label={learningLabel}
        url={experiencesPath()}
        icon={FolderOpen}
      />
      <NarrowSideMenuLink
        label={membersLabel}
        url={canEdit ? membersPath(adminMembersQuery) : membersPath()}
        icon={UserGroup}
      />
    </NarrowSideMenu>
  )
}
