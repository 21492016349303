import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router'
import classNames from 'classnames'
import shortid from 'shortid'
import { FlashMessage } from './FlashMessage'
import {
  FlashMessageContext,
  FlashMessageData,
  FlashMessagesContext,
} from './flashMessagesContext'

export const FlashMessages: FunctionComponent = props => {
  const [messages, setMessages] = useState<FlashMessageData[]>([])

  // Clear notifications on location change
  const location = useLocation()
  useEffect(() => setMessages([]), [location.pathname])

  const remove = useCallback<FlashMessageContext['remove']>(
    id => setMessages(messages.filter(m => m.id !== id)),
    [messages]
  )
  const show = useCallback<FlashMessageContext['show']>(flashMessage => {
    const newFlashMessage = { id: shortid.generate(), ...flashMessage }
    // This enables just one flash message.
    setMessages([newFlashMessage])
    // This enables multiple flash message.
    // setFlashMessages([newFlashMessage, ...flashMessage])
  }, [])
  return (
    <FlashMessagesContext.Provider
      value={{
        messages,
        show,
        remove,
      }}
    >
      <div
        className={classNames(
          'fixed top-0 left-1/2 z-[9999]',
          '-translate-x-1/2 translate-y-0 transform',
          'flex flex-col',
          'w-11/12 space-y-2 p-4 sm:w-2/4',
          {
            hidden: messages.length === 0,
          }
        )}
      >
        {messages.map(message => (
          <FlashMessage key={message.id} {...message} />
        ))}
      </div>
      {props.children}
    </FlashMessagesContext.Provider>
  )
}
