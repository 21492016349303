import React, { FunctionComponent } from 'react'
import { useExperiencePrefetch } from './api'

export const PreFetchExperiences: FunctionComponent = () => {
  useExperiencePrefetch({ tab: 'all' })
  useExperiencePrefetch({ tab: 'my-designs' })
  useExperiencePrefetch({ tab: 'my-learning' })

  return <></>
}
