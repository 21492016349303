import React, { FunctionComponent, useMemo } from 'react'
import { useAPI_CanEditExperience, useSWR_Document } from '../../hooks/useAPI'
import { useDocumentId } from '../../hooks/useDocumentId'
import { useExperienceId } from '../../hooks/useExperienceId'
import { ExperienceDocumentDueDateEditor } from './ExperienceDocumentDueDateEditor'

export const ExperienceDocumentDueDate: FunctionComponent<{
  enableEditor?: boolean
}> = ({ enableEditor = false }) => {
  const documentId = useDocumentId()
  const experienceId = useExperienceId()

  const { data } = useSWR_Document(documentId)
  const canEdit = useAPI_CanEditExperience(experienceId)
  const isTask = useMemo(() => data?.data.experience_doc.is_task, [data])
  const formattedDate = useMemo(() => {
    if (!data?.data.experience_doc.due_date) return
    const dueDate = Date.parse(data.data.experience_doc.due_date)

    const [weekday, tz] = new Intl.DateTimeFormat('en-GB', {
      weekday: 'long',
      timeZoneName: 'short',
    })
      .format(dueDate)
      .split(', ')

    // dd/mm/yyyy
    const date = new Intl.DateTimeFormat('en-GB').format(dueDate)

    return `${weekday} ${date} at 23:59 ${tz}`
  }, [data])

  if (enableEditor && data && canEdit && isTask) {
    return (
      <ExperienceDocumentDueDateEditor document={data.data.experience_doc} />
    )
  } else if (data && isTask && formattedDate) {
    return (
      <p className="font-medium">
        <span className="mr-3 text-sm leading-5 text-gray-600">
          Deadline is
        </span>
        <span className="bg-gray-100 px-3 py-1 text-xs leading-4 text-gray-900">
          {formattedDate}
        </span>
      </p>
    )
  } else {
    return null
  }
}
