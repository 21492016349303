import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit'
import { apiConversationsPath, apiMessagesPath } from '../helpers/apiPaths'
import { Conversation, Message } from '../types/entities'
import { RootState } from '../types/store'

export type ConversationsState = EntityState<Conversation>

export const conversationsAdapter = createEntityAdapter<Conversation>()

export const CONVERSATION_END = {}

export const initialState =
  conversationsAdapter.getInitialState<ConversationsState>({
    ids: [],
    entities: {},
  })

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    updateOneConversation: conversationsAdapter.updateOne,
    addOneConversation: conversationsAdapter.addOne,
    setAllConversations: conversationsAdapter.setAll,
    addMessagesToConversation: (
      state,
      { payload: { conversationId, messages } }
    ) => {
      const conversation = state.entities[conversationId]

      if (!conversation) return

      const oldMessages = (conversation.messages ||= [])

      const newMessages = [
        ...new Map(
          [...oldMessages, ...messages].map(item => [item.id, item])
        ).values(),
      ].sort(
        (a: Message, b: Message) =>
          Number(new Date(a.created_at)) - Number(new Date(b.created_at))
      )

      conversationsAdapter.updateOne(state, {
        id: conversationId,
        changes: {
          messages: newMessages,
          updated_at:
            (newMessages[newMessages.length - 1] || {}).created_at ||
            conversation.updated_at,
        },
      })
    },
    setConversationMessages: (
      state,
      { payload: { conversationId, messages } }
    ) => {
      const conversation = state.entities[conversationId]

      if (!conversation) return

      const newMessages = [...messages].sort(
        (a: Message, b: Message) =>
          Number(new Date(a.created_at)) - Number(new Date(b.created_at))
      )

      conversationsAdapter.updateOne(state, {
        id: conversationId,
        changes: {
          messages: newMessages,
          updated_at:
            (newMessages[newMessages.length - 1] || {}).created_at ||
            conversation.updated_at,
        },
      })
    },
  },
})

export const {
  addOneConversation,
  setAllConversations,
  addMessagesToConversation,
  setConversationMessages,
  updateOneConversation,
} = conversationsSlice.actions

const MESSAGES_PER_PAGE = 20

export const fetchConversations = createAsyncThunk<any, void>(
  'conversations/fetchConversation',
  async (_, thunkAPI) => {
    return fetch(`${apiConversationsPath()}`)
      .then(res => res.json())
      .then(res => res.data.conversations)
      .then(conversations => {
        thunkAPI.dispatch(setAllConversations(conversations))
      })
  }
)

export const fetchConversationMessages = createAsyncThunk<any, any>(
  'conversations/fetchConversationMessages',
  async ({ conversationId, page = 1 }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const conversation = state.entities.conversations.entities[conversationId]

    if (MESSAGES_PER_PAGE * page <= (conversation?.messages?.length || 0))
      return Promise.reject()

    const params = new URLSearchParams({
      'pagination[page]': page.toString(),
      'pagination[perPage]': MESSAGES_PER_PAGE,
    } as any)

    return fetch(`${apiMessagesPath(conversationId)}&${params}`)
      .then(res => res.json())
      .then(res => res.data.messages)
      .then(messages => {
        if (messages.length < MESSAGES_PER_PAGE) {
          messages.push(CONVERSATION_END)
        }

        thunkAPI.dispatch(
          addMessagesToConversation({
            conversationId,
            messages,
          })
        )
      })
  }
)

export const { reducer } = conversationsSlice
