import React, {
  FunctionComponent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
  CheckCircle,
  EyeOff,
  Link,
  Trash,
} from '@styled-icons/heroicons-outline'
import { DragHandle } from '@styled-icons/material'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { mutate } from 'swr'
import {
  apiExperiencePath,
  apiExperienceTrashPath,
} from '../../helpers/apiPaths'
import {
  discussionFeedsPath,
  experienceDocumentPath,
  experiencePath,
  noticeBoardsPath,
} from '../../helpers/paths'
import { ApiModel_Get_Experience_Section_Page } from '../../hooks/useAPI'
import { useOnOutsideClick } from '../../hooks/useOnClickOutside'
import { getSelectedExperienceId } from '../../selectors/experiences'
import { fetchDelete } from '../../utils/fetchJson'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { useSelectorOrThrow } from '../../utils/useSelectorOrThrow'
import { ButtonLink } from '../Button/ButtonLink'
import { Dropdown } from '../Dropdown/Dropdown'
import { DropdownItem } from '../Dropdown/DropdownItem'
import { ExperienceSectionPageVisibilityButton } from './ExperienceSectionPageVisibilityButton'
import { ExperienceSectionStateIcon } from './ExperienceSectionStateIcon'

export interface ExperienceSectionPageLinkProps {
  page: ApiModel_Get_Experience_Section_Page
  canEditExperience: boolean
  canViewExperience: boolean
  isDragging: boolean
  enableHoverStyles: boolean
}

export type ExperienceSectionPageLinkStates =
  | 'initial'
  | 'submitting'
  | 'success'
  | 'error'
  | 'menu'
  | 'copied'

export const ExperienceSectionPageLink: FunctionComponent<
  ExperienceSectionPageLinkProps
> = ({
  page,
  canEditExperience,
  canViewExperience,
  isDragging,
  enableHoverStyles,
  ...dragHandleProps
}) => {
  const history = useHistory()
  const flashMessages = useFlashMessages()
  const selectedExperienceId = useSelectorOrThrow(getSelectedExperienceId)

  const [state, setState] = useState<ExperienceSectionPageLinkStates>('initial')
  useEffect(
    function temporaryStateReset() {
      if (state === 'success' || state === 'error') {
        const resetState = () => setState('initial')
        const timeoutId = setTimeout(resetState, 1000)
        return () => clearTimeout(timeoutId)
      }
    },
    [state]
  )

  const handleOnDeletePageClick = useCallback<MouseEventHandler<HTMLElement>>(
    async event => {
      event.preventDefault()
      setState('submitting')
      const response = await fetchDelete(
        page.type === 'doc'
          ? `/api/v1/experience_docs/${page.id}`
          : `/api/v1/discussion_feeds/${page.id}`
      )
      if (response.ok) {
        await mutate(apiExperiencePath(selectedExperienceId))
        await mutate(apiExperienceTrashPath(selectedExperienceId))
        // TODO: redirect only if this is the currently selected document
        history.push(experiencePath(selectedExperienceId))
        flashMessages.show({
          type: 'success',
          text: `Page deleted: ${page.title}`,
        })
      } else {
        setState('error')
        flashMessages.show({
          type: 'error',
          text: `Page could not be deleted: ${page.title}`,
        })
      }
    },
    [history, flashMessages, page, selectedExperienceId]
  )
  const handleOnMenuIconClick = useCallback<MouseEventHandler<SVGElement>>(
    event => {
      event.preventDefault()
      setState(state === 'menu' ? 'initial' : 'menu')
    },
    [state]
  )

  const [dotsRef, menuRef] = useOnOutsideClick(
    () => void setState('initial'),
    [],
    2
  )

  const renderHiddenIndicator =
    page.hidden === true &&
    ((canEditExperience && state === 'initial') || state === 'menu')

  const iconClassName = 'mr-3 text-gray-400 group-hover:text-gray-500'

  const pagePath =
    page.type === 'doc'
      ? experienceDocumentPath(selectedExperienceId, page.id)
      : page.is_noticeboard
      ? noticeBoardsPath(selectedExperienceId, page.id)
      : discussionFeedsPath(selectedExperienceId, page.id)

  const host = import.meta.env.VITE_LEARNINX_HOST
  const handleOnCopyPageClick = useCallback(() => {
    navigator.clipboard.writeText([host, pagePath].join(''))
    setState('copied')
    setTimeout(() => setState('initial'), 1000)
  }, [host, pagePath, setState])

  if (state === 'copied')
    return (
      <div className="flex items-center space-x-1 rounded bg-gray-100 px-6 py-2 text-sm font-medium leading-5 text-gray-700">
        <CheckCircle size={20} />
        <span>Link Copied</span>
      </div>
    )

  return (
    <div className="sidemenu-link-wrapper group flex h-9 w-full items-center space-x-1">
      <ButtonLink
        theme="text"
        className={classNames('relative truncate', {
          'hover:bg-gray-100': enableHoverStyles,
        })}
        fluid
        left
        to={pagePath}
        disabled={!canViewExperience}
        onClick={e => e.currentTarget.blur()}
      >
        <div className="flex h-5 w-full gap-x-2">
          <div className="flex-grow truncate">{page.title}</div>
          {renderHiddenIndicator && (
            <Tippy
              content="Invisible for attendees"
              placement="bottom"
              disabled={!enableHoverStyles}
            >
              <EyeOff
                size={18}
                className="page-hidden-indicator flex-none cursor-default text-gray-400"
              />
            </Tippy>
          )}
          {page.is_task && (
            <div
              className={classNames({
                'group-hover:hidden': canEditExperience && enableHoverStyles,
              })}
            >
              <div className="pill">Task</div>
            </div>
          )}
        </div>
      </ButtonLink>

      <>
        <div
          className={classNames('sidemenu-link-button hidden', {
            'group-hover:flex': !isDragging && enableHoverStyles,
          })}
        >
          <ExperienceSectionStateIcon
            state={state}
            onMenuClick={handleOnMenuIconClick}
            ref={dotsRef as any}
            className={classNames('h-5', {
              'page-edit-dropdown': state === 'initial',
            })}
          />
        </div>
        {state === 'menu' && (
          <Dropdown ref={menuRef as any} marginTop={100}>
            <DropdownItem onClick={handleOnCopyPageClick}>
              <Link size={20} className={iconClassName} />
              Copy Link
            </DropdownItem>
            {canEditExperience && (
              <>
                <ExperienceSectionPageVisibilityButton
                  experienceId={selectedExperienceId}
                  page={page}
                  hidden={page.hidden}
                  setState={setState}
                />

                <Tippy
                  content={
                    <div>
                      <div className="font-semibold">Trash page</div>
                      <div className="text-gray-400">
                        You can restore it from the trash bin down at the
                        bottom.
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <DropdownItem onClick={handleOnDeletePageClick}>
                    <Trash size={20} className={iconClassName} />
                    Delete
                  </DropdownItem>
                </Tippy>
              </>
            )}
          </Dropdown>
        )}
        {canEditExperience && (
          <button
            className={classNames('sidemenu-link-button', {
              'group-hover:flex': enableHoverStyles,
              'sm:hidden': !isDragging,
            })}
            {...dragHandleProps}
            tabIndex={-1}
          >
            <DragHandle size={16} className="text-gray-500" />
          </button>
        )}
      </>
    </div>
  )
}
