import { EntityId } from '@reduxjs/toolkit'
import { sectionSchema } from '../../schemas/sections'
import { reduxFetch } from '../../utils/reduxFetch'

export const renameDocumentFetch = (
  documentId: EntityId,
  name: string
): Promise<{
  experienceDoc: Partial<Document>
}> => {
  const fetch = reduxFetch<{ experienceDoc: Partial<Document> }>({
    experienceSection: sectionSchema,
  })
  return fetch(`/api/v1/experience_docs/${documentId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      experience_doc: {
        title: name,
      },
    }),
  })
}
