import React, { FunctionComponent } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import {
  ApiModel_Get_Experience_Section,
  ApiModel_Get_Experience_Section_Page,
} from '../../hooks/useAPI'
import { ExperienceSectionPageLink } from './ExperienceSectionPageLink'
import { ExperienceSectionTitle } from './ExperienceSectionTitle'
import { SideMenuSectionPages } from './SideMenuSectionPages'
import { SideMenuSectionWrapper } from './SideMenuSectionWrapper'

export interface ExperienceSectionProps {
  section: ApiModel_Get_Experience_Section
  index: number
  canEditExperience: boolean
  canViewExperience: boolean
  enableHoverStyles: boolean
}

export const ExperienceSection: FunctionComponent<ExperienceSectionProps> = ({
  section,
  index,
  canEditExperience,
  canViewExperience,
  enableHoverStyles,
}) => {
  const pagesFilter = canEditExperience
    ? () => true
    : (page: ApiModel_Get_Experience_Section_Page) => !page.hidden
  return (
    <Draggable
      isDragDisabled={!canEditExperience}
      draggableId={section.id as string}
      index={index}
    >
      {(provided, sectionDraggableSnapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          // This pixel of padding prevents margin collapsing, which results in
          // jankiness when dragging sections.
          className="py-px"
        >
          <SideMenuSectionWrapper sectionId={section.id}>
            <ExperienceSectionTitle
              sectionId={section.id}
              title={section.title}
              hidden={section.hidden}
              canEditExperience={canEditExperience}
              {...provided.dragHandleProps}
              isDragging={sectionDraggableSnapshot.isDragging}
              enableHoverStyles={enableHoverStyles}
            />
            <Droppable droppableId={section.id as string} type="doc">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <SideMenuSectionPages
                    sectionId={section.id}
                    showHiddenPages={canEditExperience}
                    showAddPageButton={canEditExperience}
                  >
                    {section.pages.filter(pagesFilter).map((page, index) => {
                      return (
                        <Draggable
                          key={page.id}
                          draggableId={page.id as string}
                          index={index}
                          isDragDisabled={!canEditExperience}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              className="mb-2"
                            >
                              <ExperienceSectionPageLink
                                page={page}
                                canEditExperience={canEditExperience}
                                canViewExperience={canViewExperience}
                                isDragging={snapshot.isDragging}
                                enableHoverStyles={enableHoverStyles}
                                {...provided.dragHandleProps}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </SideMenuSectionPages>
                </div>
              )}
            </Droppable>
          </SideMenuSectionWrapper>
        </div>
      )}
    </Draggable>
  )
}
