import { useDebugValue } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import useSWR, { SWRResponse } from 'swr'
import { useCurrentCommunity, useMe } from '../features/users/useMe'
import {
  apiDiscussionFeedPath,
  apiExperienceMembershipsPathParams,
  apiExperienceTrashPath,
} from '../helpers/apiPaths'
import {
  apiDocumentPath,
  apiExperienceInputsPath,
  apiExperienceMembershipsPath,
  apiExperiencePath,
  apiRolesPath,
} from '../helpers/apiPaths'
import { documentSchema } from '../schemas/document'
import { experienceSchema } from '../schemas/experience'
import { experienceInputsSchema } from '../schemas/experienceInputs'
import { feedSchema } from '../schemas/feed'
import { Permission, TrashDoc, TrashSection } from '../types/entities'
import { reduxFetch } from '../utils/reduxFetch'
import { useDocumentId } from './useDocumentId'
import { useExperienceId } from './useExperienceId'

// MODEL ----------------------------------------------

export type ApiModel_Role = {
  id: EntityId
  name: string
  community_id: EntityId
  permissions: [
    {
      key: 'manage_all' | 'manage_own'
      namespace: string
    }
  ]
}

export type ApiModel_UserProfile = {
  id: EntityId
  first_name: string
  last_name: string
  email: string
  avatar_url?: string
}

export type ApiModel_ExperienceMembership = {
  id: EntityId
  email: string
  first_name: string
  last_name: string
  avatar_url?: string
  community_user_id: EntityId
  experience_id: EntityId
  properties: {
    group: ['creators', 'changemakers']
    member_type: ['learning_guide']
  }
  access_level: 'collaborator' | 'creator' | 'attendee' | 'pending'
  archived_at?: Date
  done_at?: Date
  confirmed_at?: Date
}

export type ApiModel_ExperienceDoc = {
  id: EntityId
  title: string
  content: any
  section_id: EntityId
  position: number
  parent_id: EntityId | null
  hidden: boolean
  created_at: string
  is_task: boolean
  due_date: string
}

export type ApiModel_DiscussionFeed = {
  id: EntityId
  title: string
  created_at: string
  is_noticeboard: boolean
  discussion_feed_messages: ApiModel_DiscussionMessage[]
}

export type ApiModel_DiscussionMessage = {
  id: EntityId
  user: Pick<
    ApiModel_UserProfile,
    'id' | 'first_name' | 'last_name' | 'avatar_url'
  >
  created_at: string
  content: any
}

export type ApiModel_ExperienceInput = {
  id: EntityId
  published: string
  unpublished: string
  experience_field_id: EntityId
}

export type TExperienceStatus = 'draft' | 'published' | 'archived'

export type ApiModel_Get_Experience = {
  id: EntityId
  title: string | null
  description: string | null
  community_id: EntityId
  status: TExperienceStatus
  thumbnail: {
    small: string
    original: string
  }
  properties: any
  experience_sections: ApiModel_Get_Experience_Section[]
}

export type ApiModel_Get_Experience_Section = {
  id: EntityId
  title: string
  hidden: boolean
  experience_docs: ApiModel_Get_Experience_Section_Doc[]
  pages: ApiModel_Get_Experience_Section_Page[]
}

export type ApiModel_Get_Experience_Section_Doc = {
  id: EntityId
  title: string
  hidden: boolean
  created_at: string
  is_task: boolean
}

export type ApiModel_Get_Experience_Section_Page = {
  id: EntityId
  title: string
  hidden: boolean
  type: string
  parent_id: EntityId
  sort_position: number
  created_at: string
  is_task: boolean | null
  is_noticeboard: boolean | null
}

export type ApiModel_Get_Experience_Trash = {
  docs: TrashDoc[]
  sections: TrashSection[]
}

export type ApiModel_Post_Experience = {
  data: {
    experience: {
      id: EntityId
      title: string | null
      description: string | null
      community_id: EntityId
      status: TExperienceStatus
      thumbnail: any
      properties: any
    }
  }
}

export type ApiModel_Attachment = {
  small: string
  medium: string
  large: string
  original: string
}

// RESPONSE ----------------------------------------------

export interface Pagination {
  current_page: number
  per_page: number
  total_count: number
  total_pages: number
}

export interface ApiResponse_Base {
  data: any
  errors: any
  metadata: {
    pagination: Pagination
  }
}

export interface ApiResponse_Roles extends ApiResponse_Base {
  data: {
    roles: ApiModel_Role[]
  }
}

export interface ApiResponse_Get_Experience extends ApiResponse_Base {
  data: {
    experience: ApiModel_Get_Experience
  }
}

export interface ApiResponse_Get_Experience_Trash extends ApiResponse_Base {
  data: ApiModel_Get_Experience_Trash
}

export interface ApiResponse_ExperienceMemberships extends ApiResponse_Base {
  data: {
    experience_memberships: ApiModel_ExperienceMembership[]
  }
}

export interface ApiResponse_ExperienceDoc extends ApiResponse_Base {
  data: {
    experience_doc: ApiModel_ExperienceDoc
  }
}

export interface ApiResponse_DiscussionFeed extends ApiResponse_Base {
  data: {
    discussion_feed: ApiModel_DiscussionFeed
  }
}

export interface ApiResponse_ExperienceInputs extends ApiResponse_Base {
  data: {
    experience_inputs: ApiModel_ExperienceInput[]
  }
}

export interface ApiResponse_Post_Conversation extends ApiResponse_Base {
  data: {
    conversation: {
      id: EntityId
      updated_at: string
      created_at: string
    }
  }
}

export type ApiResponse_Get_Attachment = {
  data: {
    attachment: ApiModel_Attachment
  }
}

// useSWR ----------------------------------------------

export const useSWR_Roles = (
  communityId: EntityId
): SWRResponse<ApiResponse_Roles, any> => {
  return useSWR<ApiResponse_Roles>(apiRolesPath(communityId))
}

export const useSWR_MyExperienceMemberships = (experienceId?: EntityId) => {
  const communityId = useCurrentCommunity().id
  const { data: profile } = useMe()
  const swr = useSWR

  if (!profile || !communityId) {
    return swr<ApiResponse_ExperienceMemberships>(null)
  }

  return swr<ApiResponse_ExperienceMemberships>(
    apiExperienceMembershipsPath({
      communityId,
      experienceId,
      userId: profile.id,
    })
  )
}

export const useSWR_ExperienceMemberships = (
  params: apiExperienceMembershipsPathParams
): SWRResponse<ApiResponse_ExperienceMemberships, any> => {
  return useSWR<ApiResponse_ExperienceMemberships>(
    apiExperienceMembershipsPath(params)
  )
}

export const useSWR_Experience = (
  experienceId: EntityId
): SWRResponse<ApiResponse_Get_Experience, Error> => {
  const key = apiExperiencePath(experienceId)
  const fetcher = reduxFetch({ experience: experienceSchema })
  return useSWR(key, fetcher)
}

export const useSWR_ExperienceTrash = (
  experienceId: EntityId
): SWRResponse<ApiResponse_Get_Experience_Trash, any> => {
  return useSWR<ApiResponse_Get_Experience_Trash>(
    apiExperienceTrashPath(experienceId)
  )
}

export const useSWR_Document = (
  documentId: EntityId
): SWRResponse<ApiResponse_ExperienceDoc, any> => {
  const docPath = apiDocumentPath(documentId)
  const docFetcher = reduxFetch({ experienceDoc: documentSchema })
  return useSWR(docPath, docFetcher)
}

export const useSWR_Feed = (
  discussionFeedId: EntityId
): SWRResponse<ApiResponse_DiscussionFeed, any> => {
  const feedPath = apiDiscussionFeedPath(discussionFeedId)
  const feedFetcher = reduxFetch({ discussionFeeds: feedSchema })
  return useSWR(feedPath, feedFetcher)
}

export const useSWR_Inputs = (
  documentId: EntityId | undefined,
  membershipId: EntityId | undefined
): SWRResponse<ApiResponse_ExperienceInputs, any> => {
  const apiPath =
    documentId && membershipId
      ? apiExperienceInputsPath(documentId, membershipId)
      : null
  const fetcher = reduxFetch({ experienceInputs: [experienceInputsSchema] })
  return useSWR(apiPath, fetcher)
}

export const useSWR_MyInputs = (): SWRResponse<
  ApiResponse_ExperienceInputs,
  any
> => {
  const experienceId = useExperienceId()
  const { data: memData } = useSWR_MyExperienceMemberships(experienceId)
  const membershipId = memData?.data.experience_memberships[0]?.id
  const documentId = useDocumentId()
  return useSWR_Inputs(documentId, membershipId)
}

// useAPI ----------------------------------------------

export const useAPI_MyRole = () => {
  const communityId = useCurrentCommunity().id
  const swrRoles = useSWR_Roles
  const { data: profile } = useMe()
  const community = profile?.communities?.find(c => c.id === communityId)
  const roleId = community?.role_id
  if (!roleId || !communityId) return undefined

  const { data: roles } = swrRoles(communityId)
  const role = roles?.data.roles.find(role => role.id === roleId)
  return role
}

const manageAllExperiences = {
  key: 'manage_all',
  namespace: 'experiences',
}

const manageAllCommunityUsers = {
  key: 'manage_all',
  namespace: 'community_users',
}

export const hasPermission = (
  permissions: Permission[],
  permission: Permission
): boolean =>
  permissions.some(
    p => p.key === permission.key && p.namespace === permission.namespace
  )

export const useAPI_CanEditExperience = (experienceId: EntityId): boolean => {
  const role = useAPI_MyRole()
  const canManageAll =
    role && hasPermission(role?.permissions, manageAllExperiences)
  useDebugValue({ role })
  const { data: membershipData } = useSWR_MyExperienceMemberships(experienceId)

  if (canManageAll) return true
  if (!membershipData) return false

  const editingLevels = ['creator', 'collaborator']
  const users = membershipData.data.experience_memberships
  const canEdit = users.some(u => editingLevels.includes(u.access_level))
  return canEdit
}

export const useAPI_CanViewExperience = (experienceId: EntityId): boolean => {
  const role = useAPI_MyRole()
  const { data: usersData } = useSWR_MyExperienceMemberships(experienceId)
  const membership = usersData?.data.experience_memberships[0]
  const canManageAll =
    role && hasPermission(role.permissions, manageAllExperiences)
  const isMember = !!membership && membership.access_level !== 'pending'

  return canManageAll || isMember
}

export const useAPI_CanManageAllExperiences = (): boolean | undefined => {
  const role = useAPI_MyRole()
  return role && hasPermission(role.permissions, manageAllExperiences)
}

export const useAPI_CanViewEvidence = (
  experienceId: EntityId
): boolean | undefined => {
  const memberships = useSWR_MyExperienceMemberships(experienceId)
  const myMembership = findMembershipByAccessLevel(
    memberships,
    'creator',
    'collaborator'
  )
  const isCreatorOrColab = myMembership && Boolean(myMembership)
  const canManageAllExperiences = useAPI_CanManageAllExperiences()
  return canManageAllExperiences || isCreatorOrColab
}

export const findMembershipByAccessLevel = (
  memberships: SWRResponse<ApiResponse_ExperienceMemberships, any>,
  ...access_level: string[]
): ApiModel_ExperienceMembership | undefined => {
  return memberships.data?.data.experience_memberships.find(m =>
    access_level.includes(m.access_level)
  )
}

export const useAPI_CanEditCommunity = (): boolean => {
  const role = useAPI_MyRole()
  if (!role) return false
  if (hasPermission(role.permissions, manageAllExperiences)) return true
  return false
}

export const useAPI_CanManageAllCommunityUsers = (): boolean => {
  const role = useAPI_MyRole()
  if (!role) return false
  return hasPermission(role.permissions, manageAllCommunityUsers)
}

export const useAPI_CurrentCommunityUser = () => {
  const { data } = useMe()
  const communityId = useCurrentCommunity().id
  const communityUser = data?.communities?.find(c => c.id === communityId)
  return communityUser
}

export const useAPI_IsThisCommunityUserMe = (
  communityUserId: EntityId
): boolean => {
  const { data } = useMe()
  const communityId = useCurrentCommunity().id
  if (!communityId || !data) return false
  const community = data?.communities?.find(c => c.id === communityId)
  return community?.community_user_id === communityUserId
}

export const useAPI_CanEditCommunityUser = (
  communityUserId: EntityId
): boolean => {
  const canManageAllCommunityUsers = useAPI_CanManageAllCommunityUsers()
  const isThisCommunityUserMe = useAPI_IsThisCommunityUserMe(communityUserId)
  return canManageAllCommunityUsers || isThisCommunityUserMe
}

export const useAPI_Community = (communityId: EntityId) => {
  const { data } = useMe()
  const community = data?.communities?.find(c => c.id === communityId)
  return community
}
