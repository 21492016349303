import { createSelector } from '@reduxjs/toolkit'
import { experienceInputsAdapter } from '../slices/experienceInputs'
import { RootState } from '../types/store'
import { getEntities } from './base'

const selector = createSelector(
  getEntities,
  entities => entities.experienceInputs
)

const experiencesDefaultSelectors =
  experienceInputsAdapter.getSelectors<RootState>(selector)

export const getExperienceInputsAll = experiencesDefaultSelectors.selectAll
