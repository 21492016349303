import React from 'react'
import { DragIndicator } from '@styled-icons/material/DragIndicator'
import Tippy from '@tippyjs/react'
import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_MEDIA_EMBED,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
  grabberTooltipProps,
  withDraggables,
} from '@udecode/plate'
import { ELEMENT_ATTACHMENT } from '../attachment'
import { ELEMENT_CALLOUT } from '../callout'
import { ELEMENT_FILE_FIELD } from '../file-field/createFileFieldPlugin'
import { ELEMENT_IMAGE } from '../image'
import { ELEMENT_TEXT_AREA_FIELD } from '../text-area-field/createTextAreaFieldPlugin'
import { ELEMENT_TEXT_FIELD } from '../text-field/createTextFieldPlugin'

export const withStyledDraggables = (
  components: Record<string, any>
): Record<string, any> => {
  return withDraggables(components, [
    {
      level: 0,
      keys: [
        ELEMENT_PARAGRAPH,
        ELEMENT_BLOCKQUOTE,
        ELEMENT_TODO_LI,
        ELEMENT_H1,
        ELEMENT_H2,
        ELEMENT_H3,
        ELEMENT_H4,
        ELEMENT_H5,
        ELEMENT_H6,
        ELEMENT_IMAGE,
        ELEMENT_ATTACHMENT,
        ELEMENT_OL,
        ELEMENT_UL,
        ELEMENT_TABLE,
        ELEMENT_MEDIA_EMBED,
        ELEMENT_CODE_BLOCK,
        ELEMENT_TEXT_FIELD,
        ELEMENT_TEXT_AREA_FIELD,
        ELEMENT_FILE_FIELD,
        ELEMENT_CALLOUT,
      ],
      // eslint-disable-next-line react/display-name
      onRenderDragHandle: ({ ...otherProps }) => (
        <Tippy {...grabberTooltipProps}>
          <button type="button" aria-label="Drag Button" {...otherProps}>
            <DragIndicator
              style={{
                width: 18,
                height: 18,
                color: 'rgba(55, 53, 47, 0.3)',
              }}
            />
          </button>
        </Tippy>
      ),
    },
    {
      keys: [
        ELEMENT_PARAGRAPH,
        ELEMENT_UL,
        ELEMENT_OL,
        ELEMENT_IMAGE,
        ELEMENT_ATTACHMENT,
        ELEMENT_CALLOUT,
        ELEMENT_TEXT_FIELD,
        ELEMENT_TEXT_AREA_FIELD,
        ELEMENT_FILE_FIELD,
      ],
      styles: {
        gutterLeft: {
          padding: '16px 0 0',
          fontSize: 0,
        },
      },
    },
    {
      keys: [ELEMENT_H1, ELEMENT_H2, ELEMENT_H3],
      styles: {
        gutterLeft: {
          padding: '24px 0 0',
          fontSize: 0,
        },
      },
    },
  ])
}
