import React, { FunctionComponent } from 'react'
import { useToggle } from '../../hooks/useToggle'
import { Dropdown } from '../Dropdown/Dropdown'
import { ExperienceStatus_MenuButton } from './ExperienceStatus'
import { ExperienceStatus_DropdownMenu_ArchiveButton } from './ExperienceStatus_DropdownMenu_ArchiveButton'

export const ExperienceStatus_DraftView_DropdownMenu: FunctionComponent<{
  setSaving: any
}> = ({ setSaving }) => {
  const [menuOpen, toggleMenuOpen] = useToggle(false)

  return (
    <div id="status-dropdown-draft" className="relative">
      <ExperienceStatus_MenuButton onClick={toggleMenuOpen} />
      {menuOpen && (
        <Dropdown>
          <ExperienceStatus_DropdownMenu_ArchiveButton setSaving={setSaving} />
        </Dropdown>
      )}
    </div>
  )
}
