import React, { FunctionComponent } from 'react'
import { Eye, EyeOff } from '@styled-icons/heroicons-outline'
import Tippy from '@tippyjs/react'
import { DropdownItem } from '../Dropdown/DropdownItem'

export interface ExperienceSectionVisibilityButtonProps {
  type: 'page' | 'section'
  hidden: boolean
  onClick: React.MouseEventHandler
}

export const ExperienceSectionVisibilityButton: FunctionComponent<
  ExperienceSectionVisibilityButtonProps
> = ({ type, onClick, hidden }) => {
  const iconSize = 20
  const className = `mr-3 text-gray-400 group-hover:text-gray-500 ${
    hidden ? `show-${type}-button` : `hide-${type}-button`
  }`

  return hidden ? (
    <Tippy
      content={
        <div>
          <div className="font-semibold">Show {type}</div>
          <div className="font-medium text-gray-400">
            Make the {type} visible to attendees
          </div>
        </div>
      }
      placement="right"
    >
      <DropdownItem onClick={onClick}>
        <Eye size={iconSize} className={className} />
        Show {type}
      </DropdownItem>
    </Tippy>
  ) : (
    <Tippy
      content={
        <div>
          <div className="font-semibold">Hide {type}</div>
          <div className="font-medium text-gray-400">
            Make the {type} invisible to attendees
          </div>
        </div>
      }
      placement="right"
    >
      <DropdownItem onClick={onClick}>
        <EyeOff size={iconSize} className={className} onClick={onClick} />
        Hide {type}
      </DropdownItem>
    </Tippy>
  )
}
