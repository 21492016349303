import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useState,
} from 'react'
import 'rc-drawer/assets/index.css'
import Drawer from 'rc-drawer'
import { Airbrake } from '../AirbrakeBoundary/Airbrake'

export interface LayoutProps {
  topComponent: ReactElement
  sideComponent: ReactElement
  // Use to prevent layout jumps on pages which change in length during use.
  // Defaults to auto.
  scrollbar?: 'always' | 'auto'
}

export const Layout: FunctionComponent<LayoutProps> = props => {
  const alwaysShowScrollbar = props.scrollbar === 'always'

  // This block of code looks useless but, if you remove it,
  // some notifications disappear. It took me hours to figure it out
  // and I have no idea why these line affect notifications. For example:
  //
  // 1. Visit an experience document BY VISITING THE URL DIRECTLY
  // 2. Click on "Make a Copy"
  // 3. The success notification only appears if this block is here.
  //    To see for yourself, remove the 4 lines below and repeat the steps.
  const [topMenuHeight, setTopMenuHeight] = useState(0)
  const topMenuRef = useCallback((node: HTMLDivElement | null) => {
    setTopMenuHeight(node?.getBoundingClientRect()?.bottom ?? 0)
  }, [])

  return (
    <div className="learninx-layout bg-gray-50">
      <header
        ref={topMenuRef}
        className="learninx-layout-header sticky top-0 z-30 border-b border-gray-300"
      >
        {props.topComponent}
      </header>

      {/* Mobile menu */}
      <Drawer
        width="16rem"
        level={null}
        wrapperClassName="sm:hidden bg-gray-50"
      >
        <nav>{props.sideComponent}</nav>
      </Drawer>

      {/* Desktop menu */}
      <nav className="learninx-layout-menu hidden w-full sm:block">
        <div
          className="vanishing-scrollbar fixed w-[16rem] overflow-y-scroll"
          style={{ height: `calc(100vh - ${topMenuHeight}px)` }}
        >
          {props.sideComponent}
        </div>
      </nav>

      <main
        className={
          alwaysShowScrollbar ? 'overflow-y-scroll' : 'vanishing-scrollbar'
        }
      >
        <Airbrake>{props.children}</Airbrake>
      </main>
    </div>
  )
}
