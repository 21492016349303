import React, { FunctionComponent } from 'react'
import { useAirbrake } from '../../hooks/useAirbrake'
import { AirbrakeBoundary } from './AirbrakeBoundary'

// Wrapper to allow useAirbrake hook in a class component
export const Airbrake: FunctionComponent = ({ children }) => {
  const airbrake = useAirbrake()

  return <AirbrakeBoundary airbrake={airbrake}>{children}</AirbrakeBoundary>
}
