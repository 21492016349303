import { useSelector } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'
import { getExperienceInputsAll } from '../../../selectors/experienceInputs'
import { ExperienceInput } from '../../../types/entities'
import { temporaryInput } from './inputUtils'

export const useInput = (
  fieldId: EntityId,
  membershipId: EntityId
): ExperienceInput => {
  const inputs = useSelector(getExperienceInputsAll)
  const input =
    inputs.find(
      i =>
        i.experienceFieldId === fieldId &&
        i.experienceMembershipId === membershipId
    ) || temporaryInput(fieldId, membershipId)
  return input
}
