import { EntityId } from '@reduxjs/toolkit'
import { useExperienceMembersFetch } from '../../utils/useExperienceMembersFetch'

export function useAddMembers(): (
  checkedUserIds: EntityId[],
  experienceId: EntityId
) => Promise<void> {
  const membersData = useExperienceMembersFetch()

  return async (
    checkedUserIds: EntityId[],
    experienceId: EntityId
  ): Promise<void> => {
    const response = await fetch('/api/v1/experience_memberships', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        experience_memberships: {
          experience_id: experienceId,
          community_user_ids: checkedUserIds,
        },
      }),
    })

    if (response.ok) {
      membersData.mutate()
    } else {
      throw response
    }
  }
}
