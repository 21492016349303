import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

export type TableHeaderProps = React.HTMLAttributes<HTMLTableSectionElement>

export const TableHeader: FunctionComponent<TableHeaderProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <thead {...rest} className={classNames('bg-gray-100', className)}>
      <tr>{children}</tr>
    </thead>
  )
}
