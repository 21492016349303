import React, { FunctionComponent, ReactChild } from 'react'
import Tippy from '@tippyjs/react'

export interface ToolTipProps {
  content?: string
  children: ReactChild
  placement?: 'top' | 'right' | 'bottom' | 'left'
  className?: string
}

export const ToolTip: FunctionComponent<ToolTipProps> = ({
  content,
  children,
  placement = 'bottom',
  className,
}) => {
  if (!content) {
    return <>{children}</>
  }

  // The extra div is required for the editor toolbar buttons to work.
  return (
    <Tippy
      disabled={content === undefined}
      content={content}
      placement={placement}
      className="rounded-lg bg-gray-900 py-1 px-2 text-center text-white"
      delay={[200, 0]}
    >
      <div className={className}>{children}</div>
    </Tippy>
  )
}
