import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>

export const PageTitle: FunctionComponent<Props> = props => {
  return (
    <h1
      {...props}
      className={classNames(
        'text-xl font-semibold text-gray-900 md:text-2xl',
        props.className
      )}
    />
  )
}
