import React, { FunctionComponent, ThHTMLAttributes } from 'react'
import classNames from 'classnames'

export type TableHeaderCellProps = ThHTMLAttributes<HTMLTableHeaderCellElement>

export const TableHeaderCell: FunctionComponent<TableHeaderCellProps> = ({
  className,
  ...rest
}) => {
  return (
    <th
      {...rest}
      className={classNames(
        'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-600',
        className
      )}
    />
  )
}
