import React, { FunctionComponent, useCallback } from 'react'
import { Popup } from 'reactjs-popup'
import { X } from '@styled-icons/heroicons-solid'
import { ApiModel_ExperienceMembership } from '../../hooks/useAPI'
import { useDeleteMembership } from '../../hooks/useDeleteMembership'
import { Button } from '../Button/Button'
import { Modal } from '../Modal/Modal'
import { ToolTip } from '../ToolTip/ToolTip'

export const ExperienceMembersPageDeleteButton: FunctionComponent<{
  user: ApiModel_ExperienceMembership
  afterSubmit?: () => void
}> = ({ user, afterSubmit }) => {
  const deleteMembership = useDeleteMembership()

  const handleDelete = useCallback(async () => {
    await deleteMembership(
      user.id,
      `Succesfully removed ${user.first_name} ${user.last_name}`
    )

    afterSubmit && afterSubmit()
  }, [afterSubmit, user, deleteMembership])

  return (
    <Popup
      trigger={
        <button
          className="remove-member-button flex cursor-pointer items-center"
          aria-label="Remove member"
        >
          <ToolTip content="Remove member">
            <X size={20} />
          </ToolTip>
        </button>
      }
      modal
      lockScroll
      position="center center"
      closeOnEscape
      overlayStyle={{
        borderRadius: '0.5em',
      }}
      contentStyle={{
        background: 'none',
        border: '0px',
      }}
    >
      {(close: () => void) => (
        <div className="sm:max-w-lg">
          <Modal
            title="Remove member"
            description={
              <p className="mt-2 max-w-xl text-sm text-gray-500">
                Once you remove this member, you will lose all data associated
                with them.
              </p>
            }
            buttons={
              <>
                <Button theme="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    close()
                    handleDelete()
                  }}
                  theme="destroy"
                >
                  Remove member
                </Button>
              </>
            }
          />
        </div>
      )}
    </Popup>
  )
}
