import { createPluginFactory } from '@udecode/plate'

export const ELEMENT_IFRAME = 'iframe'

export type ELEMENT_IFRAME = typeof ELEMENT_IFRAME

export const createIframePlugin = createPluginFactory({
  key: ELEMENT_IFRAME,
  isElement: true,
  isVoid: true,
})
