import React from 'react'
import Embed from 'react-embed'
import { TElement, TRenderElementProps } from '@udecode/plate'
import classNames from 'classnames'
import { useFocused, useReadOnly, useSelected } from 'slate-react'
import { ELEMENT_EMBED } from './createEmbedPlugin'

export type EmbedElement = TElement<{
  type: ELEMENT_EMBED
  url: string
}>

export const EmbedElement = ({
  attributes,
  children,
  element,
}: TRenderElementProps<EmbedElement>): JSX.Element => {
  const readOnly = useReadOnly()
  const selected = useSelected()
  const focused = useFocused()

  return (
    <div {...attributes}>
      <div
        contentEditable={false}
        className={classNames({
          'my-4 flex justify-center': true,
          'ring-4': selected && focused,
          'pointer-events-none select-none': !readOnly,
        })}
      >
        <Embed url={element.url} />
      </div>
      {children}
    </div>
  )
}
