import { Slice } from '@reduxjs/toolkit'
import humps from 'humps'
import { normalize, Schema } from 'normalizr'
import { communitySlice } from '../slices/communities'
import { documentsSlice } from '../slices/documents'
import { experienceInputsSlice } from '../slices/experienceInputs'
import { experiencesSlice } from '../slices/experiences'
import { learningOutcomesSlice } from '../slices/learningOutcomes'
import { recordPropertiesSlice } from '../slices/recordProperties'
import { initRequest, setError, setLoading } from '../slices/requests'
import { sectionsSlice } from '../slices/sections'
import { usersSlice } from '../slices/users'
import { store } from '../store'
import { EntityTypes } from '../types/entities'

function sliceFromEntityName(entityName: string): Slice {
  const sliceMap: Record<string, Slice> = {
    users: usersSlice,
    experiences: experiencesSlice,
    learningOutcomes: learningOutcomesSlice,
    recordProperties: recordPropertiesSlice,
    communities: communitySlice,
    sections: sectionsSlice,
    documents: documentsSlice,
    experienceInputs: experienceInputsSlice,
  }
  const slice = sliceMap[entityName]
  if (typeof slice === 'undefined') {
    throw new Error(`Unable to resolve slice for entity name "${entityName}"`)
  }
  return slice
}
const capitalizeFirstChar = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1)

/*
In most cases, you are going to use `reduxFetch` as last argument of `useSWR` hook. SWR requires you to provide your own fetch library:
`useSWR('/api/stuff', fetcher)`
SWR passes all arguments to fetcher, tracks network status and caches the results. It does much more like request deduplication and so on.
You can put any logic into the fetcher
`useSWR('/api/secret', authFetcher)` where before making the call you put access token into http header. It's all up to you.
`reduxFetch` is also a special kind of fetcher what it does is it syncs everything into redux.
Network state of the call is in `requests` and contains something like:
```
requests: {
  '/api/v1/users/profile': {
    loading: false
  },
  communityExperiences: {
    loading: false
  }
},
```
Using either endpoint URL as a key or one provided by passing `requestKey` argument
Then you can query the state of a request anywhere in the app by using regular selectors.
```
const loading = useSelector(state => getRequestLoading(state, 'communityExperiences'))
```

Results of the call are more interesting. You need to describe shape of the response by
providing `entitySchema` which defines relations between returned possibly nested resources.
Normalized resources are then merged into `entities` in redux which is great.
No entity is duplicated if you have user id you know there is only one place in store where to find him.
If you then want to use him in another part of the store use just ids not entire entities.
`employeesOfTheMonth: [1,2,5]` not `employeesOfTheMonth: [{ id: 1, firstName: ... }],`
There should be no disadvantage in using reduxFetch over relugar fetch its just more arguments.

You can even use `reduxFetch` outside `useSWR` it returns fetch sprinkled with redux syncing magic.
```
const allUsers  = await reduxFetch({ users: [userSchema] })('/api/users')
```
*/
export function reduxFetch<T = any>(
  entitySchema: Schema<EntityTypes>,
  config: { requestKey?: string } = {}
): (...args: any) => Promise<T> {
  // When you break rules of react hooks you need to provide your own dispatch
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = store.dispatch
  const reduxSyncedFetch = async (input: RequestInfo, init?: RequestInit) => {
    const _requestKey = config.requestKey || input.toString()
    dispatch(initRequest(_requestKey))
    try {
      const response = await fetch(input, init)
      if (!response.ok) {
        const error = await response.json()
        throw new ApiException(error)
      }
      const json = await response.json()
      const camelized = humps.camelizeKeys(json.data)
      const { entities } = normalize(camelized, entitySchema)
      for (const [entityName, entityData] of Object.entries(entities)) {
        const slice = sliceFromEntityName(entityName)
        const upsertManyActionName = `upsertMany${capitalizeFirstChar(
          slice.name
        )}`
        const upsertMany = slice.actions[upsertManyActionName]
        if (typeof upsertMany === 'undefined') {
          throw new Error(
            `Action with name "${upsertManyActionName}" is required on slice "${slice.name}" for reduxFetch to work`
          )
        }
        dispatch(upsertMany(entityData))
      }
      return json
    } catch (exception: any) {
      console.error(exception)
      dispatch(
        setError({
          key: _requestKey,
          error: { exception: exception.toString() },
        })
      )
      throw exception
    } finally {
      dispatch(setLoading({ key: _requestKey, loading: false }))
    }
  }
  return reduxSyncedFetch
}

export interface ApiErrorResponse<
  TData = Record<string, unknown>,
  TError = Record<string, unknown>
> {
  data: TData
  errors: TError
}
export class ApiException extends Error {
  error: ApiErrorResponse

  constructor(error: ApiErrorResponse, message = 'ApiError') {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
    this.name = this.constructor.name
    this.error = error
  }
}
