import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type BackButtonUrlState = string | null

export const initialState: BackButtonUrlState = null

export const backButtonUrlSlice = createSlice({
  name: 'backButtonUrl',
  initialState: initialState as BackButtonUrlState,
  reducers: {
    setBackButtonUrl: (_, action: PayloadAction<BackButtonUrlState>) =>
      action.payload,
  },
})

export const { setBackButtonUrl } = backButtonUrlSlice.actions

export const { reducer } = backButtonUrlSlice
