import React, { FunctionComponent, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { match, Redirect, Route, RouteProps } from 'react-router-dom'
import { useCurrentCommunity, useMe } from '../../features/users/useMe'
import { homePath } from '../../helpers/paths'
import { setSelectedExperienceId } from '../../slices/selectedExperienceId'
import { LoadingPage } from '../LoadingPage/LoadingPage'
import { MissingCommunityPage } from '../MissingCommunityPage/MissingCommunityPage'

export type AuthorizedRouteProps = RouteProps & {
  computedMatch?: match<any>
}

export const AuthorizedRoute: FunctionComponent<
  AuthorizedRouteProps
> = props => {
  const response = useMe()
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    const selectedExperienceId = props.computedMatch?.params?.experienceId

    if (selectedExperienceId) {
      dispatch(setSelectedExperienceId(selectedExperienceId))
    }
  }, [dispatch, response, props])

  const selectedUserId = response.data?.id
  const selectedCommunityId = useCurrentCommunity().id

  switch (response.status) {
    case 'loading':
      return <LoadingPage />
    case 'error':
      console.error('error', response.error)

      return <Redirect to={homePath()} />
    case 'success':
      if (!selectedUserId) {
        console.warn('missing data, redirecting home')
        console.warn(response.data)

        return <Redirect to={homePath()} />
      } else if (!selectedCommunityId) {
        return <MissingCommunityPage />
      } else {
        return <Route {...props} />
      }
  }
}
