import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { LinkifyWrapper } from '../../LinkifyWrapper/LinkifyWrapper'

export const EvidenceInputBox: FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <LinkifyWrapper>
      <div
        className={classNames(
          'min-h-[2rem] rounded bg-white p-2 text-blue-500',
          className
        )}
        {...props}
      />
    </LinkifyWrapper>
  )
}
