import React, { FunctionComponent } from 'react'
import { Home, Refresh } from '@styled-icons/heroicons-outline'

export const ErrorDiv: FunctionComponent = () => {
  return (
    <div
      id="error"
      style={{ textAlign: 'center' }}
      className="flex h-screen flex-col items-center justify-center gap-10 px-4"
    >
      <h1 className="text-3xl font-light text-gray-700">
        Sorry, something went wrong :(
      </h1>
      <nav className="flex flex-col items-start gap-3 text-left text-lg text-gray-600">
        <button
          onClick={() => window.location.reload()}
          className="text-blue-600 hover:underline"
        >
          <Refresh size={16} /> reload
        </button>
        <a
          href="/dashboard"
          className="font-normal text-blue-600 hover:underline"
        >
          <Home size={16} /> go home
        </a>
      </nav>
      <p className="max-w-lg text-gray-600">
        This problem has been reported, and we will do our best to fix it. If
        it&apos;s ruining your day, please email{' '}
        <a
          href="mailto:devs@learnlife.com"
          className="text-blue-600 hover:underline"
        >
          devs@learnlife.com
        </a>{' '}
        or ping one of us on basecamp. Apologies for the inconvenience!
      </p>
    </div>
  )
}
