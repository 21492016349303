import React from 'react'
import { FunctionComponent } from 'react'
import { experienceEvidenceDocumentPath } from '../../helpers/paths'
import {
  useSWR_Experience,
  useSWR_ExperienceMemberships,
} from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useMembershipId } from '../../hooks/useMembershipId'
import { throwIf } from '../../utils/throwIf'
import { ButtonLink } from '../Button/ButtonLink'
import { EvidenceMemberSwitch } from '../EvidenceMemberSwitch/EvidenceMemberSwitch'
import { ExperienceSectionTitle } from '../ExperienceSection/ExperienceSectionTitle'
import { SideMenuSectionPages } from '../ExperienceSection/SideMenuSectionPages'
import { SideMenuSectionWrapper } from '../ExperienceSection/SideMenuSectionWrapper'

export const ExperienceEvidenceDetailSideMenu: FunctionComponent = () => {
  const experienceId = useExperienceId()
  const membershipId = useMembershipId()

  const experience = useSWR_Experience(experienceId)

  // Querying "index" endpoint because there's no "show" endpoint.
  const memberships = useSWR_ExperienceMemberships({
    experienceId,
    perPage: 999,
  })
  const membership = memberships.data?.data.experience_memberships.find(
    m => m.id === membershipId
  )

  throwIf(experience.error, memberships.error)
  if (!membership || !experience.data) return null

  return (
    <div className="p-4">
      <div className="mb-4">
        <EvidenceMemberSwitch />
      </div>
      <div>
        {experience.data.data.experience.experience_sections
          .filter(s => !s.hidden)
          .map(section => {
            return (
              <SideMenuSectionWrapper key={section.id} sectionId={section.id}>
                <ExperienceSectionTitle
                  sectionId={section.id}
                  title={section.title}
                  hidden={section.hidden}
                />
                <SideMenuSectionPages sectionId={section.id} showHiddenPages>
                  {section.experience_docs
                    .filter(d => !d.hidden)
                    .map(document => {
                      return (
                        <ButtonLink
                          key={document.id}
                          theme="text"
                          className="group relative hover:bg-gray-100"
                          fluid
                          disabled={false}
                          to={experienceEvidenceDocumentPath(
                            experienceId,
                            membershipId,
                            document.id
                          )}
                        >
                          <div className="truncate">{document.title}</div>
                          {document.is_task && (
                            <div className="pill ml-2">Task</div>
                          )}
                        </ButtonLink>
                      )
                    })}
                </SideMenuSectionPages>
              </SideMenuSectionWrapper>
            )
          })}
      </div>
    </div>
  )
}
