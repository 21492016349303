import React, { FunctionComponent } from 'react'
import {
  ExperienceStatus_Container,
  ExperienceStatus_StatusText,
} from './ExperienceStatus'
import { SetSavingProps } from './ExperienceStatus'
import { ExperienceStatus_ArchivedView_DraftButton } from './ExperienceStatus_ArchivedView_DraftButton'
import { ExperienceStatus_CopyButton } from './ExperienceStatus_CopyButton'

export const ExperienceStatus_ArchivedView: FunctionComponent<
  SetSavingProps
> = ({ setSaving }) => {
  return (
    <ExperienceStatus_Container>
      <ExperienceStatus_StatusText title="Archived" textColor="text-gray-400" />
      <div />
      <ExperienceStatus_CopyButton setSaving={setSaving} />
      <ExperienceStatus_ArchivedView_DraftButton setSaving={setSaving} />
    </ExperienceStatus_Container>
  )
}
