import React, { FunctionComponent } from 'react'

export const ExperienceTopMenuWrapper: FunctionComponent = props => {
  return (
    <div
      className="flex h-[64px] items-center bg-gray-800 px-4 text-gray-100"
      {...props}
    />
  )
}
