import React from 'react'
import { ExperienceContentDiv } from '../ExperienceContentDiv/ExperienceContentDiv'
import { ExperienceSideMenu } from '../ExperienceSideMenu/ExperienceSideMenu'
import { ExperienceTopMenu } from '../ExperienceTopMenu/ExperienceTopMenu'
import { Layout } from '../Layout/Layout'
import { ExperienceAddMembersPageContent } from './ExperienceAddMembersPageContent'

export function ExperienceAddMembersPage(): JSX.Element {
  return (
    <Layout
      topComponent={<ExperienceTopMenu />}
      sideComponent={<ExperienceSideMenu />}
      scrollbar="always"
    >
      <ExperienceContentDiv>
        <ExperienceAddMembersPageContent />
      </ExperienceContentDiv>
    </Layout>
  )
}
