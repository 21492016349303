import React, { FunctionComponent } from 'react'
import { ContentCard } from '../../../components/ContentCard/ContentCard'
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar'
import { useAirbrake } from '../../../hooks/useAirbrake'
import {
  useSWR_Document,
  useSWR_MyExperienceMemberships,
  useSWR_MyInputs,
} from '../../../hooks/useAPI'
import { useDocumentId } from '../../../hooks/useDocumentId'
import { useExperienceId } from '../../../hooks/useExperienceId'
import { useCurrentCommunity } from '../../users/useMe'
import {
  ExperienceFeedback,
  useDeleteExperienceFeedback,
  useExperienceFeedback,
} from './api'
import { LXFeedbackForm } from './Form'

// Used when viewing own feedback; evidence view requires List & Form to be
// passed membership & doc ids.
export const ExperienceFeedbackCombo: FunctionComponent = () => {
  const documentId = useDocumentId()
  const experienceId = useExperienceId()
  const isTask =
    useSWR_Document(documentId)?.data?.data?.experience_doc?.is_task
  const inputs = useSWR_MyInputs()?.data?.data?.experience_inputs
  const membership = useSWR_MyExperienceMemberships(
    experienceId
  )?.data?.data?.experience_memberships.find(m => m.access_level === 'attendee')

  if (!isTask || !inputs || !isTask || !membership) {
    return null
  }

  return (
    <ContentCard className="flex flex-col gap-8 px-6 pt-6">
      <ExperienceFeedbackList
        documentId={documentId}
        membershipId={membership.id as string}
      />

      <LXFeedbackForm
        documentId={documentId}
        membershipId={membership.id as string}
        placeholder="Share your thoughts"
      />
    </ContentCard>
  )
}

export const ExperienceFeedbackList: FunctionComponent<{
  documentId: string
  membershipId: string
}> = ({ documentId, membershipId }) => {
  const { data, error, isLoading } = useExperienceFeedback({
    experienceDocId: documentId,
    experienceMembershipId: membershipId,
  })

  if (isLoading) {
    return null
  } else if (error) {
    console.error(error)

    return (
      <div className="ml-10 pl-4 text-gray-300">Could not load comments</div>
    )
  } else {
    {
      const reversedFeedback = [...(data?.feedback || [])].reverse()

      return (
        <div id="comment-list" className="space-y-8">
          {reversedFeedback.map(item => (
            <FeedbackIem key={item.id} item={item} />
          ))}
        </div>
      )
    }
  }
}

const FeedbackIem: FunctionComponent<{
  item: ExperienceFeedback
}> = ({ item }) => {
  return (
    <div id={`comment-${item.id}`}>
      <div className="mb-2 flex items-center space-x-4">
        <UserAvatar size="large" user={{ ...item.author }} />
        <div className="flex-grow">
          <div className="font-weight-500 truncate text-sm">
            {item.author?.first_name}&nbsp;{item.author?.last_name}
          </div>
          <div className="font-weight-500 truncate text-xs uppercase text-gray-500">
            {useFormatDate(item.created_at)}
          </div>
        </div>
        <DeleteButton item={item} />
      </div>
      <div className="font-weight-400 col-start-2 whitespace-pre-wrap text-gray-900 sm:ml-10 sm:pl-4">
        {item.content}
      </div>
    </div>
  )
}

const DeleteButton: FunctionComponent<{ item: ExperienceFeedback }> = ({
  item,
}) => {
  const myCommunityUserId = useCurrentCommunity()?.community_user_id
  const isMyComment = myCommunityUserId === item.author?.id
  const deleteFeedback = useDeleteExperienceFeedback().mutateAsync

  if (!myCommunityUserId || !isMyComment) return null

  const handleClick = async () => {
    const confirmed = confirm(
      `Are you sure you want to delete this comment?\n\n${item.content}`
    )

    if (!confirmed) return

    deleteFeedback(item.id)
  }

  return (
    <button
      onClick={handleClick}
      className="font-weight-600 cursor-pointer text-xs text-gray-400 hover:text-gray-500"
    >
      Delete
    </button>
  )
}

// We don't want to blow up everything if date parsing fails for some reason.
const useFormatDate = (date: string) => {
  const airbrake = useAirbrake()

  try {
    return new Intl.DateTimeFormat('en-BR', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }).format(Date.parse(date))
  } catch {
    airbrake.notify({
      error: new Error('Could not parse date'),
      params: { date },
    })

    return ''
  }
}
