import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { experiencesPath } from '../../helpers/paths'
import { useAPI_CanEditExperience, useSWR_Experience } from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { getBackButtonUrl } from '../../selectors/base'
import { ExperienceAttendeeDropdown } from '../ExperienceAttendeeDropdown/ExperienceAttendeeDropdown'
import { ExperienceStatus } from '../ExperienceStatus/ExperienceStatus'
import { TopMenuBackArrow } from '../TopMenuBackArrow/TopMenuBackArrow'
import { ExperienceTopMenuOverview } from './ExperienceTopMenuOverview'
import { ExperienceTopMenuWrapper } from './ExperienceTopMenuWrapper'

export const ExperienceTopMenu: FunctionComponent = () => {
  const experienceId = useExperienceId()
  const { data } = useSWR_Experience(experienceId)
  const canEdit = useAPI_CanEditExperience(experienceId)
  const defaultBackUrl = experiencesPath({ 'filters[status]': 'published' })
  const backButtonUrl = useSelector(getBackButtonUrl) || defaultBackUrl
  const title = data?.data.experience.title || ''
  if (!data) return null

  return (
    <ExperienceTopMenuWrapper>
      <TopMenuBackArrow
        to={backButtonUrl}
        title="Back to Learning Experiences"
      />
      <ExperienceTopMenuOverview title={title} />
      {canEdit ? <ExperienceStatus /> : <ExperienceAttendeeDropdown />}
    </ExperienceTopMenuWrapper>
  )
}
