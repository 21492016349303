import { createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash-es'

export type CollapsedSectionIdsState = Record<EntityId, boolean>

export const initialState: CollapsedSectionIdsState = {}

export const collapsedSectionIdsSlice = createSlice({
  name: 'selectedExperienceId',
  initialState: initialState as CollapsedSectionIdsState,
  reducers: {
    toggleSectionCollapsedId: (
      state: CollapsedSectionIdsState,
      action: PayloadAction<EntityId>
    ) =>
      state[action.payload]
        ? omit(state, action.payload)
        : { ...state, [action.payload]: true },
  },
})

export const { toggleSectionCollapsedId } = collapsedSectionIdsSlice.actions

export const { reducer } = collapsedSectionIdsSlice
