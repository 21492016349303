import React, { FunctionComponent } from 'react'
import { ChevronRight } from '@styled-icons/heroicons-solid'

export interface NextPageProps {
  onClick: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >['onClick']
}

export const NextPage: FunctionComponent<NextPageProps> = props => {
  return (
    <button
      onClick={props.onClick}
      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
      title="Next Page"
    >
      <ChevronRight size={20} />
    </button>
  )
}
