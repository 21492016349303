import React, { FunctionComponent } from 'react'
import {
  ChatAlt2,
  FolderOpen,
  Home,
  UserGroup,
} from '@styled-icons/heroicons-outline'
import logo from '../../../public/logo_transparent.png'
import {
  dashboardPath,
  experiencesPath,
  membersPath,
  messengerPath,
} from '../../helpers/paths'
import { NarrowSideMenuLogo } from '../NarrowSideMenu/NarrowSideMenuLogo'
import { CommunityMobileMenuLink } from './CommunityMobileMenuLink'

export const CommunityMobileMenu: FunctionComponent = () => {
  return (
    <div className="space-y-3 p-4">
      <NarrowSideMenuLogo
        url={dashboardPath()}
        src={logo}
        alt="Learnlife Logo"
        title="Dashboard"
      />
      <CommunityMobileMenuLink label="Home" url={dashboardPath()} icon={Home} />
      <CommunityMobileMenuLink
        label="Messages"
        url={messengerPath()}
        icon={ChatAlt2}
      />
      <CommunityMobileMenuLink
        label="Learning"
        url={experiencesPath({ 'filters[status]': 'published', tab: 'all' })}
        icon={FolderOpen}
      />
      <CommunityMobileMenuLink
        label="Members"
        url={membersPath()}
        icon={UserGroup}
      />
    </div>
  )
}
