import { createRef, DependencyList, useEffect } from 'react'

type ClickOutsideEventHandler = (event: MouseEvent | TouchEvent) => void

/*
Usage:
  Simple:
    const ref = useOnOutsideClick(() => console.log("hey"))
    <div>Outside click</div>
    <div ref={ref}>Inside click because I have ref from the hook</div>
  Complex:
    const refs = useOnOutsideClick(() => console.log("hey"), [], 2)
    <div>Outside click</div>
    <div ref={ref[0]}>Inside click because I have ref from the hook</div>
    <div ref={ref[1]}>Inside click because I have ref from the hook</div>
  Complex with dependency:
    const bitcoinPrice = useNetworkCall(...)
    const refs = useOnOutsideClick(() => console.log(bitcoinPrice), [bitcoinPrice], 2)
    <div>Outside click</div>
    <div ref={ref[0]}>Inside click because I have ref from the hook</div>
    <div ref={ref[1]}>Inside click because I have ref from the hook</div>
*/
export const useOnOutsideClick = <T extends Element>(
  handler: ClickOutsideEventHandler,
  deps: DependencyList = [],
  numberOfRefs = 1
): React.RefObject<T>[] => {
  const refs = [...Array(numberOfRefs)].map(() => createRef<T>())
  useEffect(() => {
    const listener: ClickOutsideEventHandler = event => {
      const clickedOnInsideElement = refs.some(
        ref => ref.current && ref.current.contains(event.target as Node)
      )
      const isElementMounted = refs.some(ref => Boolean(ref.current))
      if (isElementMounted && !clickedOnInsideElement) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, handler, ...deps])
  return refs
}
