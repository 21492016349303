import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import {
  ClipboardList,
  ExclamationCircle,
  Trash,
  UserGroup,
} from '@styled-icons/heroicons-outline'
import {
  experienceEditPath,
  experienceEvidencesPath,
  experienceMembersPath,
  experiencePath,
  experienceTrashPath,
} from '../../helpers/paths'
import {
  ApiModel_Get_Experience_Section,
  useAPI_CanEditExperience,
  useAPI_CanViewEvidence,
  useAPI_CanViewExperience,
  useSWR_Experience,
  useSWR_ExperienceTrash,
} from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useExperienceMembersFetch } from '../../utils/useExperienceMembersFetch'
import { AddSection } from '../AddSection/AddSection'
import { ButtonLink } from '../Button/ButtonLink'
import { ExperienceSection } from '../ExperienceSection/ExperienceSection'
import { SideMenu } from '../SideMenu/SideMenu'
import { useDragEnd } from './dragDropHandlers'

export const ExperienceSideMenu: FunctionComponent = () => {
  const perPage = 999
  const experienceId = useExperienceId()
  const experience = useSWR_Experience(experienceId)
  const canEdit = useAPI_CanEditExperience(experienceId)
  const canView = useAPI_CanViewExperience(experienceId)
  const canViewEvidence = useAPI_CanViewEvidence(experienceId)
  const trash = useSWR_ExperienceTrash(experienceId)
  const handleDragEnd = useDragEnd()

  const trashEmpty = useMemo<boolean>(() => {
    return trash.data?.data?.docs?.length === 0
  }, [trash])

  const { pagination } = useExperienceMembersFetch({
    'pagination[per_page]': perPage,
    'filters[access_level]': 'creator,collaborator,attendee',
  })

  const sectionsFilter = (section: ApiModel_Get_Experience_Section) =>
    canEdit || !section.hidden

  // Disable hover styles when dragging & dropping
  const [enableHover, setEnableHover] = useState(true)
  const onBeforeCapture = useCallback(() => {
    setEnableHover(false)
  }, [setEnableHover])
  const onDragEnd = useCallback(
    (result: DropResult) => {
      setEnableHover(true)
      handleDragEnd(result)
    },
    [setEnableHover, handleDragEnd]
  )

  // Be careful with how you use margins and manage vertical whitespace within
  // the DragDropContext. Our drag & drop library does not account for margin
  // collapsing, which means if we have any we'll get elements jumping around.
  //
  // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md#unsupported-margin-setups
  // https://css-tricks.com/what-you-should-know-about-collapsing-margins/
  return (
    <SideMenu>
      <div>
        <div className="mb-9 space-y-1">
          <ButtonLink
            to={experiencePath(experienceId)}
            icon={ExclamationCircle}
            fluid
            left
            theme="text"
            className="hover:bg-gray-100"
            activatedBy={experienceEditPath(experienceId)}
          >
            <div>Overview</div>
          </ButtonLink>

          {canView && (
            <ButtonLink
              to={experienceMembersPath(experienceId)}
              icon={UserGroup}
              fluid
              theme="text"
              left
              className="hover:bg-gray-100"
            >
              <div className="flex flex-1 items-center justify-between">
                <div>Members</div>
                <span className="inline-flex items-center rounded-full bg-white px-2.5 py-0.5 text-xs font-medium">
                  {pagination?.totalCount === perPage
                    ? `+${perPage}`
                    : pagination?.totalCount}
                </span>
              </div>
            </ButtonLink>
          )}

          {canViewEvidence && (
            <ButtonLink
              to={experienceEvidencesPath(experienceId)}
              icon={ClipboardList}
              left
              fluid
              theme="text"
              className="hover:bg-gray-100"
            >
              <div>Evidence</div>
            </ButtonLink>
          )}
        </div>
        <div>
          <DragDropContext
            onBeforeCapture={onBeforeCapture}
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId="root" type="section">
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="mb-4 sm:mb-24"
                >
                  {experience.data?.data.experience.experience_sections
                    .filter(sectionsFilter)
                    .map((section, index) => (
                      <ExperienceSection
                        key={section.id}
                        section={section}
                        index={index}
                        canEditExperience={canEdit}
                        canViewExperience={canView}
                        enableHoverStyles={enableHover}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {canEdit && (
          <>
            <AddSection />
            <ButtonLink
              to={experienceTrashPath(experienceId)}
              icon={Trash}
              theme="text"
              fluid
              left
              className="trash-button mt-8 sm:mt-24"
              disabled={trashEmpty}
              activatedBy={experienceTrashPath(experienceId)}
            >
              <div>Trash</div>
            </ButtonLink>
          </>
        )}
      </div>
    </SideMenu>
  )
}
