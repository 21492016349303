import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { difference } from 'lodash'
import { ApiModel_ExperienceMembership } from '../../hooks/useAPI'
import { ExperienceMembersResponse } from '../../utils/useExperienceMembersFetch'
import { TableBody } from '../Table/TableBody'
import { TableCell } from '../Table/TableCell'
import { TableRow } from '../Table/TableRow'
import { ExperienceMembersPageTableRow } from './ExperienceMembersPageTableRow'

export const ExperienceMembersPageTableBody: FunctionComponent<{
  membersData: ExperienceMembersResponse
  canEdit?: boolean
  currentUserId?: EntityId
  colCount: number
}> = ({ membersData, canEdit, currentUserId, colCount }) => {
  const buildRow = useCallback(
    (member: ApiModel_ExperienceMembership): JSX.Element => {
      return (
        <ExperienceMembersPageTableRow
          key={member.id}
          user={member}
          canEdit={canEdit}
          afterSubmit={membersData.mutate}
          currentUserId={currentUserId}
        />
      )
    },
    [membersData.mutate, canEdit, currentUserId]
  )

  const members = useMemo(
    () => membersData.data?.data.experience_memberships || [],
    [membersData]
  )

  const pendingMembers = useMemo(
    () => members.filter(m => m.access_level === 'pending'),
    [members]
  )

  const activeMembers = useMemo(
    () => difference(members, pendingMembers),
    [members, pendingMembers]
  )

  const content = useMemo(() => {
    if (membersData.error) {
      // Error

      return (
        <TableRow>
          <TableCell colSpan={colCount}>Something went wrong</TableCell>
        </TableRow>
      )
    } else if (pendingMembers.length && canEdit) {
      // Pending members

      return (
        <>
          <TableRow>
            <TableCell colSpan={colCount} className="text-lg font-semibold">
              Awaiting Approval
            </TableCell>
          </TableRow>

          {pendingMembers.map(buildRow)}

          <TableRow>
            <TableCell colSpan={colCount} className="text-lg font-semibold">
              Active Members
            </TableCell>
          </TableRow>

          {activeMembers.map(buildRow)}
        </>
      )
    } else if (activeMembers.length) {
      // No pending members

      return activeMembers.map(buildRow)
    } else if (membersData.data) {
      // Empty. Probably impossible?

      return (
        <TableRow>
          <TableCell colSpan={colCount}>No members</TableCell>
        </TableRow>
      )
    } else {
      // Still loading

      return [...Array(10).keys()].map(index => (
        <TableRow key={index}>
          <TableCell colSpan={colCount}>
            <div className="skeleton">Loading card</div>
          </TableCell>
        </TableRow>
      ))
    }
  }, [membersData, buildRow, colCount, pendingMembers, activeMembers, canEdit])

  return <TableBody>{content}</TableBody>
}
