import React, { ChangeEventHandler, FunctionComponent } from 'react'
import { MinusCircle, PlusCircle } from '@styled-icons/heroicons-outline'
import { Checkbox } from '../Checkbox/Checkbox'
import { ExperienceMembersPageGroupName } from '../ExperienceMembersPage/ExperienceMembersPageGroupName'

export interface ExperienceAddMembersPageGroupHeaderProps {
  groupKey: string
  closed: boolean
  checked: boolean
  toggleClosed: ChangeEventHandler
  handleMemberGroupClick: ChangeEventHandler<HTMLInputElement>
}

// {closedGroups.includes(groupKey) ? (
// checked={groupChecked(groupKey)}
export const ExperienceAddMembersPageGroupHeader: FunctionComponent<
  ExperienceAddMembersPageGroupHeaderProps
> = ({ groupKey, closed, checked, toggleClosed, handleMemberGroupClick }) => {
  return (
    <div className="flex items-center rounded border border-gray-300 bg-gray-50 py-2 px-3 text-gray-900">
      <label className="flex items-center gap-2">
        <Checkbox
          id={`group-${groupKey}-checkbox`}
          data-group-key={groupKey}
          onChange={handleMemberGroupClick}
          checked={checked}
        />
        <h2 className="whitespace-nowrap font-medium">
          <ExperienceMembersPageGroupName groupKey={groupKey} />
        </h2>
      </label>
      <label className="group-collapse-button flex w-full flex-grow items-center justify-end">
        <Checkbox onChange={toggleClosed} className="hidden" />
        {closed ? (
          <>
            <span className="mx-1 text-xs text-gray-600">Open</span>
            <PlusCircle
              size={24}
              className="open-group-button cursor-pointer text-gray-400 hover:text-gray-500"
            />
          </>
        ) : (
          <>
            <span className="mx-1 text-xs text-gray-600">Close</span>
            <MinusCircle
              size={24}
              className="close-group-button cursor-pointer text-gray-400 hover:text-gray-500"
            />
          </>
        )}
      </label>
    </div>
  )
}
