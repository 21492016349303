import queryString, { stringifyUrl } from 'query-string'
import useSWR, { SWRResponse } from 'swr'
import { useCurrentCommunity } from '../features/users/useMe'
import { CommunityMembersPathQuery } from '../helpers/paths'
import { PaginationData } from '../types/pagination'

export const useCommunityMembersFetch = <Data = any, Error = any>(
  location: string,
  _query: CommunityMembersPathQuery = {}
): SWRResponse<Data, Error> & {
  pagination: PaginationData | undefined
} => {
  const communityId = useCurrentCommunity()?.id

  const query: any = {
    ...queryString.parse(location),
    community_id: communityId,
    ..._query,
  }

  const response = useSWR(
    stringifyUrl({ url: '/api/v1/community_users', query }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const pagination: PaginationData | undefined = response.data &&
    !response.error && {
      page: Number.parseInt(query['pagination[page]'] || 1, 10),
      perPage: response.data.metadata.pagination.per_page,
      totalCount: response.data.metadata.pagination.total_count,
      totalPages: response.data.metadata.pagination.total_pages,
    }

  return { ...response, pagination }
}
