import { createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit'

export interface CommunityExperiencesResultState {
  ids: EntityId[] | undefined
}

export const initialState: CommunityExperiencesResultState = { ids: undefined }

export const communityExperiencesResultSlice = createSlice({
  name: 'communityExperiencesResult',
  initialState,
  reducers: {
    setCommunityExperiencesResultIds: (
      state,
      action: PayloadAction<EntityId[]>
    ) => {
      state.ids = action.payload
    },
  },
})

export const { setCommunityExperiencesResultIds } =
  communityExperiencesResultSlice.actions

export const { reducer } = communityExperiencesResultSlice
