import { useLocation } from 'react-router-dom'
import queryString, { stringifyUrl } from 'query-string'
import useSWR, { SWRResponse } from 'swr'
import { ExperienceMembersPathQuery } from '../helpers/paths'
import { ApiResponse_ExperienceMemberships } from '../hooks/useAPI'
import { getSelectedExperienceId } from '../selectors/experiences'
import { PaginationData } from '../types/pagination'
import { useSelectorOrThrow } from './useSelectorOrThrow'

export interface ExperienceMembersResponse
  extends SWRResponse<ApiResponse_ExperienceMemberships, Error> {
  pagination: PaginationData | undefined
}

export const useExperienceMembersFetch = <
  Data = ApiResponse_ExperienceMemberships,
  Error = any
>(
  _query: ExperienceMembersPathQuery = {}
): SWRResponse<Data, Error> & { pagination: PaginationData | undefined } => {
  const location = useLocation()
  const experienceId = useSelectorOrThrow(getSelectedExperienceId)

  const query: any = {
    ...queryString.parse(location.search),
    experience_id: experienceId,
    ..._query,
  }

  const response = useSWR(
    stringifyUrl({ url: '/api/v1/experience_memberships', query }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const pagination: PaginationData | undefined = response.data &&
    !response.error && {
      page: Number.parseInt(query['pagination[page]'] || 1, 10),
      perPage: response.data.metadata.pagination.per_page,
      totalCount: response.data.metadata.pagination.total_count,
      totalPages: response.data.metadata.pagination.total_pages,
    }

  return {
    ...response,
    pagination,
  }
}
