import React, { useState } from 'react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Search } from '@styled-icons/heroicons-outline'
import { Formik } from 'formik'
import { noop } from 'lodash'
import useSWR from 'swr'
import { apiExperienceDocCompletionsPath } from '../../helpers/apiPaths'
import {
  experienceEvidenceDetailPath,
  experienceEvidenceDocumentPath,
} from '../../helpers/paths'
import {
  ApiModel_ExperienceMembership,
  useSWR_Experience,
  useSWR_ExperienceMemberships,
} from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { getDocumentsFromExperience } from '../../utils/getDocumentsFromExperience'
import { throwIfObjectIsEmpty } from '../../utils/throwIf'
import { ContentCard } from '../ContentCard/ContentCard'
import { ExperienceContentDiv } from '../ExperienceContentDiv/ExperienceContentDiv'
import { ExperienceSideMenu } from '../ExperienceSideMenu/ExperienceSideMenu'
import { ExperienceTopMenu } from '../ExperienceTopMenu/ExperienceTopMenu'
import { Layout } from '../Layout/Layout'
import { PageTitle } from '../PageTitle/PageTitle'
import { StyledLink } from '../StyledLink/StyledLink'
import { Table } from '../Table/Table'
import { TableBody } from '../Table/TableBody'
import { TableCell } from '../Table/TableCell'
import { TableHeader } from '../Table/TableHeader'
import { TableHeaderCell } from '../Table/TableHeaderCell'
import { TableRow } from '../Table/TableRow'
import { TextField } from '../TextField/TextField'
import { UserAvatar } from '../UserAvatar/UserAvatar'

export const ExperienceEvidencesPage: FunctionComponent = () => {
  return (
    <Layout
      topComponent={<ExperienceTopMenu />}
      sideComponent={<ExperienceSideMenu />}
    >
      <EvidencesTableContainer />
    </Layout>
  )
}

export const EvidencesTableContainer: FunctionComponent = () => {
  const experienceId = useExperienceId()
  const { data: cData } = useSWR(apiExperienceDocCompletionsPath(experienceId))
  const { data: eData } = useSWR_Experience(experienceId)
  const { data: mData } = useSWR_ExperienceMemberships({
    experienceId,
    page: 1,
    perPage: 999,
  })
  const [query, setQuery] = useState('')

  if (!cData || !eData || !mData) return null
  throwIfObjectIsEmpty(cData.errors, eData.errors, mData.errors)

  const docs = getDocumentsFromExperience(eData.data.experience).filter(
    d => d.is_task
  )

  const normalize = (string: string) =>
    string
      .toLowerCase()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')

  const matchesQuery = (m: ApiModel_ExperienceMembership) => {
    const q = normalize(query)
    const firstName = normalize(m.first_name)
    const lastName = normalize(m.last_name)
    return (
      firstName.includes(q) ||
      lastName.includes(q) ||
      q.includes(firstName) ||
      q.includes(lastName)
    )
  }
  const sortedMemberships = mData.data.experience_memberships
    .filter(m => m.access_level === 'attendee')
    .filter(matchesQuery)
    .sort((a, b) => a.first_name.localeCompare(b.first_name))

  return (
    <ExperienceContentDiv>
      <PageTitle className="min-h-10 py-1 px-6 sm:px-0">Evidence</PageTitle>

      <ContentCard className="py-6 sm:px-6">
        {/* Formik is not really used, but TextField only works inside Formik. */}
        <Formik initialValues={{}} onSubmit={noop}>
          <TextField
            name="search"
            placeholder="Search attendee"
            onChange={e => setQuery(e.target.value)}
            rightIcon={Search}
            outerClassName="px-6 sm:px-0 mb-4 lg:w-1/4"
          />
        </Formik>
        <div className="overflow-x-auto">
          <Table className="mb-10">
            <TableHeader>
              <TableHeaderCell>Name</TableHeaderCell>
              {docs.map(d => {
                return <TableHeaderCell key={d.id}>{d.title}</TableHeaderCell>
              })}
            </TableHeader>
            <TableBody>
              {sortedMemberships.map(member => {
                return (
                  <TableRow key={member.id}>
                    <TableCell>
                      <div className="flex items-center space-x-4">
                        <UserAvatar size="large" user={member} />
                        <div>
                          <StyledLink
                            to={experienceEvidenceDetailPath(
                              experienceId,
                              member.id
                            )}
                          >
                            {member.first_name}&nbsp;{member.last_name}
                          </StyledLink>
                        </div>
                      </div>
                    </TableCell>
                    {docs.map(d => {
                      const completion =
                        cData.data.experience_doc_completions.find(
                          (c: any) =>
                            c.experience_doc_id === d.id &&
                            c.experience_membership_id === member.id
                        )
                      return (
                        <TableCell
                          id={`member-${member.id}-doc-${d.id}`}
                          key={d.id}
                        >
                          {completion && (
                            <Link
                              className="pill"
                              to={experienceEvidenceDocumentPath(
                                experienceId,
                                member.id,
                                d.id
                              )}
                            >
                              Done
                            </Link>
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </ContentCard>
    </ExperienceContentDiv>
  )
}
