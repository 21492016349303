import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { reducer as backButtonUrl } from './slices/backButtonUrl'
import { reducer as collapsedSectionIds } from './slices/collapsedSectionIds'
import { reducer as communities } from './slices/communities'
import { reducer as communityExperiencesResult } from './slices/communityExperiencesResult'
import { reducer as conversations } from './slices/conversations'
import { reducer as discussionFeeds } from './slices/discussionFeeds'
import { reducer as documents } from './slices/documents'
import { reducer as experienceFieldIdToInputId } from './slices/experienceFieldIdToInputId'
import { reducer as experienceInputs } from './slices/experienceInputs'
import { reducer as experiences } from './slices/experiences'
import { reducer as learningOutcomes } from './slices/learningOutcomes'
import { reducer as recordProperties } from './slices/recordProperties'
import { reducer as requests } from './slices/requests'
import { reducer as sections } from './slices/sections'
import { reducer as selectedExperienceId } from './slices/selectedExperienceId'
import { reducer as users } from './slices/users'
import { LocalStorageRootState, RootState } from './types/store'
import { loadState, saveState } from './utils/localStorage'

export const store = configureStore<RootState>({
  preloadedState: loadState(),
  reducer: {
    entities: combineReducers({
      experiences,
      recordProperties,
      learningOutcomes,
      users,
      communities,
      sections,
      documents,
      conversations,
      experienceInputs,
      discussionFeeds,
    }),
    requests,
    communityExperiencesResult,
    selectedExperienceId,
    collapsedSectionIds,
    backButtonUrl,
    experienceFieldIdToInputId,
  },
})

export const filteredState = (state: RootState): LocalStorageRootState => ({
  collapsedSectionIds: state.collapsedSectionIds,
})

// We may need to throttle this in the future.
store.subscribe(() => {
  saveState(filteredState(store.getState()))
})
