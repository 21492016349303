import { useCallback } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { mutate } from 'swr'
import { apiExperienceMembershipsPath } from '../helpers/apiPaths'
import { useFlashMessages } from '../utils/useFlashMessages'
import { ApiModel_ExperienceMembership } from './useAPI'
import { useExperienceId } from './useExperienceId'

export function useUpdateMembership(): (
  userId: EntityId,
  newValues: Partial<ApiModel_ExperienceMembership>,
  successMessage?: string
) => Promise<void> {
  const flashMessages = useFlashMessages()
  const experienceId = useExperienceId()

  return useCallback(
    async (
      userId: EntityId,
      newValues: Partial<ApiModel_ExperienceMembership>,
      successMessage?: string
    ) => {
      try {
        const response = await fetch(
          `/api/v1/experience_memberships/${userId}`,
          {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              experience_membership: newValues,
            }),
          }
        )

        if (!response.ok) {
          const data = await response.json()
          const key = Object.keys(data.errors)[0]
          const error = data.errors[key]
          throw new Error(error)
        }
        mutate(
          apiExperienceMembershipsPath({
            experienceId: experienceId,
          })
        )
        if (successMessage) {
          flashMessages.show({
            type: 'success',
            text: successMessage,
          })
        }
      } catch (error: any) {
        console.error(error)

        flashMessages.show({
          type: 'error',
          text: error.message || 'Apologies, something went wrong.',
        })
      }
    },
    [experienceId, flashMessages]
  )
}
