import React, { FunctionComponent } from 'react'
import {
  ExperienceStatus_Container,
  ExperienceStatus_StatusText,
} from './ExperienceStatus'
import { SetSavingProps } from './ExperienceStatus'
import { ExperienceStatus_CopyButton } from './ExperienceStatus_CopyButton'
import { ExperienceStatus_DraftView_DropdownMenu } from './ExperienceStatus_DraftView_DropdownMenu'
import { ExperienceStatus_DraftView_PublishButton } from './ExperienceStatus_DraftView_PublishButton'

export const ExperienceStatus_DraftView: FunctionComponent<SetSavingProps> = ({
  setSaving,
}) => {
  return (
    <ExperienceStatus_Container>
      <ExperienceStatus_StatusText title="Draft" textColor="text-yellow-400" />
      <ExperienceStatus_CopyButton setSaving={setSaving} />
      <ExperienceStatus_DraftView_DropdownMenu setSaving={setSaving} />
      <ExperienceStatus_DraftView_PublishButton setSaving={setSaving} />
    </ExperienceStatus_Container>
  )
}
