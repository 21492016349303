import { EntityId } from '@reduxjs/toolkit'
import { compact, omit } from 'lodash'
import qs from 'qs'
import queryString from 'query-string'
import { URIExperienceParams } from '../features/experiences/api'
import { compactObject } from '../utils/compactObject'

const accountsHost = import.meta.env.VITE_ACCOUNTS_HOST
const accountsCallbackUrl = import.meta.env.VITE_ACCOUNTS_CALLBACK_URL

const accountsQueryString = queryString.stringify({
  app: 'learninx',
  callback_url: accountsCallbackUrl,
})

export const homePath = (): string => '/'
export const signInPath = (): string =>
  `${accountsHost}/sign_in?${accountsQueryString}`
export const signUpPath = (): string =>
  `${accountsHost}/sign_up?${accountsQueryString}`
export const signOutPath = (): string => `${accountsHost}/sign_out`
export const profilePath = (): string => `${accountsHost}/profile`

export type DashboardTab = 'my-learning' | 'my-designs' | 'todo'
export const dashboardPath = (
  tab?: DashboardTab,
  params?: URIExperienceParams
): string => {
  const base = ['/dashboard', tab].join('/')
  const compactedParams = compactObject(omit(params, ['tab']))

  const query = Object.keys(compactedParams).length
    ? `${qs.stringify(compactedParams)}`
    : null

  return compact([base, query]).join('?')
}

export const todoPath = (tab?: 'deadlines' | 'feedback' | 'requests'): string =>
  [dashboardPath('todo'), tab].join('/')
export const missingCommunityPath = (): string => '/missing_community'
export const demoPath = (): string => '/button-demo'

export interface PaginationQuery {
  'pagination[page]'?: number
  'pagination[per_page]'?: number
}
export interface CommunityExperiencesPathQuery extends PaginationQuery {
  tab?: 'all' | 'taking' | 'created'
  'filters[member_id]'?: EntityId
  'filters[author_id]'?: EntityId
  'filters[title]'?: string
  'filters[status]'?: string
  'filters[progress]'?: string
  'filters[properties]'?: string
  order?: string
}
export interface CommunityMembersPathQuery extends PaginationQuery {
  'filters[full_text]'?: string
  'filters[properties]'?: string
  'filters[active_status]'?: string
}

export interface ExperienceMembersPathQuery extends PaginationQuery {
  'filters[access_level]'?: string
}

export const experiencesPath = (
  query?: CommunityExperiencesPathQuery
): string =>
  `/experiences${query ? `?${queryString.stringify(query || {})}` : ''}`

export const experiencePath = (id: EntityId): string => `/experiences/${id}`

export const experienceEditPath = (id: EntityId): string =>
  `${experiencePath(id)}/edit`

export const experienceEvidencesPath = (id: EntityId): string =>
  `/experiences/${id}/evidence`

export const experienceEvidenceDetailPath = (
  experienceId: EntityId,
  membershipId: EntityId
): string => `${experienceEvidencesPath(experienceId)}/${membershipId}`

export const experienceEvidenceDocumentPath = (
  experienceId: EntityId,
  membershipId: EntityId,
  documentId: EntityId
): string =>
  `${experienceEvidenceDetailPath(
    experienceId,
    membershipId
  )}/document/${documentId}`

export const experienceMembersPath = (
  id: EntityId,
  query?: PaginationQuery
): string =>
  `${experiencePath(id)}/members${
    query ? `?${queryString.stringify(query || {})}` : ''
  }`

export const experienceAddMembersPath = (
  id: EntityId,
  query?: PaginationQuery
): string =>
  `${experiencePath(id)}/add-members${
    query ? `?${queryString.stringify(query || {})}` : ''
  }`

export const experienceDocumentPath = (
  id: EntityId,
  documentId: EntityId
): string => `${experiencePath(id)}/document/${documentId}`

export const experienceDocumentEditPath = (
  id: EntityId,
  documentId: EntityId
): string => `${experienceDocumentPath(id, documentId)}/edit`

export const experienceTrashPath = (id: EntityId): string =>
  `${experiencePath(id)}/trash`

export const membersPath = (query?: CommunityMembersPathQuery): string =>
  `/members${query ? `?${queryString.stringify(query || {})}` : ''}`

export const memberProfilePath = (memberId: EntityId): string =>
  `/members/${memberId}`
export const memberProfileAboutPath = (memberId: EntityId): string =>
  `/members/${memberId}/about`
export const memberProfileBitsPath = (
  memberId: EntityId,
  group?: string,
  outcome?: string
): string =>
  [
    '/members',
    memberId,
    'big-ideas-and-skills',
    ...(group ? [group] : []),
    ...(outcome ? [outcome] : []),
  ].join('/')
export const memberProfileLearningPath = (memberId: EntityId): string =>
  `/members/${memberId}/my-learning`

export const messengerPath = (): string => '/messages'
export const conversationPath = (id: EntityId): string => `/messages/${id}`
export const newConversationPath = (): string => `/messages/new`
export const notificationsPath = (): string => `/notifications`
export const settingsPath = (): string => `/settings`
export const discussionFeedsPath = (
  experienceId: EntityId,
  id: EntityId = ''
): string => `${experiencePath(experienceId)}/discussion-feeds/${id}`
export const noticeBoardsPath = (
  experienceId: EntityId,
  id: EntityId = ''
): string => `${experiencePath(experienceId)}/notice-boards/${id}`
