// Fetch with json headers
export const fetchJson = (
  input: RequestInfo,
  init?: RequestInit | undefined
) => {
  return fetch(input, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...init,
  })
}

// Fetch json with POST method
export const fetchPost = (
  input: RequestInfo,
  init?: RequestInit | undefined
) => {
  return fetchJson(input, {
    method: 'POST',
    ...init,
  })
}

// Fetch json with PUT method
export const fetchPut = (
  input: RequestInfo,
  init?: RequestInit | undefined
) => {
  return fetchJson(input, {
    method: 'PUT',
    ...init,
  })
}

// Fetch json with DELETE method
export const fetchDelete = (
  input: RequestInfo,
  init?: RequestInit | undefined
) => {
  return fetchJson(input, {
    method: 'DELETE',
    ...init,
  })
}
