import React, { FunctionComponent, useCallback } from 'react'
import { ChatAlt } from '@styled-icons/heroicons-outline'
import { camelizeKeys } from 'humps'
import { ApiModel_ExperienceMembership } from '../../hooks/useAPI'
import { useFindOrCreateConversation } from '../../hooks/useFindOrCreateConversation'
import { ToolTip } from '../ToolTip/ToolTip'

export const CommunityMemberPageChatButton: FunctionComponent<{
  user: ApiModel_ExperienceMembership
}> = ({ user }) => {
  const getConvo = useFindOrCreateConversation()

  const onClick = useCallback(
    () => getConvo(camelizeKeys(user)),
    [user, getConvo]
  )

  return (
    <ToolTip content="Chat with member">
      <button
        className="chat-button flex cursor-pointer items-center"
        onClick={onClick}
        aria-label="Chat with member"
      >
        <ChatAlt size={24} />
      </button>
    </ToolTip>
  )
}
