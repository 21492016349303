/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck
import type { json } from '../models/json';
import type { Learninx_Experiences_Presenters_V2List } from '../models/Learninx_Experiences_Presenters_V2List';
import type { postV1Experiences } from '../models/postV1Experiences';
import type { postV1ExperiencesClone } from '../models/postV1ExperiencesClone';
import type { putV1ExperiencesId } from '../models/putV1ExperiencesId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExperiencesService {

    /**
     * @returns any get Experience(s)
     * @throws ApiError
     */
    public static getV2ExperiencesId({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/experiences/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List community experiences
     * @returns Learninx_Experiences_Presenters_V2List List community experiences
     * @throws ApiError
     */
    public static getV2Experiences({
        communityId,
        authorId,
        memberId,
        progress,
        title,
        properties,
        status,
        order,
        paginationPage,
        paginationPerPage,
    }: {
        /**
         * ID of community
         */
        communityId: string,
        /**
         * Comma separated list of experience author_id (User ID)
         */
        authorId?: Array<string>,
        /**
         * Experience member_id (User ID, not community user ID)
         */
        memberId?: string,
        /**
         * Progress of the experience membership
         */
        progress?: Array<string>,
        /**
         * Title of experience
         */
        title?: string,
        /**
         * List of properties in format { "prop_key": ["prop_value"] }
         */
        properties?: json,
        /**
         * Comma separated list of statuses
         */
        status?: Array<string>,
        /**
         * Comma separated list of options, descending priority.
         */
        order?: Array<string>,
        /**
         * Page number
         */
        paginationPage?: number,
        /**
         * Number of records per page
         */
        paginationPerPage?: number,
    }): CancelablePromise<Learninx_Experiences_Presenters_V2List> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/experiences',
            query: {
                'community_id': communityId,
                'author_id[]': authorId,
                'member_id': memberId,
                'progress[]': progress,
                'title': title,
                'properties': properties,
                'status[]': status,
                'order[]': order,
                'pagination[page]': paginationPage,
                'pagination[per_page]': paginationPerPage,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static deleteV1ExperiencesId({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/experiences/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any get Experience(s)
     * @throws ApiError
     */
    public static getV1ExperiencesId({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/experiences/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any updated Experience
     * @throws ApiError
     */
    public static putV1ExperiencesId({
        id,
        putV1ExperiencesId,
    }: {
        id: string,
        putV1ExperiencesId: putV1ExperiencesId,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/experiences/{id}',
            path: {
                'id': id,
            },
            body: putV1ExperiencesId,
        });
    }

    /**
     * @returns any created Experience
     * @throws ApiError
     */
    public static postV1Experiences({
        postV1Experiences,
    }: {
        postV1Experiences: postV1Experiences,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/experiences',
            body: postV1Experiences,
        });
    }

    /**
     * @returns any created Clone
     * @throws ApiError
     */
    public static postV1ExperiencesClone({
        postV1ExperiencesClone,
    }: {
        postV1ExperiencesClone: postV1ExperiencesClone,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/experiences/clone',
            body: postV1ExperiencesClone,
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static deleteV1ExperiencesTrashId({
        id,
        pageableId,
        pageableType,
    }: {
        id: string,
        /**
         * The id of the pageable
         */
        pageableId: string,
        /**
         * The type of the pageable
         */
        pageableType: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/experiences/trash/{id}',
            path: {
                'id': id,
            },
            query: {
                'pageable_id': pageableId,
                'pageable_type': pageableType,
            },
        });
    }

    /**
     * @returns any created Trash
     * @throws ApiError
     */
    public static postV1ExperiencesTrashId({
        id,
        pageableId,
        pageableType,
    }: {
        id: string,
        /**
         * The id of the pageable
         */
        pageableId: string,
        /**
         * The type of the pageable
         */
        pageableType: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/experiences/trash/{id}',
            path: {
                'id': id,
            },
            formData: {
                'pageable_id': pageableId,
                'pageable_type': pageableType,
            },
        });
    }

    /**
     * @returns any get Trash(s)
     * @throws ApiError
     */
    public static getV1ExperiencesTrashId({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/experiences/trash/{id}',
            path: {
                'id': id,
            },
        });
    }

}
