import { PlateEditor } from '@udecode/plate'
import { v4 as uuidv4 } from 'uuid'
import { insertNodeOnEmptyLine } from '../insertNodeOnEmptyLine'
import { ELEMENT_FILE_FIELD } from './createFileFieldPlugin'
import { FileFieldElement } from './FileFieldElement'

// Called by a click event on the toolbar button.
export const insertFileField = (editor: PlateEditor): void => {
  const fileField: FileFieldElement = {
    type: ELEMENT_FILE_FIELD,
    fieldId: uuidv4(),
    children: [{ text: '' }],
  }
  insertNodeOnEmptyLine(editor, fileField)
}
