import React, { FunctionComponent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { capitalize } from 'lodash-es'
import { getCommunityUserGroupValueNameByKey } from '../../selectors/communityUserRecordProperties'
import { RootState } from '../../types/store'

export interface ExperienceMembersPageMemberTypeNameProps {
  groupKey: string
}

export const ExperienceMembersPageGroupName: FunctionComponent<
  ExperienceMembersPageMemberTypeNameProps
> = props => {
  const getCommunityUserGroupValueNameByKeyMemoized = useMemo(
    () => getCommunityUserGroupValueNameByKey,
    []
  )
  const memberType =
    useSelector<RootState, string | undefined>(state =>
      getCommunityUserGroupValueNameByKeyMemoized(state, props.groupKey)
    ) || props.groupKey
  return (
    <div>
      {memberType === 'other'
        ? 'Members unassigned to a group'
        : capitalize(memberType)}
    </div>
  )
}
