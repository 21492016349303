import { useQuery, useQueryClient } from '@tanstack/react-query'
import { compact, omit } from 'lodash-es'
import qs from 'qs'
import {
  ExperiencesService,
  Learninx_Experiences_Presenters_V2ListItem,
} from '../../generatedFetchers'
import { compactObject } from '../../utils/compactObject'
import { useCurrentCommunity, useMe } from '../users/useMe'

export type Experience = Learninx_Experiences_Presenters_V2ListItem

// Experiences provide a unique challenge; the URL params are not the same as the
// API params. The API needs a communityId that we don't want to show in the
// URL, and the URL uses a tab param that expands to several params when sent
// to the API.
type APIExperienceParams = Omit<
  Parameters<typeof ExperiencesService.getV2Experiences>[0],
  'properties'
> & {
  // I haven't figured out how to type properties on the API side (swagger), so
  // we'll hack it in here for now.
  properties?: Record<string, unknown>
}

// This type represents query params from the URL bar, parsed into an object
export type URIExperienceParams = Omit<APIExperienceParams, 'communityId'> & {
  tab?: 'my-learning' | 'my-designs' | 'all' | 'none'
}

export const queryKeyExperiences = (params?: APIExperienceParams) => {
  return ['experiences', params] as const
}

export const useExperiences = (params: URIExperienceParams = {}) => {
  const expandedParams = useExperienceAPIParams(params)

  return useQuery({
    queryKey: queryKeyExperiences(expandedParams),
    queryFn: () => queryFn(expandedParams),
    enabled: !!expandedParams,
  })
}

export const useExperiencePrefetch = (params: URIExperienceParams) => {
  const expandedParams = useExperienceAPIParams(params)
  const queryClient = useQueryClient()

  if (!expandedParams) return

  return queryClient.prefetchQuery({
    queryKey: queryKeyExperiences(expandedParams),
    queryFn: () => queryFn(expandedParams),
  })
}

type ExpandedExperienceParams = Parameters<
  typeof ExperiencesService.getV2Experiences
>[0]

const queryFn = (params?: ExpandedExperienceParams) => {
  if (!params) return

  return ExperiencesService.getV2Experiences(params)
}

// Expands tab into full params, for use in API calls
const useExperienceAPIParams = (params: URIExperienceParams = {}) => {
  const { tab, ...rest } = params
  const me = useMe()
  const communityId = useCurrentCommunity().id

  if (!communityId || !me.data?.id) return

  const expandedTabs = {
    'my-learning': {
      memberId: me?.data?.id,
      status: ['published'],
      order: ['last_interaction', 'updated_at'],
    },
    'my-designs': {
      authorId: [me.data.id],
      status: ['draft', 'published'],
      order: ['status', 'updated_at'],
    },
    all: {
      status: ['published'],
      order: ['updated_at'],
    },
    none: {},
  }

  return {
    ...expandedTabs[tab || 'none'],
    ...rest,
    communityId,
  }
}

// URL bar, not API
export const useExperiencesUrl = (
  params: URIExperienceParams,
  basePath = '/experiences'
) => {
  const compacted = compactObject(omit(params, ['tab']))

  const query = Object.keys(compacted).length
    ? `${qs.stringify(compacted)}`
    : null

  return compact([basePath, query]).join('?')
}
