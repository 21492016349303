import React, { FunctionComponent, useCallback, useState } from 'react'
import { Check, X } from '@styled-icons/heroicons-outline'
import { ApiModel_ExperienceMembership } from '../../hooks/useAPI'
import { useDeleteMembership } from '../../hooks/useDeleteMembership'
import { useUpdateMembership } from '../../hooks/useUpdateMembership'
import { Button } from '../Button/Button'

export const ExperienceMembersPagePendingControls: FunctionComponent<{
  member: ApiModel_ExperienceMembership
  afterSubmit?: () => void
}> = ({ member, afterSubmit }) => {
  const updateMembership = useUpdateMembership()
  const deleteMembership = useDeleteMembership()
  const [state, setState] = useState<'approving' | 'denying' | 'initial'>(
    'initial'
  )

  const cleanup = useCallback(() => {
    afterSubmit && afterSubmit()
    setState('initial')
  }, [afterSubmit])

  const handleApprove = useCallback(async () => {
    setState('approving')

    await updateMembership(
      member.id,
      {
        access_level: 'attendee',
      },
      `Accepted ${member.first_name} ${member.last_name}; they are now an Attendee.`
    )
    cleanup()
  }, [cleanup, updateMembership, member])

  const handleDeny = useCallback(async () => {
    setState('denying')
    await deleteMembership(
      member.id,
      `Denied membership to ${member.first_name} ${member.last_name}`
    )

    cleanup()
  }, [cleanup, deleteMembership, member])

  return (
    <div className="flex flex-row items-center gap-2 lg:gap-4">
      <Button
        theme="confirm"
        icon={Check}
        onClick={handleApprove}
        loading={state === 'approving'}
        disabled={state !== 'initial'}
        fluid
      >
        Approve
      </Button>

      <Button
        theme="destroy"
        icon={X}
        onClick={handleDeny}
        loading={state === 'denying'}
        disabled={state !== 'initial'}
        fluid
      >
        Deny
      </Button>
    </div>
  )
}
