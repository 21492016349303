import React, {
  FunctionComponent,
  InputHTMLAttributes,
  useCallback,
  useMemo,
} from 'react'
import { ExclamationCircle, X } from '@styled-icons/heroicons-solid'
import { StyledIcon } from '@styled-icons/styled-icon'
import classNames from 'classnames'
import { FieldValidator, useField, useFormikContext } from 'formik'
import { FieldError } from '../FieldError/FieldError'
import { FieldLabel } from '../FieldLabel/FieldLabel'

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  label?: string
  leftIcon?: StyledIcon
  rightIcon?: StyledIcon
  clearButton?: boolean
  outerClassName?: string
  validate?: FieldValidator
  hideErrors?: boolean
}

export const TextField: FunctionComponent<TextFieldProps> = ({
  leftIcon,
  rightIcon,
  clearButton,
  outerClassName,
  validate,
  hideErrors,
  ...textFieldProps
}) => {
  const { submitForm } = useFormikContext()
  const [field, meta, helpers] = useField<string>({
    name: textFieldProps.name,
    validate: validate,
  })
  const showError = meta.touched && meta.error
  const leftIconContent = useMemo(() => {
    const LeftIcon = leftIcon
    return (
      LeftIcon && (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <LeftIcon size={20} className="text-gray-400" />
        </div>
      )
    )
  }, [leftIcon])
  const rightIconContent = useMemo(() => {
    if (showError) {
      return (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircle title="Error" className="h-5 w-5 text-red-600" />
        </div>
      )
    } else if (rightIcon && !clearButton) {
      const RightIcon = rightIcon
      return (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <RightIcon className="h-5 w-5 text-gray-400" />
        </div>
      )
    }
  }, [rightIcon, clearButton, showError])
  const handleClear = useCallback(() => {
    helpers.setValue('')
    submitForm()
  }, [submitForm, helpers])
  const clearButtonContent = useMemo(() => {
    return (
      clearButton &&
      field.value.length > 0 && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <X
            title="Clear"
            size={16}
            className="cursor-pointer text-gray-400"
            onClick={handleClear}
          />
        </div>
      )
    )
  }, [clearButton, handleClear, field])
  const className = classNames(
    textFieldProps.className,
    'shadow-sm block w-full sm:text-sm rounded-md',
    {
      'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500':
        showError,
      'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300':
        !showError,
      'pr-10': rightIcon,
      'pl-10': leftIcon,
    }
  )
  return (
    <div className={outerClassName}>
      {textFieldProps.label && (
        <FieldLabel
          label={textFieldProps.label}
          htmlFor={textFieldProps.name}
          required={textFieldProps.required}
        />
      )}
      <div className="relative rounded-md shadow-sm">
        {leftIconContent}
        <input
          type="text"
          {...field}
          {...textFieldProps}
          className={className}
        />
        {rightIconContent}
        {clearButtonContent}
      </div>
      {!hideErrors && meta.error && <FieldError>{meta.error}</FieldError>}
    </div>
  )
}
