import { insertNodes, PlateEditor, setNodes } from '@udecode/plate'
import { ReactEditor } from 'slate-react'
import { emptyChildren } from '../../config/config'
import { CalloutElement } from '.'
import { ELEMENT_CALLOUT } from './createCalloutPlugin'

export const insertCallout = (editor: PlateEditor, emoji: string): void => {
  const callout: CalloutElement = {
    emoji: emoji,
    children: emptyChildren,
    type: ELEMENT_CALLOUT,
  }

  insertNodes(editor, callout)
}

export function setEmoji(
  editor: PlateEditor,
  element: CalloutElement,
  emoji: string
): void {
  // This triggers a "Cannot resolve a slate node from DOM node" console error.
  const path = ReactEditor.findPath(editor, element)
  const newProperties: Partial<CalloutElement> = { emoji: emoji }
  setNodes(editor, newProperties, { at: path })
}
