import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { mutate } from 'swr'
import {
  apiDiscussionFeedPath,
  apiExperiencePath,
} from '../../helpers/apiPaths'
import {
  ApiModel_DiscussionFeed,
  useAPI_CanEditExperience,
} from '../../hooks/useAPI'
import { AutoSubmit } from '../AutoSubmit/AutoSubmit'
import { renameDiscussionFeedFetch } from './renameDiscussionFeedFetch'

export const DiscussionFeedTitle: FunctionComponent<{
  experienceId: string
  discussionFeed: ApiModel_DiscussionFeed
}> = ({ discussionFeed, experienceId }) => {
  const canEdit = useAPI_CanEditExperience(experienceId)
  const isNew = discussionFeed.title === 'Untitled'
  const initialValues = useMemo(
    () => ({
      title: isNew ? '' : discussionFeed.title,
    }),
    [discussionFeed, isNew]
  )

  const titleClassName =
    'text-2xl text-gray-900 font-semibold w-full outline-none placeholder-gray-300'
  const inputClassName = classNames(
    titleClassName,
    'bg-transparent hover:bg-gray-200 focus:bg-gray-200'
  )

  const saveTitle = useCallback(
    async (feedId: EntityId, title: string) => {
      await renameDiscussionFeedFetch(feedId, title)
      await mutate(apiExperiencePath(experienceId))
      await mutate(apiDiscussionFeedPath(discussionFeed.id))
    },
    [discussionFeed, experienceId]
  )
  const handleSubmit = useCallback(
    async values => {
      await saveTitle(discussionFeed.id, values.title)
    },
    [discussionFeed, saveTitle]
  )

  return canEdit ? (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <Form>
          <AutoSubmit />
          <Field
            name="title"
            component="input"
            className={inputClassName}
            placeholder={'Untitled'}
            autoFocus={isNew}
          ></Field>
        </Form>
      </Formik>
    </div>
  ) : (
    <h1 className={titleClassName}>{discussionFeed.title}</h1>
  )
}
