import { insertNodes, PlateEditor } from '@udecode/plate'
import imageExtensions from 'image-extensions'
import isUrl from 'is-url'
import { emptyChildren } from '../../config/config'
import { ELEMENT_IMAGE, ImageElement } from '.'

export const insertImage = (editor: PlateEditor, url: string): void => {
  const image: ImageElement = {
    type: ELEMENT_IMAGE,
    url: url,
    children: emptyChildren,
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result as string
            if (url) insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      editor.insertData(data)
    }
  }

  insertNodes(editor, image)
}

export const isImageUrl = (url: string): boolean => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return ext ? imageExtensions.includes(ext) : false
}
