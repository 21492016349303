/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck
import type { Learninx_Notifications_Presenters_V2List } from '../models/Learninx_Notifications_Presenters_V2List';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationsService {

    /**
     * List notifications for the current user
     * @returns Learninx_Notifications_Presenters_V2List List notifications for the current user
     * @throws ApiError
     */
    public static getV2Notifications({
        paginationPage,
        paginationPerPage,
        excludeRead,
        only,
        except,
    }: {
        /**
         * Page number
         */
        paginationPage?: number,
        /**
         * Number of records per page
         */
        paginationPerPage?: number,
        /**
         * Exclude read items (boolean)
         */
        excludeRead?: boolean,
        /**
         * Only return these notifications in these categories
         */
        only?: Array<string>,
        /**
         * Return everything except these categories.
         */
        except?: Array<string>,
    }): CancelablePromise<Learninx_Notifications_Presenters_V2List> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/notifications',
            query: {
                'pagination[page]': paginationPage,
                'pagination[per_page]': paginationPerPage,
                'exclude_read': excludeRead,
                'only[]': only,
                'except[]': except,
            },
        });
    }

    /**
     * @returns any get Notification(s)
     * @throws ApiError
     */
    public static getV1Notifications({
        paginationPage,
        paginationPerPage,
        filtersExcludeRead,
        filtersOnly,
        filtersExcept,
    }: {
        /**
         * Page number
         */
        paginationPage?: number,
        /**
         * Number of records per page
         */
        paginationPerPage?: number,
        /**
         * Exclude read items
         */
        filtersExcludeRead?: boolean,
        /**
         * Only return these notifications in these categories
         */
        filtersOnly?: Array<string>,
        /**
         * Return everything except these categories.
         */
        filtersExcept?: Array<string>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/notifications',
            query: {
                'pagination[page]': paginationPage,
                'pagination[per_page]': paginationPerPage,
                'filters[exclude_read]': filtersExcludeRead,
                'filters[only][]': filtersOnly,
                'filters[except][]': filtersExcept,
            },
        });
    }

    /**
     * Mark notifications read
     * @returns any Mark notifications read
     * @throws ApiError
     */
    public static postV2NotificationsReads({
        only,
        except,
        notificationId,
    }: {
        /**
         * Restrict to these categories
         */
        only?: Array<string>,
        /**
         * Exclude these categories.
         */
        except?: Array<string>,
        notificationId?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/notifications/reads',
            formData: {
                'only[]': only,
                'except[]': except,
                'notification_id': notificationId,
            },
        });
    }

    /**
     * Returns an array of IDs
     * @returns any Returns an array of IDs
     * @throws ApiError
     */
    public static postV1NotificationsReads({
        only,
        except,
        notificationId,
    }: {
        /**
         * Restrict to these categories
         */
        only?: Array<string>,
        /**
         * Exclude these categories.
         */
        except?: Array<string>,
        notificationId?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/notifications/reads',
            formData: {
                'only[]': only,
                'except[]': except,
                'notification_id': notificationId,
            },
        });
    }

}
