import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { Experience } from '../types/entities'

export type ExperiencesState = EntityState<Experience>

export const experiencesAdapter = createEntityAdapter<Experience>()

export const initialState =
  experiencesAdapter.getInitialState<ExperiencesState>({
    ids: [],
    entities: {},
  })

export const experiencesSlice = createSlice({
  name: 'experiences',
  initialState,
  reducers: {
    upsertManyExperiences: experiencesAdapter.upsertMany,
    updateOneExperience: experiencesAdapter.updateOne,
  },
})

export const { upsertManyExperiences, updateOneExperience } =
  experiencesSlice.actions

export const { reducer } = experiencesSlice
