import { FunctionComponent } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { useSWR_Document } from '../../hooks/useAPI'

export interface LinkifyExperienceDocProps {
  documentId: EntityId
  tag: HTMLAnchorElement
}

export const LinkifyExperienceDoc: FunctionComponent<
  LinkifyExperienceDocProps
> = ({ documentId, tag }) => {
  const { data } = useSWR_Document(documentId)
  if (data?.data.experience_doc.title)
    tag.innerText = data.data.experience_doc.title

  return null
}
