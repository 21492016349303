import React, {
  FunctionComponent,
  TextareaHTMLAttributes,
  useMemo,
} from 'react'
import classNames from 'classnames'
import { FieldValidator, useField } from 'formik'
import { FieldError } from '../FieldError/FieldError'
import { FieldLabel } from '../FieldLabel/FieldLabel'

export type TextAreaFieldProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string
  label?: string
  required?: boolean
  validate?: FieldValidator
  showErrorsImmediately?: boolean
  hideErrors?: boolean
}

export const textAreaClassName =
  'focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border'

export const TextAreaField: FunctionComponent<TextAreaFieldProps> = ({
  validate,
  showErrorsImmediately,
  hideErrors,
  ...props
}) => {
  const [field, meta] = useField<string>({
    name: props.name,
    validate: validate,
  })

  const showErrors = useMemo(
    () => !hideErrors && (meta.touched || showErrorsImmediately),
    [meta.touched, showErrorsImmediately, hideErrors]
  )

  return (
    <>
      {props.label && (
        <FieldLabel label={props.label} required={props.required} />
      )}

      <textarea
        {...field}
        {...props}
        className={classNames(props.className, textAreaClassName, {
          'border-red-500': showErrors && meta.error,
        })}
      />
      {showErrors && <FieldError>{meta.error}</FieldError>}
    </>
  )
}
