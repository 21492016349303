import { useCallback, useEffect, useMemo, useState } from 'react'
import AwsS3 from '@uppy/aws-s3'
import Core from '@uppy/core'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import { apiAttachmentsPath } from '../../helpers/apiPaths'

export type UseUploadInput = {
  onUploadSuccess?: (attachmentId: string) => void
  onUploadError?: () => void
}

export type UseUploadOutput = {
  uppy: Core.Uppy<Core.TypeChecking>
  upload: (files: FileList) => boolean
  isUploading: boolean
  previewSrc: string | undefined
}

export type UseUploadHook = (input: UseUploadInput) => UseUploadOutput

/*
Upload a file to Learninx backend. Example usage:
const { upload } = useUpload()
const onFileChange = event => upload(event.target.files)
*/
export const useUpload: UseUploadHook = ({
  onUploadSuccess,
  onUploadError,
}) => {
  const [previewSrc, setPreviewSrc] = useState<string>()
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const uppy = useMemo(() => {
    // wtf alert: uppy automatically appends /s3/params to the companionUrl
    return Core({ autoProceed: true })
      .use(ThumbnailGenerator, { thumbnailWidth: 600 })
      .use(AwsS3, { companionUrl: apiAttachmentsPath() })
  }, [])

  const upload = useCallback(
    (files: FileList): boolean => {
      if (isUploading) return false
      uppy.reset()
      Array.from(files).forEach(file => {
        uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        })
      })
      return true
    },
    [uppy, isUploading]
  )

  useEffect(() => {
    addUppyEventListeners()
    return removeUppyEventListeners

    function addUppyEventListeners() {
      uppy.on('upload-success', handleUploadSuccess)
      uppy.on('thumbnail:generated', handleThumbnailGenerated)
      uppy.on('upload', handleUploadBegin)
      uppy.on('error', handleUploadInterrupted)
      uppy.on('cancel-all', handleUploadInterrupted)
    }

    function removeUppyEventListeners() {
      uppy.off('upload-success', handleUploadSuccess)
      uppy.off('thumbnail:generated', handleThumbnailGenerated)
      uppy.off('upload', handleUploadBegin)
      uppy.off('error', handleUploadInterrupted)
      uppy.off('cancel-all', handleUploadInterrupted)
    }

    function handleThumbnailGenerated(
      file: Core.UppyFile<any, any>,
      preview: any
    ) {
      setPreviewSrc(preview)
    }

    function handleUploadBegin() {
      setIsUploading(true)
    }

    function handleUploadInterrupted() {
      setIsUploading(false)
    }

    function handleUploadSuccess(file: Core.UppyFile<any, any>, response: any) {
      const attachmentId = response.uploadURL.split('/').pop()
      postAttachment()

      async function postAttachment() {
        // construct uploaded file data in the format that Shrine expects
        const uploadedFileData = {
          id: attachmentId,
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        }

        const response = await fetch(apiAttachmentsPath(), {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ attachment: uploadedFileData }),
        })

        setIsUploading(false)

        if (response.ok) {
          onUploadSuccess?.(attachmentId /*, imageUrl */) // TODO: endpoint to get the image
        } else {
          onUploadError?.()
          throw new Error(response.statusText)
        }
      }
    }
  })

  return { uppy, upload, isUploading, previewSrc }
}
