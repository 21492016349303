import React, { ButtonHTMLAttributes, forwardRef } from 'react'
import { ButtonBaseProps } from './types'
import { useButton } from './useButton'

export type ButtonProps = ButtonBaseProps &
  ButtonHTMLAttributes<HTMLButtonElement>

/**
 * Basic button. Use for controls which perform actions, generally not leaving
 * the page.
 *
 * Don't pass simple icons as children, pass them as an icon prop.
 * If you do pass children, ensure that they contain a meaningful description.
 * Otherwise, pass a meaningful description using one of the other accessible
 * attributes (listed in src/Button/types.ts - DiscernibleText).
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { content, elementProps } = useButton({ ...props, ref })

    return <button {...elementProps}>{content}</button>
  }
)

Button.displayName = 'Button'
