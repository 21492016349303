import React, { FunctionComponent } from 'react'
import { signOutPath } from '../../helpers/paths'
import logo from '../../learninx_logo.svg'
import { ButtonAnchor } from '../Button/ButtonAnchor'

export const MissingCommunityPage: FunctionComponent = () => {
  return (
    <div className="mx-auto flex h-screen max-w-7xl flex-col items-center justify-center sm:px-6 lg:px-8">
      <img className="mb-6" alt="logo" src={logo} />
      <div className="mb-32 text-gray-700">
        In order to access LearninX, you have to be part of a community.
        <br />
        Please contact your community manager if you are unable to access your
        community.
        <br />
        <br />
        If you are interested in creating a new community, contact us at{' '}
        <a className="text-blue-700" href="mailto:support@learnlife.com">
          support@learnlife.com
        </a>
      </div>
      <ButtonAnchor href={signOutPath()}>Sign out</ButtonAnchor>
    </div>
  )
}
