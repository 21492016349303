import React, { FunctionComponent } from 'react'
import { SpinnerIcon } from '../SpinnerIcon/SpinnerIcon'

export const LoadingPage: FunctionComponent = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <SpinnerIcon size={32} className="animate-spin text-blue-600" />
    </div>
  )
}
