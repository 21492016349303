/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck

export type putV1ExperienceMembershipsId = {
    experience_membership: {
        /**
         * Experience Access Level
         */
        access_level?: putV1ExperienceMembershipsId.access_level;
        /**
         * Flag to mark experience (membership) as done
         */
        done_at?: string;
        /**
         * Flag for creator to confirm experience as done
         */
        confirmed_at?: string;
    };
};

export namespace putV1ExperienceMembershipsId {

    /**
     * Experience Access Level
     */
    export enum access_level {
        COLLABORATOR = 'collaborator',
        ATTENDEE = 'attendee',
    }


}

