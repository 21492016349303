// Hotkeys to insert a new paragraph and reset formatting.

import { ExitBreakPlugin } from '@udecode/plate-break'
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from '@udecode/plate-heading'
import { ELEMENT_ATTACHMENT } from '../../plugins/attachment'
import { ELEMENT_CALLOUT } from '../../plugins/callout'
import { ELEMENT_EMBED } from '../../plugins/embed'
import { ELEMENT_FILE_FIELD } from '../../plugins/file-field/createFileFieldPlugin'
import { ELEMENT_HORIZONTAL_RULE } from '../../plugins/horizontal-rule'
import { ELEMENT_IFRAME } from '../../plugins/iframe'
import { ELEMENT_IMAGE } from '../../plugins/image'
import { ELEMENT_TEXT_AREA_FIELD } from '../../plugins/text-area-field/createTextAreaFieldPlugin'
import { ELEMENT_TEXT_FIELD } from '../../plugins/text-field/createTextFieldPlugin'

// Docs are wrong:
// https://plate.udecode.io/docs/plugins/exit-break
export const optionsExitBreakPlugin: { options: ExitBreakPlugin } = {
  options: {
    rules: [
      {
        hotkey: 'enter',
        query: {
          allow: [
            ELEMENT_CALLOUT,
            ELEMENT_TEXT_FIELD,
            ELEMENT_TEXT_AREA_FIELD,
            ELEMENT_FILE_FIELD,
            ELEMENT_HORIZONTAL_RULE,
            ELEMENT_IMAGE,
            ELEMENT_ATTACHMENT,
            ELEMENT_EMBED,
            ELEMENT_IFRAME,
          ],
        },
      },
      {
        hotkey: 'enter',
        query: {
          allow: [
            ELEMENT_H1,
            ELEMENT_H2,
            ELEMENT_H3,
            ELEMENT_H4,
            ELEMENT_H5,
            ELEMENT_H6,
          ],
          end: true,
        },
      },
    ],
  },
}
