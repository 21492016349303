import { useCallback } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { SWRResponse } from 'swr'
import {
  apiDiscussionFeedPath,
  apiDocumentPath,
  apiSectionPath,
} from '../../helpers/apiPaths'
import {
  ApiModel_Get_Experience_Section,
  ApiResponse_Get_Experience,
  useSWR_Experience,
} from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useFlashMessages } from '../../utils/useFlashMessages'

export function useDragEnd(): (result: DropResult) => void {
  const docDropHandler = useDocDropHandler()
  const sectionDropHandler = useSectionDropHandler()
  const experienceId = useExperienceId()
  const experienceResponse = useSWR_Experience(experienceId)

  return useCallback(
    function (result: DropResult) {
      if (
        result.source.droppableId === result.destination?.droppableId &&
        result.source.index === result.destination.index
      ) {
        return
      }

      switch (result.type) {
        case 'doc':
          docDropHandler(result, experienceResponse)
          break

        case 'section':
          sectionDropHandler(result, experienceResponse)
      }
    },
    [docDropHandler, sectionDropHandler, experienceResponse]
  )
}

function useDocDropHandler(): (
  result: DropResult,
  experienceResponse: SWRResponse<ApiResponse_Get_Experience, Error>
) => void {
  const flashMessages = useFlashMessages()

  return useCallback(
    async function (
      result: DropResult,
      experienceResponse: SWRResponse<ApiResponse_Get_Experience, Error>
    ) {
      if (!result.destination || !experienceResponse.data) {
        return
      }

      const experience = experienceResponse.data.data.experience
      const source = experience.experience_sections.find(
        (s: ApiModel_Get_Experience_Section) =>
          s.id === result.source.droppableId
      )
      const dest = experience.experience_sections.find(
        s => s.id === result.destination?.droppableId
      )

      const movedPage = source?.pages.find(
        page => page.id === result.draggableId
      )

      if (!movedPage || !source || !dest) {
        return
      }

      source.pages.splice(result.source.index, 1)
      dest.pages.splice(result.destination.index, 0, movedPage)

      try {
        const body =
          movedPage.type == 'doc'
            ? {
                experience_doc: {
                  sort_position: result.destination.index,
                  parent_id: result.destination.droppableId,
                },
              }
            : {
                discussion_feed: {
                  sort_position: result.destination.index,
                  parent_id: result.destination.droppableId,
                },
              }

        const response = await fetch(
          movedPage.type == 'doc'
            ? apiDocumentPath(movedPage.id)
            : apiDiscussionFeedPath(movedPage.id),
          {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          }
        )

        experienceResponse.mutate()

        if (!response.ok) {
          throw new Error(response.statusText)
        }
      } catch {
        experienceResponse.mutate(experienceResponse.data, false)

        flashMessages.show({
          type: 'error',
          text: `Could not drag & drop page`,
        })
      }
    },
    [flashMessages]
  )
}

function useSectionDropHandler(): (
  result: DropResult,
  experienceResponse: SWRResponse<ApiResponse_Get_Experience, Error>
) => void {
  const flashMessages = useFlashMessages()

  return useCallback(
    async function (
      result: DropResult,
      experienceResponse: SWRResponse<ApiResponse_Get_Experience, Error>
    ) {
      const experience = experienceResponse.data?.data.experience
      const movedSection = experience?.experience_sections.find(
        s => s.id === result.draggableId
      )

      if (!movedSection || !experience || !result.destination) {
        return
      }

      experience.experience_sections.splice(result.source.index, 1)
      experience.experience_sections.splice(
        result.destination.index,
        0,
        movedSection
      )

      try {
        const response = await fetch(apiSectionPath(result.draggableId), {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            experience_section: {
              sort_position: result.destination.index,
            },
          }),
        })

        experienceResponse.mutate()

        if (!response.ok) {
          throw new Error(response.statusText)
        }
      } catch {
        experienceResponse.mutate(experienceResponse.data, false)

        flashMessages.show({
          type: 'error',
          text: `Could not drag & drop section`,
        })
      }
    },
    [flashMessages]
  )
}
