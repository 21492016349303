import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchConversations } from '../../slices/conversations'
import {
  subscribeToConversations,
  subscribeToConversationUpdates,
  subscribeToMessages,
  subscribeToOnlinePresence,
} from '../../subscriptions/messagingSubscriptions'

export function MessengerSubscriptions(): null {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchConversations())
    const subscriptions = [
      subscribeToConversations(),
      subscribeToMessages(),
      subscribeToConversationUpdates(),
      subscribeToOnlinePresence(),
    ]

    return () => {
      subscriptions.forEach(u => u())
    }
  }, [dispatch])

  return null
}
