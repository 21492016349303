import { createSelector } from '@reduxjs/toolkit'
import { sectionsAdapter } from '../slices/sections'
import { Section } from '../types/entities'
import { RootState } from '../types/store'
import { getEntities } from './base'
import { getSelectedExperience } from './experiences'

const sectionsSelector = createSelector(
  getEntities,
  entities => entities.sections
)
const sectionsDefaultSelectors =
  sectionsAdapter.getSelectors<RootState>(sectionsSelector)

export const getSections = sectionsDefaultSelectors.selectEntities

export const getSelectedExperienceSectionIds = createSelector(
  getSelectedExperience,
  experience => experience?.experienceSections ?? []
)

export const getSelectedExperienceSections = createSelector(
  getSelectedExperienceSectionIds,
  getSections,
  (ids, experiences): Section[] =>
    ids.map(id => experiences[id]).filter(exp => exp != null) as Section[]
)
