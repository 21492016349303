import React from 'react'
import { FunctionComponent } from 'react'
import { LXFeedbackForm } from '../../features/experiences/feedback/Form'
import { ExperienceFeedbackList } from '../../features/experiences/feedback/List'
import { useAirbrake } from '../../hooks/useAirbrake'
import {
  useAPI_CanViewEvidence,
  useSWR_Document,
  useSWR_Inputs,
} from '../../hooks/useAPI'
import { useDocumentId } from '../../hooks/useDocumentId'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useMembershipId } from '../../hooks/useMembershipId'
import { ErrorDiv } from '../AirbrakeBoundary/ErrorDiv'
import { ContentCard } from '../ContentCard/ContentCard'
import { DocumentEditor } from '../DocumentEditor/DocumentEditor'
import { ExperienceContentDiv } from '../ExperienceContentDiv/ExperienceContentDiv'
import { ExperienceDocumentDueDate } from '../ExperienceDocumentPage/ExperienceDocumentDueDate'
import { ExperienceDocumentPageTitleContainer } from '../ExperienceDocumentPage/ExperienceDocumentPageTitleContainer'
import { ExperienceTopMenuEvidence } from '../ExperienceTopMenu/ExperienceTopMenuEvidence'
import { Layout } from '../Layout/Layout'
import { ExperienceEvidenceConfirmAsDone } from './ExperienceEvidenceConfirmAsDone'
import { ExperienceEvidenceDetailSideMenu } from './ExperienceEvidenceDetailSideMenu'

export const ExperienceEvidenceDocumentPage: FunctionComponent = () => {
  const documentId = useDocumentId()
  const experienceId = useExperienceId()
  const membershipId = useMembershipId()

  const document = useSWR_Document(documentId).data?.data?.experience_doc
  const inputs = useSWR_Inputs(documentId, membershipId)
  const canViewEvidence = useAPI_CanViewEvidence(experienceId)
  const airbrake = useAirbrake()

  if (!document || !inputs.data || canViewEvidence == null) return null

  if (!canViewEvidence) {
    airbrake.notify({
      error: new Error(
        'User attempted to view document evidence page without permission'
      ),
      params: {
        documentId,
        experienceId,
        membershipId,
        url: window.location.href,
      },
      context: {
        severity: 'warning',
      },
    })
    return <ErrorDiv />
  }

  return (
    <Layout
      topComponent={<ExperienceTopMenuEvidence />}
      sideComponent={<ExperienceEvidenceDetailSideMenu />}
    >
      <ExperienceContentDiv style={{ maxWidth: 808, margin: 'auto' }}>
        <div className="px-6 py-1 sm:px-0">
          <ExperienceDocumentPageTitleContainer />
        </div>
        <ExperienceEvidenceConfirmAsDone />
        <ExperienceDocumentDueDate />

        <DocumentEditor
          key={documentId}
          readOnly={true}
          document={document}
          experienceId={experienceId}
        />

        {document.is_task && (
          <ContentCard className="flex flex-col gap-8 p-6">
            <ExperienceFeedbackList
              documentId={documentId}
              membershipId={membershipId}
            />

            <LXFeedbackForm
              documentId={documentId}
              membershipId={membershipId}
            />
          </ContentCard>
        )}
      </ExperienceContentDiv>
    </Layout>
  )
}
