import React from 'react'
import { TElement } from '@udecode/plate'
import { SlateElementComponent } from '../../config/types'
import {
  CalloutChildrenDiv,
  CalloutDiv,
  CalloutEmojiDiv,
  CalloutNimbleEmoji,
} from '../callout'
import { CalloutRoleSwitch } from '../CalloutRoleSwitch'
import { textInputClassName } from '../formFieldClasses'
import { ELEMENT_TEXT_FIELD } from './createTextFieldPlugin'
import { TextFieldInput } from './TextFieldInput'

export type TextFieldElement = TElement<{
  type: ELEMENT_TEXT_FIELD
  fieldId: string
}>

export const TextFieldElement: SlateElementComponent = ({
  attributes,
  element,
  children,
}) => {
  return (
    <div {...attributes}>
      <CalloutRoleSwitch>
        <CalloutDiv>
          <CalloutEmojiDiv>
            <CalloutNimbleEmoji emoji={':writing_hand:'} />
          </CalloutEmojiDiv>
          <CalloutChildrenDiv>
            <TextFieldInput
              id={element.fieldId}
              fieldId={element.fieldId}
              type="text"
              placeholder="Add your short answer"
              className={textInputClassName}
            />
          </CalloutChildrenDiv>
        </CalloutDiv>
      </CalloutRoleSwitch>
      {children}
    </div>
  )
}
