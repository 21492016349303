import React, { forwardRef, MouseEventHandler } from 'react'
import { useCurrentCommunity, useMe } from '../../features/users/useMe'
import {
  memberProfileAboutPath,
  profilePath,
  signOutPath,
} from '../../helpers/paths'
import { useAPI_Community } from '../../hooks/useAPI'
import { UserAvatar } from '../UserAvatar/UserAvatar'

export interface UserAvatarMenuProps {
  open: boolean
  onAvatarClick?: MouseEventHandler<HTMLButtonElement>
}

export const UserAvatarMenu = forwardRef<HTMLDivElement, UserAvatarMenuProps>(
  (props, ref) => {
    const user = useMe().data
    const selectedCommunityId = useCurrentCommunity().id
    const apiCommunity = useAPI_Community

    if (!user || !selectedCommunityId) return null
    const currentUserID = apiCommunity(selectedCommunityId)?.community_user_id

    return (
      <div ref={ref}>
        <div>
          <button
            className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={props.onAvatarClick}
          >
            <span className="sr-only">Open user menu</span>
            <UserAvatar user={user} />
          </button>
        </div>
        {props.open && currentUserID && (
          <div className="absolute right-0 z-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
            <a
              href={memberProfileAboutPath(currentUserID)}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              My profile
            </a>
            <a
              href={profilePath()}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              target="_blank"
              rel="noreferrer"
            >
              My account
            </a>
            <a
              href={signOutPath()}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Sign out
            </a>
          </div>
        )}
      </div>
    )
  }
)

UserAvatarMenu.displayName = 'UserAvatarMenu'
