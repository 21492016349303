import { insertNodes, PlateEditor } from '@udecode/plate'
import { emptyChildren } from '../../config/config'
import { ELEMENT_IFRAME, IframeElement } from '.'

export const insertIframeElement = (
  editor: PlateEditor,
  code: string
): void => {
  const iframe: IframeElement = {
    type: ELEMENT_IFRAME,
    code: code,
    children: emptyChildren,
  }

  insertNodes(editor, iframe)
}
