import React, { FunctionComponent, useCallback } from 'react'
import classNames from 'classnames'
import { format, isFuture, isToday, isValid } from 'date-fns'
import { Field, Form, Formik } from 'formik'
import { mutate } from 'swr'
import { apiDocumentPath } from '../../helpers/apiPaths'
import { ApiModel_ExperienceDoc } from '../../hooks/useAPI'
import { useFlashMessages } from '../../utils/useFlashMessages'

export const ExperienceDocumentDueDateEditor: FunctionComponent<{
  document: ApiModel_ExperienceDoc
}> = ({ document }) => {
  const flashMessages = useFlashMessages()

  const validateDate = useCallback(
    (value: string) => {
      // Don't complain about previously set due dates which have already passed.
      if (value === document.due_date || value === '') return

      const candidate = new Date(value)

      if (!isValid(candidate)) {
        return 'Due date is an invalid format.'
      }

      if (!isFuture(candidate) && !isToday(candidate)) {
        return 'Due date must be in the future.'
      }
    },
    [document.due_date]
  )

  const handleSubmit = useCallback(
    async ({ due_date }) => {
      if (
        (!due_date && !document.due_date) ||
        due_date === document.due_date ||
        !!validateDate(due_date)
      ) {
        return
      }

      try {
        const response = await fetch(apiDocumentPath(document.id), {
          method: 'PUT',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            experience_doc: {
              due_date: due_date,
            },
          }),
        })

        if (response.ok) {
          mutate(apiDocumentPath(document.id))
          flashMessages.show({
            type: 'success',
            text: 'Updated due date.',
          })
        } else {
          throw new Error(response.statusText)
        }
      } catch {
        flashMessages.show({
          type: 'error',
          text: `Could not update due date.`,
        })
      }
    },
    [document.id, document.due_date, flashMessages, validateDate]
  )

  return (
    <Formik
      initialValues={{ due_date: document.due_date ? document.due_date : '' }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formik => (
        <Form className="flex flex-row flex-wrap items-baseline gap-x-5 px-4 sm:px-0">
          <label
            htmlFor="due_date"
            className="text-sm font-medium leading-5 text-gray-900"
          >
            Set Deadline
          </label>
          <div>
            <Field
              id="due_date"
              type="date"
              name="due_date"
              onBlur={formik.submitForm}
              className={classNames(
                'shadow-sm',
                'sm:text-sm',
                'rounded-md',
                'focus:ring-indigo-500',
                'placeholder-opacity-50',
                { 'border-red-500': !!formik.errors.due_date }
              )}
              min={format(new Date(), 'yyyy-MM-dd')}
              validate={validateDate}
            />
            <div className="my-1 text-center text-xs text-red-600">
              {formik.errors.due_date}
            </div>
          </div>
          <span className="w-full text-xs font-normal leading-5 text-gray-600 lg:w-auto lg:text-sm">
            {formik.values.due_date === '' ? (
              'Deadlines are set to 23:59 CET on the selected day.'
            ) : (
              <>
                <span>23:59 CET</span>
                <button
                  className="mx-5 cursor-pointer text-red-600"
                  type="button"
                  onClick={() => {
                    formik.setFieldValue('due_date', '')
                    formik.submitForm()
                  }}
                >
                  Remove Due Date
                </button>
              </>
            )}
          </span>
        </Form>
      )}
    </Formik>
  )
}
