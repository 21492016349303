import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

export const ExperienceContentDiv: FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <div className="mx-0 mt-14 sm:mx-8 sm:mt-6 sm:mb-6 2xl:mr-64">
      <div className={classNames('w-full', className)} {...props} />
    </div>
  )
}
