import React, { FunctionComponent } from 'react'
import 'tippy.js/animations/scale.css'
import 'tippy.js/dist/tippy.css'
import { Bold, Italic, Underline } from '@styled-icons/remix-editor'
import { TippyProps } from '@tippyjs/react'
import {
  BalloonToolbar,
  getPluginType,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MarkToolbarButton,
} from '@udecode/plate'
import { useStoreEditorRefInvariant } from './config/useStoreEditorRefInvariant'

export const HoverToolbar: FunctionComponent = () => {
  const editor = useStoreEditorRefInvariant()

  const arrow = false
  const theme = 'dark'

  const tooltip: TippyProps = {
    arrow: true,
    delay: 0,
    duration: [200, 0],
    hideOnClick: false,
    offset: [0, 17],
    placement: 'top',
  }

  return (
    <BalloonToolbar
      // direction={direction}
      // hiddenDelay={hiddenDelay}
      theme={theme}
      arrow={arrow}
      // If we don't position it at the top, it inserts itself as the last child
      // of the <body> and messes up our layout. That change causes it to zoom
      // in from the top of the screen, so we also have to disable up & down
      // animation. It's not very noticable; left & right is more important.
      styles={{ root: { top: 0, transition: 'left 75ms ease-out', zIndex: 0 } }}
    >
      <MarkToolbarButton
        type={getPluginType(editor, MARK_BOLD)}
        icon={<Bold />}
        tooltip={{ content: 'Bold (^B)', ...tooltip }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_ITALIC)}
        icon={<Italic />}
        tooltip={{ content: 'Italic (^I)', ...tooltip }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_UNDERLINE)}
        icon={<Underline />}
        tooltip={{ content: 'Underline (^U)', ...tooltip }}
      />
    </BalloonToolbar>
  )
}
