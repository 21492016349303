import { createContext, ReactNode } from 'react'
import { noop } from 'lodash-es'

export interface FlashMessageData {
  id: string
  text?: string
  children?: ReactNode
  type: 'success' | 'warning' | 'info' | 'error'
  duration?: number
}

export interface FlashMessageContext {
  messages: FlashMessageData[]
  show: (flashMessage: Omit<FlashMessageData, 'id'>) => void
  remove: (FlashMessageId: FlashMessageData['id']) => void
}

export const FlashMessagesContext = createContext<FlashMessageContext>({
  messages: [],
  show: noop,
  remove: noop,
})
