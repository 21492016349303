import React, { FunctionComponent } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import useSWR from 'swr'
import { apiDiscussionFeedMessagesPath } from '../../helpers/apiPaths'
import {
  ApiModel_DiscussionFeed,
  useAPI_CanEditExperience,
  useSWR_Experience,
} from '../../hooks/useAPI'
import { DiscussionFeedMessage } from '../../types/entities'
import { DiscussionFeedMessageCard } from './DiscussionFeedMessageCard'
import { DiscussionFeedMessageForm } from './DiscussionFeedMessageForm'

export const DiscussionFeedMessages: FunctionComponent<{
  discussionFeed: ApiModel_DiscussionFeed
  experienceId: EntityId
}> = ({ discussionFeed, experienceId }) => {
  const canEdit = useAPI_CanEditExperience(experienceId)
  const experienceResponse = useSWR_Experience(experienceId)
  const messagesResponse = useSWR(
    apiDiscussionFeedMessagesPath(discussionFeed.id)
  )
  if (!messagesResponse.data || !experienceResponse.data) return null

  const discussionFeedMessages =
    messagesResponse.data.data.discussion_feed_messages
  const experience = experienceResponse.data.data.experience

  return (
    <div>
      {(canEdit ||
        (!discussionFeed.is_noticeboard &&
          experience.status === 'published')) && (
        <DiscussionFeedMessageForm discussionFeedId={discussionFeed.id} />
      )}
      {discussionFeed.is_noticeboard &&
        !canEdit &&
        discussionFeedMessages.length === 0 && (
          <p className="py-4 text-gray-400">
            This notice board has no posts yet.
          </p>
        )}
      {discussionFeedMessages.map(
        (discussionFeedMessage: DiscussionFeedMessage) => {
          return (
            <DiscussionFeedMessageCard
              key={discussionFeedMessage.id}
              discussionFeedMessage={discussionFeedMessage}
              experienceId={experienceId}
            />
          )
        }
      )}
    </div>
  )
}
