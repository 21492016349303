import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { Section } from '../types/entities'

export type SectionsState = EntityState<Section>

export const sectionsAdapter = createEntityAdapter<Section>()

export const initialState = sectionsAdapter.getInitialState<SectionsState>({
  ids: [],
  entities: {},
})

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    upsertManySections: sectionsAdapter.upsertMany,
    updateOneSection: sectionsAdapter.updateOne,
    removeSectionById: sectionsAdapter.removeOne,
    addOneSection: sectionsAdapter.addOne,
  },
})

export const {
  upsertManySections,
  updateOneSection,
  removeSectionById,
  addOneSection,
} = sectionsSlice.actions

export const { reducer } = sectionsSlice
