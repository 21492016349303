import { PlateEditor } from '@udecode/plate'
import { emptyChildren } from '../../config/config'
import { insertNodeOnEmptyLine } from '../insertNodeOnEmptyLine'
import { ELEMENT_HORIZONTAL_RULE } from './createHorizontalRulePlugin'

export const insertHorizontalRule = (editor: PlateEditor): void => {
  const horizontalRule = {
    children: emptyChildren,
    type: ELEMENT_HORIZONTAL_RULE,
  }

  insertNodeOnEmptyLine(editor, horizontalRule)
}
