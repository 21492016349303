import React, { Component, ErrorInfo, ReactNode } from 'react'
import { Notifier } from '@airbrake/browser'
import { ErrorDiv } from './ErrorDiv'

export class AirbrakeBoundary extends Component<
  { airbrake: Notifier },
  { hasError: boolean; airbrake: Notifier }
> {
  constructor(props: { airbrake: Notifier }) {
    super(props)

    this.state = { airbrake: props.airbrake, hasError: false }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ hasError: true })

    this.props.airbrake.notify({
      error: error,
      params: { info: errorInfo },
      context: {
        severity: 'critical',
      },
    })

    if (process.env.NODE_ENV === 'development') console.error(error)
  }

  render(): ReactNode {
    if (this.state.hasError) return <ErrorDiv />

    return this.props.children
  }
}
