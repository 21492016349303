import { createSelector, EntityId } from '@reduxjs/toolkit'
import { experiencesAdapter } from '../slices/experiences'
import { RootState } from '../types/store'
import { getEntities } from './base'

const experiencesSelector = createSelector(
  getEntities,
  entities => entities.experiences
)
const experiencesDefaultSelectors =
  experiencesAdapter.getSelectors<RootState>(experiencesSelector)

export const getExperiences = experiencesDefaultSelectors.selectEntities

export const getSelectedExperienceId = (
  state: RootState
): EntityId | undefined => state.selectedExperienceId

export const getSelectedExperience = createSelector(
  getSelectedExperienceId,
  getExperiences,
  (id, experiences) => (typeof id === 'undefined' ? undefined : experiences[id])
)
