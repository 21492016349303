import React, { FunctionComponent, HTMLAttributes } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

export interface SideMenuLinkProps
  extends Pick<HTMLAttributes<HTMLElement>, 'className'> {
  url: string
  label: string
  icon?: any
  indicator?: boolean
}

export const NarrowSideMenuLink: FunctionComponent<
  SideMenuLinkProps
> = props => {
  return (
    <NavLink
      to={props.url}
      activeClassName="text-white bg-indigo-600 hover:bg-indigo-700"
      className={classNames(
        'group relative flex h-18 flex-col items-center justify-center rounded-md px-2 py-2 text-sm font-medium focus:underline',
        'text-gray-50 hover:bg-gray-700 hover:text-white',
        props.className
      )}
    >
      {props.indicator && (
        <div className="absolute right-2 top-2 h-2 w-2 rounded-full bg-blue-500"></div>
      )}
      {props.icon && (
        <props.icon className="h-6 w-6 text-gray-200 group-hover:text-white" />
      )}
      <span className="text-center text-xs">{props.label}</span>
    </NavLink>
  )
}
