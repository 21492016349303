export const throwIf = (...args: any | any[]): void => {
  for (const arg of args) {
    if (arg) throw new Error(arg)
  }
}

export const throwIfObjectIsEmpty = (...args: any | any[]): void => {
  for (const arg of args) {
    if (Object.keys(args).length === 0) throw new Error(arg)
  }
}
