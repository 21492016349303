import {
  ApiModel_ExperienceMembership,
  useSWR_MyExperienceMemberships,
} from '../hooks/useAPI'
import { useExperienceId } from '../hooks/useExperienceId'

export const useCurrentAttendeeMembership = ():
  | ApiModel_ExperienceMembership
  | undefined => {
  const experienceId = useExperienceId()
  const { data: mData } = useSWR_MyExperienceMemberships(experienceId)
  const attendeeMembership = mData?.data.experience_memberships.find(
    m => m.access_level === 'attendee'
  )
  return attendeeMembership
}
