import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'
import useSWR from 'swr'
import { apiExperiencePath } from '../../helpers/apiPaths'
import { experienceSchema } from '../../schemas/experience'
import { getSelectedExperience } from '../../selectors/experiences'
import { setSelectedExperienceId } from '../../slices/selectedExperienceId'
import { reduxFetch } from '../../utils/reduxFetch'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export interface ExperienceLoaderProps {
  experienceId: EntityId
}

export const ExperienceLoader: FunctionComponent<
  ExperienceLoaderProps
> = props => {
  const dispatch = useDispatch()
  useEffect(
    () => void dispatch(setSelectedExperienceId(props.experienceId)),
    [dispatch, props.experienceId]
  )
  const experienceApiPath = apiExperiencePath(props.experienceId)
  const experienceFetcher = reduxFetch({ experience: experienceSchema })
  const { data: experienceData, error: experienceError } = useSWR(
    experienceApiPath,
    experienceFetcher
  )
  const experience = useSelector(getSelectedExperience)

  if (experienceError) throw new Error(experienceError)

  if (experienceData && experience) return <>{props.children}</>
  return <LoadingPage />
}
