import { useQuery } from '@tanstack/react-query'
import { MeService } from '../../generatedFetchers'

export const queryKeyMe = ['users', 'me'] as const

export const useMe = () => {
  return useQuery({
    queryKey: queryKeyMe,
    queryFn: MeService.getV2Me,
  })
}

// Currently we don't have the UI for multiple communities.
export const useCurrentCommunity = () => {
  return useMe().data?.communities?.[0] || {}
}
