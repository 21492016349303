import { PEditor, PlateEditor, usePlateEditorRef } from '@udecode/plate'
import { HistoryEditor } from 'slate-history'
import invariant from 'tiny-invariant'
import { useDocumentId } from '../../../hooks/useDocumentId'

// Excludes undefined type from usePlateEditorRef.
// Also, prettier really sucks at formatting this. Super unreadable IMO.
export const useStoreEditorRefInvariant = (): PlateEditor &
  HistoryEditor &
  PEditor => {
  const docId = useDocumentId()
  const editor = usePlateEditorRef(docId)
  invariant(editor, 'No editor defined')
  return editor
}
