import React, { useCallback, useMemo, useState } from 'react'
import { ChangeEventHandler } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Link, Prompt } from 'react-router-dom'
import { EntityId } from '@reduxjs/toolkit'
import { ArrowLeft, Plus } from '@styled-icons/heroicons-solid'
import classNames from 'classnames'
import { differenceWith, groupBy, mapValues, uniq, without } from 'lodash-es'
import { experienceMembersPath } from '../../helpers/paths'
import { useExperienceId } from '../../hooks/useExperienceId'
import { getSelectedExperience } from '../../selectors/experiences'
import { useCommunityMembersFetch } from '../../utils/useCommunityMembersFetch'
import { useExperienceMembersFetch } from '../../utils/useExperienceMembersFetch'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { Button } from '../Button/Button'
import { Checkbox } from '../Checkbox/Checkbox'
import { ContentCard } from '../ContentCard/ContentCard'
import { ExperienceMembersPageMemberTypeName } from '../ExperienceMembersPage/ExperienceMembersPageMemberTypeName'
import { PageTitle } from '../PageTitle/PageTitle'
import { ExperienceAddMembersNoneLeft } from './ExperenceAddMembersNoneLeft'
import { ExperienceAddMembersPageGroupHeader } from './ExperienceAddmembersPageGroupHeader'
import { ExperienceAddMembersPageLoading } from './ExperienceAddMembersPageLoading'
import { ExperienceAddmembersPageTypeMembers } from './ExperienceAddMembersPageMembers'
import { useAddMembers } from './handleSubmit'

export function ExperienceAddMembersPageContent(): JSX.Element {
  const perPage = 1024
  const location = useLocation()
  const history = useHistory()
  const experienceId = useExperienceId()
  const experience = useSelector(getSelectedExperience)
  const flashMessages = useFlashMessages()
  const addMembers = useAddMembers()

  const communityMembers = useCommunityMembersFetch(location.search, {
    'pagination[per_page]': perPage,
    'filters[active_status]': 'active',
  })

  const experienceMembers = useExperienceMembersFetch({
    'pagination[per_page]': perPage,
  })

  const invitableMembers = useMemo(() => {
    if (communityMembers.data && experienceMembers.data) {
      return differenceWith<any, any>(
        communityMembers.data.data.community_users,
        experienceMembers.data.data.experience_memberships,
        (a, b) => a.id === b.community_user_id
      )
    } else {
      return []
    }
  }, [communityMembers, experienceMembers])

  const [checkedUserIds, setCheckedUserIds] = useState<EntityId[]>([])
  const [openedGroups, setOpenedGroups] = useState<string[]>([])

  const toggleGroup = useCallback(
    (groupKey: string) => {
      if (openedGroups.includes(groupKey)) {
        setOpenedGroups(without(openedGroups, groupKey))
      } else {
        setOpenedGroups([...openedGroups, groupKey])
      }
    },
    [setOpenedGroups, openedGroups]
  )

  const handleMemberCheckboxChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    event => {
      const userId = event.target.id
      const newUserIds = event.target.checked
        ? [...checkedUserIds, userId]
        : without(checkedUserIds, userId)
      setCheckedUserIds(newUserIds)
    },
    [checkedUserIds]
  )

  const successMessage = useMemo(() => {
    return {
      type: 'success' as const,
      duration: 5000,
      children: (
        <div className="text-sm">
          <div className="font-semibold text-green-800">{`${checkedUserIds.length} members have been added as attendees`}</div>
          <ul className="ml-[14px] mt-3 flex list-disc flex-col space-y-1 text-green-700">
            <li>Set co-creators in the member list as needed.</li>
            {experience?.status === 'published' ? (
              <li>Attendees will be notified immediately.</li>
            ) : (
              <li>
                Attendees will be notified when the experience is published.
              </li>
            )}
          </ul>
        </div>
      ),
    }
  }, [checkedUserIds, experience?.status])

  const handleCancel = useCallback(() => {
    setCheckedUserIds([])
    history.goBack()
  }, [history, setCheckedUserIds])

  const handleSubmit = useCallback(async () => {
    if (checkedUserIds.length === 0) return

    try {
      await addMembers(checkedUserIds, experienceId)
      setCheckedUserIds([])
      history.push(experienceMembersPath(experienceId))
      flashMessages.show(successMessage)
    } catch {
      flashMessages.show({
        text: 'Something went wrong',
        type: 'error',
      })
    }
  }, [
    addMembers,
    history,
    experienceId,
    checkedUserIds,
    flashMessages,
    successMessage,
  ])

  const getUserIds = useCallback(
    ({
      memberTypeKey,
      groupKey,
    }: {
      memberTypeKey?: string
      groupKey?: string
    }): EntityId[] =>
      invitableMembers
        .filter(
          user =>
            (groupKey && groupKey !== 'other'
              ? user.properties?.group?.includes(groupKey)
              : true) &&
            (groupKey && groupKey === 'other'
              ? user.properties?.group === undefined
              : true) &&
            (memberTypeKey
              ? user.properties?.member_type?.includes(memberTypeKey)
              : true)
        )
        .map(user => user.id),
    [invitableMembers]
  )

  const membersByGroup = useMemo(
    () =>
      invitableMembers.reduce(function groupByMemberType(
        membersByGroup,
        member
      ) {
        const groups: string[] = member?.properties?.group || ['other']
        groups.forEach(
          groupName =>
            (membersByGroup[groupName] = membersByGroup[groupName]
              ? [...membersByGroup[groupName], member]
              : [member])
        )
        return membersByGroup
      },
      {}),
    [invitableMembers]
  )

  const membersByGroupByType = useMemo(
    () =>
      mapValues(membersByGroup, groupMembers =>
        groupBy(
          groupMembers,
          groupMember => groupMember.properties.member_type || 'Others'
        )
      ),
    [membersByGroup]
  )

  const memberTypes = Object.keys(membersByGroupByType).sort((a, b) => {
    if (a === 'Others') return 1
    if (b === 'Others') return -1
    return a.localeCompare(b)
  })

  const handleMemberGroupClick = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    event => {
      const { groupKey } = event.target.dataset
      const groupMemberIds = getUserIds({ groupKey })
      event.target.checked
        ? setCheckedUserIds(uniq([...groupMemberIds, ...checkedUserIds]))
        : setCheckedUserIds(without(checkedUserIds, ...groupMemberIds))
    },
    [getUserIds, checkedUserIds]
  )

  const handleMemberTypeClick = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    event => {
      const { groupKey, memberTypeKey } = event.target.dataset
      const memberTypeIds = getUserIds({ groupKey, memberTypeKey })
      event.target.checked
        ? setCheckedUserIds(uniq([...memberTypeIds, ...checkedUserIds]))
        : setCheckedUserIds(
            checkedUserIds.filter(userId => !memberTypeIds.includes(userId))
          )
    },
    [getUserIds, checkedUserIds]
  )

  const memberTypeChecked = useCallback(
    ({
      memberTypeKey,
      groupKey,
    }: {
      memberTypeKey?: string
      groupKey?: string
    }): boolean =>
      getUserIds({
        groupKey,
        memberTypeKey,
      }).every(userId => checkedUserIds.includes(userId)),
    [checkedUserIds, getUserIds]
  )

  const groupChecked = useCallback(
    (groupKey: string): boolean =>
      getUserIds({ groupKey }).every(userId => checkedUserIds.includes(userId)),
    [checkedUserIds, getUserIds]
  )

  const loading =
    (!communityMembers?.error && !communityMembers?.data) ||
    (!experienceMembers?.error && !experienceMembers?.data)

  if (loading) return <ExperienceAddMembersPageLoading />

  if (memberTypes.length === 0) {
    return <ExperienceAddMembersNoneLeft handleCancel={handleCancel} />
  }

  const buttons = (
    <>
      <div className="flex-grow" />
      <Button theme="text" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        disabled={checkedUserIds.length === 0}
        icon={Plus}
        onClick={handleSubmit}
      >
        Add
      </Button>
    </>
  )

  return (
    <div>
      <Prompt
        when={checkedUserIds.length > 0}
        message="You have unsaved changes. Are you sure you want to leave this page?"
      />

      <header className="flex h-10 items-center px-6 sm:px-0">
        <div className="flex items-center gap-x-2 px-6 sm:px-0">
          <Link
            to={experienceMembersPath(experienceId)}
            title="Back to members"
            className="rounded-full p-1 hover:bg-gray-300"
          >
            <ArrowLeft size={24} />
          </Link>
          <PageTitle>Add members</PageTitle>
        </div>
        {buttons}
      </header>

      <ContentCard className="flex flex-col gap-6 sm:p-6">
        {memberTypes.map(groupKey => {
          const groupMembers = membersByGroupByType[groupKey]
          return (
            <section key={groupKey}>
              <ExperienceAddMembersPageGroupHeader
                groupKey={groupKey}
                handleMemberGroupClick={e => handleMemberGroupClick(e)}
                toggleClosed={() => toggleGroup(groupKey)}
                closed={!openedGroups.includes(groupKey)}
                checked={groupChecked(groupKey)}
              />
              <div
                className={classNames('flex flex-col gap-4 py-4 pl-8', {
                  hidden: !openedGroups.includes(groupKey),
                })}
              >
                {Object.entries(groupMembers)
                  .sort()
                  .map(([memberTypeKey, memberTypeMembers]) => {
                    return (
                      <div key={memberTypeKey}>
                        <div className="flex flex-col">
                          <div className="my-1 flex items-center space-x-4">
                            <Checkbox
                              id={`member_type-${groupKey}-${memberTypeKey}-checkbox`}
                              data-group-key={groupKey}
                              data-member-type-key={memberTypeKey}
                              onChange={handleMemberTypeClick}
                              checked={memberTypeChecked({
                                groupKey,
                                memberTypeKey,
                              })}
                            />
                            <label
                              htmlFor={`member_type-${groupKey}-${memberTypeKey}-checkbox`}
                              className="text-xs font-semibold uppercase text-gray-500"
                            >
                              <h3>
                                <ExperienceMembersPageMemberTypeName
                                  memberTypeKey={memberTypeKey}
                                />
                              </h3>
                            </label>
                          </div>
                          <ExperienceAddmembersPageTypeMembers
                            members={memberTypeMembers}
                            handleChange={handleMemberCheckboxChange}
                            checkedUserIds={checkedUserIds}
                          />
                        </div>
                      </div>
                    )
                  })}
              </div>
            </section>
          )
        })}
      </ContentCard>
      <div className="flex h-10 items-center px-6 sm:px-0">{buttons}</div>
    </div>
  )
}
