import React, { FunctionComponent } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import invariant from 'tiny-invariant'
import { useSWR_Experience } from '../../../../hooks/useAPI'
import { useExperienceId } from '../../../../hooks/useExperienceId'
import { useMembershipId } from '../../../../hooks/useMembershipId'
import { useCurrentAttendeeMembership } from '../../../../utils/useCurrentAttendeeMembership'
import { EvidenceInputBox } from '../EvidenceInputBox'
import { useSubmitForTextInput } from '../inputUtils'
import { RoleSwitch } from '../RoleSwitch'
import { useInput } from '../useInputs'

interface TextFieldInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  fieldId: EntityId
}

export const TextFieldInput: FunctionComponent<TextFieldInputProps> = props => {
  return (
    <RoleSwitch
      attendee={<AttendeeVersion {...props} />}
      creator={<CreatorVersion {...props} />}
      evidence={<EvidenceVersion {...props} />}
    />
  )
}

const AttendeeVersion: FunctionComponent<TextFieldInputProps> = ({
  fieldId,
  ...props
}) => {
  const experienceId = useExperienceId()
  const experienceResponse = useSWR_Experience(experienceId)
  const experience = experienceResponse.data?.data.experience
  const membership = useCurrentAttendeeMembership()
  invariant(membership)
  const input = useInput(fieldId, membership.id)
  const { handleChange } = useSubmitForTextInput(input)

  if (!experience) return null

  return (
    <input
      {...props}
      value={input.unpublished}
      onChange={handleChange}
      disabled={experience.status !== 'published'}
      readOnly={experience.status !== 'published'}
    />
  )
}

const CreatorVersion: FunctionComponent<TextFieldInputProps> = ({
  placeholder,
}) => {
  return <div className="rounded bg-white p-2 text-gray-500">{placeholder}</div>
}

const EvidenceVersion: FunctionComponent<TextFieldInputProps> = ({
  fieldId,
}) => {
  const membershipId = useMembershipId()
  const input = useInput(fieldId, membershipId)
  return <EvidenceInputBox>{input.published}</EvidenceInputBox>
}
