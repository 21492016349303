import React, { FunctionComponent } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import useSWR from 'swr'
import { useCurrentCommunity } from '../../features/users/useMe'
import {
  apiLearningOutcomesPath,
  apiRecordPropertiesPath,
} from '../../helpers/apiPaths'
import { learningOutcomesSchema } from '../../schemas/learningOutcome'
import { recordPropertySchema } from '../../schemas/recordProperty'
import { RecordProperty } from '../../types/entities'
import { reduxFetch } from '../../utils/reduxFetch'
import { LoadingPage } from '../LoadingPage/LoadingPage'

export interface ExperiencePropertyConfigLoaderProps {
  recordType: RecordProperty['recordType']
}

export const ExperiencePropertyConfigLoader: FunctionComponent<
  ExperiencePropertyConfigLoaderProps
> = props => {
  const communityId = useCurrentCommunity().id
  const learningOutcomesApiPath = apiLearningOutcomesPath(communityId || '')
  const learningOutcomeFetcher = reduxFetch({
    learningOutcomes: learningOutcomesSchema,
  })
  const { data: learningOutcomeData, error: learningOutcomeError } = useSWR(
    learningOutcomesApiPath,
    learningOutcomeFetcher
  )

  const apiPath = requestKey(communityId || '', props.recordType)
  const fetcher = reduxFetch({ recordProperties: [recordPropertySchema] })
  const { data, error } = useSWR(apiPath, fetcher)

  if (learningOutcomeError) throw new Error(learningOutcomeError)
  if (error) throw new Error(error)

  if (data && learningOutcomeData) return <>{props.children}</>
  return <LoadingPage />
}

export const requestKey = (
  communityId: EntityId,
  recordType: RecordProperty['recordType']
): string => apiRecordPropertiesPath(communityId, recordType)
