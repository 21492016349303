import {
  selectEndOfBlockAboveSelection,
  setNodes,
  TEditor,
} from '@udecode/plate'
import { Editor, Node, Transforms } from 'slate'
import invariant from 'tiny-invariant'

export const insertNodeOnEmptyLine = (editor: TEditor, node: Node): void => {
  Transforms.collapse(editor)
  selectEndOfBlockAboveSelection(editor)
  if (isCurrentElementEmpty(editor)) {
    setNodes(editor, node)
  } else {
    Editor.insertNode(editor, node)
  }
}

const isCurrentElementEmpty = (editor: TEditor): boolean => {
  invariant(editor.selection)
  const parentElement = Editor.parent(editor, editor.selection)[0]
  return Editor.isEmpty(editor, parentElement)
}
