/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//@ts-nocheck

import type { Learninx_Users_Presenters_BriefUser } from './Learninx_Users_Presenters_BriefUser';

export type Learninx_Notifications_Presenters_V2ListItem = {
    /**
     * Notification ID
     */
    id: string;
    /**
     * Notification category.
     */
    category: Learninx_Notifications_Presenters_V2ListItem.category;
    sender?: Learninx_Users_Presenters_BriefUser;
    /**
     * Notification payload.
     */
    payload?: any;
    /**
     * URL for most appropriate associated resouce. Optional.
     */
    url?: string;
    /**
     * Title for URL. Optional.
     */
    url_title?: string;
    /**
     * Date/time notification was created.
     */
    created_at: string;
    /**
     * Date/time notification was read. Used as a boolean flag.
     */
    read_at?: string;
    /**
     * Some notifications have associated due dates.
     */
    due_date?: string;
};

export namespace Learninx_Notifications_Presenters_V2ListItem {

    /**
     * Notification category.
     */
    export enum category {
        LX_INVITATION = 'lx_invitation',
        LX_UNPUBLISHED = 'lx_unpublished',
        ACCESS_LEVEL = 'access_level',
        LX_MEMBERSHIP_APPROVED = 'lx_membership_approved',
        MEMBERSHIP_REQUEST = 'membership_request',
        MEMBER_GROUP = 'member_group',
        MEMBER_TYPE = 'member_type',
        TASK_SUBMISSION = 'task_submission',
        TASK_FEEDBACK = 'task_feedback',
        TASK_FEEDBACK_REPLY = 'task_feedback_reply',
        COMMUNITY_ACCESS_LEVEL = 'community_access_level',
        TASK_DUE = 'task_due',
        LX_DONE_REQUEST = 'lx_done_request',
        LX_CONFIRMED_DONE = 'lx_confirmed_done',
    }


}

