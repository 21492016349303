import { createSelector } from '@reduxjs/toolkit'
import { recordPropertiesAdapter } from '../slices/recordProperties'
import { RecordProperty } from '../types/entities'
import { RootState } from '../types/store'
import { getEntities } from './base'

const recordPropertiesSelector = createSelector(
  getEntities,
  entities => entities.recordProperties
)
const recordPropertiesDefaultSelectors =
  recordPropertiesAdapter.getSelectors<RootState>(recordPropertiesSelector)

export const getRecordPropertiesAll = recordPropertiesDefaultSelectors.selectAll
export const getRecordPropertyById = recordPropertiesDefaultSelectors.selectById

export const recordPropertyComparer = (
  a: RecordProperty,
  b: RecordProperty
): number => a.position - b.position
export const getSortedRecordPropertiesAll = createSelector(
  getRecordPropertiesAll,
  recordProperties => recordProperties.sort(recordPropertyComparer)
)
