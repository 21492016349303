import React, { FunctionComponent, HTMLAttributes } from 'react'

export type TableFooterProps = HTMLAttributes<HTMLTableSectionElement>

export const TableFooter: FunctionComponent<TableFooterProps> = ({
  children,
  ...rest
}) => {
  return (
    <tfoot {...rest}>
      <tr>{children}</tr>
    </tfoot>
  )
}
