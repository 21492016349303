import React, { FunctionComponent, ReactElement } from 'react'
import { Airbrake } from '../AirbrakeBoundary/Airbrake'

export interface NarrowLayoutProps {
  topComponent: ReactElement
  sideComponent: ReactElement
}

export const NarrowLayout: FunctionComponent<NarrowLayoutProps> = props => {
  return (
    <div className="narrow-layout max-h-screen">
      <aside className="narrow-layout-menu vanishing-scrollbar hidden w-full overflow-y-auto bg-gray-800 sm:block">
        {props.sideComponent}
      </aside>
      <header className="narrow-layout-header">{props.topComponent}</header>
      <main className="narrow-layout-main vanishing-scrollbar overflow-y-auto">
        <Airbrake>{props.children}</Airbrake>
      </main>
    </div>
  )
}
