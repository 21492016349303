import React, { FunctionComponent } from 'react'
import { matchPath, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { SideMenuLinkProps } from '../NarrowSideMenu/NarrowSideMenuLink'

export const CommunityMobileMenuLink: FunctionComponent<
  SideMenuLinkProps
> = props => {
  const active = !!matchPath(props.url, useLocation().pathname)
  return (
    <Link
      to={props.url}
      className={classNames({
        'bg-indigo-600': active,
        'hover:bg-gray-700': !active,
        'flex items-center space-x-2 rounded py-2 pl-3 pr-4 text-sm font-medium text-white':
          true,
      })}
    >
      <props.icon className="h-6 w-6 text-white" />
      <div>{props.label}</div>
    </Link>
  )
}
