import { createPluginFactory } from '@udecode/plate'

export const ELEMENT_EMBED = 'embed'

export type ELEMENT_EMBED = typeof ELEMENT_EMBED

export const createEmbedPlugin = createPluginFactory({
  key: ELEMENT_EMBED,
  isElement: true,
  isVoid: true,
})
