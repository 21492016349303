import React, { FunctionComponent, useCallback } from 'react'
import { createPaginationItems } from './createPagination'
import { NextPage } from './NextPage'
import { PreviousPage } from './PreviousPage'

const defaults = {
  boundaryRange: 2,
  siblingRange: 1,
  ellipsis: true,
  firstAndLast: false,
  previousAndNext: true,
}

export const Pagination: FunctionComponent<{
  pagination?: {
    current_page?: number
    total_pages?: number
    per_page?: number
    total_count?: number
  }
  onPageChange: (page: number) => void
  boundaryRange?: number
  siblingRange?: number
  ellipsis?: boolean
  firstAndLast?: boolean
  previousAndNext?: boolean
}> = ({ pagination = {}, onPageChange, ...props }) => {
  const { current_page = 1, total_pages = 1 } = pagination

  const children = createPaginationItems({
    ...defaults,
    ...props,
    onPageChange,
    page: current_page,
    totalPages: total_pages,
  })

  const handlePrevious = useCallback(() => {
    const previousPage = current_page - 1
    if (previousPage) {
      onPageChange(previousPage)
    }
  }, [current_page, onPageChange])

  const handleNext = useCallback(() => {
    const nextPage = current_page + 1
    if (nextPage <= total_pages) {
      onPageChange(nextPage)
    }
  }, [current_page, onPageChange, total_pages])

  return (
    <div className="bg-white">
      <div className="flex flex-1 flex-col items-center justify-end lg:flex-row">
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <PreviousPage onClick={handlePrevious} />
            {children}
            <NextPage onClick={handleNext} />
          </nav>
        </div>
      </div>
    </div>
  )
}
