import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { DiscussionFeed } from '../types/entities'

export type DiscussionFeedsState = EntityState<DiscussionFeed>

export const discussionFeedsAdapter = createEntityAdapter<DiscussionFeed>()

export const initialState =
  discussionFeedsAdapter.getInitialState<DiscussionFeedsState>({
    ids: [],
    entities: {},
  })

export const discussionFeedsSlice = createSlice({
  name: 'discussionFeeds',
  initialState,
  reducers: {
    upsertManyDiscussionFeeds: discussionFeedsAdapter.upsertMany,
    removeDiscussionFeedById: discussionFeedsAdapter.removeOne,
    updateOneDiscussionFeed: discussionFeedsAdapter.updateOne,
    addOneDiscussionFeed: discussionFeedsAdapter.addOne,
  },
})

export const {
  upsertManyDiscussionFeeds,
  removeDiscussionFeedById,
  updateOneDiscussionFeed,
  addOneDiscussionFeed,
} = discussionFeedsSlice.actions

export const { reducer } = discussionFeedsSlice
