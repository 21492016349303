import React, { FunctionComponent, useCallback, useState } from 'react'
import Popup from 'reactjs-popup'
import 'emoji-mart/css/emoji-mart.css'
import { TElement, TRenderElementProps } from '@udecode/plate'
import classNames from 'classnames'
import data from 'emoji-mart/data/apple.json'
import { NimblePicker } from 'emoji-mart/dist-es'
import NimbleEmoji from 'emoji-mart/dist-es/components/emoji/nimble-emoji.js'
import { useReadOnly } from 'slate-react'
import { useStoreEditorRefInvariant } from '../../config/useStoreEditorRefInvariant'
import { setEmoji } from './eventHandlers'

export type CalloutElement = TElement<{
  emoji: string
  type: 'callout'
}>

export const CalloutElement = ({
  attributes,
  children,
  element,
}: TRenderElementProps<CalloutElement>): JSX.Element => {
  const isReadOnly = useReadOnly()
  const emojiName = element.emoji

  // Prevents a console warning; d&d plugin passes a ref, which function
  // components can't handle. This is probably the wrong way to do this, but it
  // doesn't affect current functionality.
  delete attributes.ref

  return (
    <CalloutDiv {...attributes}>
      {isReadOnly ? (
        <CalloutEmojiDiv>
          <CalloutNimbleEmoji emoji={emojiName} />
        </CalloutEmojiDiv>
      ) : (
        <CalloutEmojiButton element={element}>
          <CalloutNimbleEmoji emoji={emojiName} />
        </CalloutEmojiButton>
      )}
      <CalloutChildrenDiv>{children}</CalloutChildrenDiv>
    </CalloutDiv>
  )
}

export const CalloutDiv: FunctionComponent = props => {
  return (
    <div
      className="relative my-2 flex items-center space-x-4 rounded bg-gray-100 p-4"
      {...props}
    />
  )
}

const emojiClass =
  'shrink-0 w-8 h-8 flex items-center justify-center p-0.5 rounded-full'

export const CalloutEmojiDiv: FunctionComponent = props => {
  return <div className={emojiClass} contentEditable={false} {...props} />
}

export const CalloutEmojiButton: FunctionComponent<{
  element: TElement<CalloutElement>
}> = ({ element, children }) => {
  const editor = useStoreEditorRefInvariant()
  const [menuVisible, setMenuVisible] = useState<boolean>(false)
  const open = useCallback(() => setMenuVisible(true), [])
  const close = useCallback(() => setMenuVisible(false), [])
  const handleSelectEmoji = useCallback(
    emoji => {
      if (!editor) return
      setMenuVisible(false)
      setEmoji(editor, element, emoji.colons)
    },
    [editor, element]
  )

  return (
    <div contentEditable={false} className="relative">
      <button
        onClick={open}
        className={classNames(
          emojiClass,
          'hover:bg-gray-200 active:bg-gray-300'
        )}
      >
        {children}
      </button>

      <Popup
        open={menuVisible}
        onClose={close}
        closeOnDocumentClick
        contentStyle={{ width: 'auto' }}
      >
        <NimblePicker
          data={data as any}
          set="apple"
          sheetSize={32}
          emoji=""
          title="Select an emoji"
          onSelect={handleSelectEmoji}
        />
      </Popup>
    </div>
  )
}

export const CalloutNimbleEmoji: FunctionComponent<{ emoji: string }> = ({
  emoji,
}) => {
  return <NimbleEmoji data={data as any} emoji={emoji} size={24} native />
}

export const CalloutChildrenDiv: FunctionComponent = props => {
  return <div className="w-full" {...props} />
}
