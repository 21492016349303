import { EntityId } from '@reduxjs/toolkit'
import { sectionSchema } from '../../schemas/sections'
import { Experience } from '../../types/entities'
import { reduxFetch } from '../../utils/reduxFetch'

export const renameExperienceSectionFetch = (
  sectionId: EntityId,
  name: string
): Promise<{
  experience_section: Partial<Experience>
}> => {
  const fetch = reduxFetch<{ experience_section: Partial<Experience> }>({
    experienceSection: sectionSchema,
  })
  return fetch(`/api/v1/experience_sections/${sectionId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      experience_section: {
        title: name,
      },
    }),
  })
}
