import React, { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import { InitialState } from './InitialState'
import { PromptState } from './PromptState'
import { SavingState } from './SavingState'

interface AddSectionState {
  context: 'initial' | 'prompt' | 'saving'
  title: string
}

export const AddSection: FunctionComponent = () => {
  const [state, setState] = useState<AddSectionState>({
    context: 'initial',
    title: '',
  })
  const setInitial = useCallback(
    () => setState({ context: 'initial', title: '' }),
    []
  )
  const setPrompt = useCallback(
    (title: string) => setState({ context: 'prompt', title: title }),
    []
  )
  const setSaving = useCallback(
    (title: string) => setState({ context: 'saving', title: title }),
    []
  )
  const handleAddSection = useCallback(() => setPrompt(''), [setPrompt])

  switch (state.context) {
    case 'initial':
      return <InitialState onClick={handleAddSection} />
    case 'prompt':
      return (
        <PromptState
          initialValue={state.title}
          onConfirm={setSaving}
          onCancel={setInitial}
        />
      )
    case 'saving':
      return (
        <SavingState
          title={state.title}
          onSuccess={setInitial}
          onFail={setPrompt}
        />
      )
    default:
      return null
  }
}
