import React, {
  ChangeEventHandler,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useUnmount } from 'react-use'
import { EntityId } from '@reduxjs/toolkit'
import classNames from 'classnames'
import { useFormik } from 'formik'
import { mutate } from 'swr'
import { apiDocumentPath, apiExperiencePath } from '../../helpers/apiPaths'
import {
  ApiModel_ExperienceDoc,
  useAPI_CanEditExperience,
} from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { updateOneDocument } from '../../slices/documents'
import { renameDocumentFetch } from './renameDocumentFetch'

export interface ExperienceDocumentPageTitleFormValues {
  name: string
}

export type ExperienceDocumentPageTitleStates =
  | 'initial'
  | 'submitting'
  | 'success'
  | 'error'

interface ExperienceDocumentPageTitleProps {
  document: ApiModel_ExperienceDoc
}

export const ExperienceDocumentPageTitle: FunctionComponent<
  ExperienceDocumentPageTitleProps
> = ({ document }) => {
  const dispatch = useDispatch()

  const [state, setState] =
    useState<ExperienceDocumentPageTitleStates>('initial')
  useEffect(
    function temporaryStateReset() {
      if (state === 'success' || state === 'error') {
        const resetState = () => setState('initial')
        const timeoutId = setTimeout(resetState, 1000)
        return () => clearTimeout(timeoutId)
      }
    },
    [state]
  )

  const experienceId = useExperienceId()
  const canEdit = useAPI_CanEditExperience(experienceId)
  const isNew = document.title === 'Untitled'

  const saveName = useCallback(
    async (document_id: EntityId, name: string) => {
      await renameDocumentFetch(document_id, name)
      await mutate(apiExperiencePath(experienceId))
      await mutate(apiDocumentPath(document_id))
    },
    [experienceId]
  )

  const formik = useFormik<ExperienceDocumentPageTitleFormValues>({
    initialValues: {
      name: isNew ? '' : document.title,
    },
    enableReinitialize: true,
    onSubmit: async values => {
      setState('submitting')
      try {
        await saveName(document.id, values.name)
        setState('success')
      } catch (error) {
        setState('error')
      }
    },
  })

  const handleNameOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      dispatch(
        updateOneDocument({
          id: document.id,
          changes: { title: event.target.value },
        })
      )
      formik.handleChange(event)
    },
    [dispatch, document.id, formik]
  )
  const handleNameOnBlur = useCallback(
    function fillAndSubmitTitle() {
      if (formik.values.name.length === 0) {
        formik.setValues({ name: 'Untitled' })
      }
      formik.submitForm()
    },
    [formik]
  )
  const titleClassName =
    'text-2xl text-gray-900 font-semibold w-full outline-none placeholder-gray-300'
  const inputClassName = classNames(
    titleClassName,
    'bg-transparent hover:bg-gray-200 focus:bg-gray-200'
  )

  const ref: any = useRef()
  useEffect(() => {
    if (
      ref.current?.document_id !== document.id && // Document changed
      ref.current?.formik?.dirty // It's dirty and needs save
    ) {
      saveName(ref.current.document_id, ref.current.formik.values.name)
    }
    ref.current = { document_id: document.id, formik: formik }
  }, [document.id, formik, saveName])

  useUnmount(() => handleNameOnBlur())

  return canEdit ? (
    <h1>
      <form onSubmit={formik.handleSubmit}>
        <input
          autoFocus={isNew}
          name="name"
          onChange={handleNameOnChange}
          onBlur={handleNameOnBlur}
          value={formik.values.name}
          className={inputClassName}
          disabled={state === 'submitting'}
          placeholder={'Untitled'}
        />
        <button type="submit" className="hidden">
          Submit
        </button>
      </form>
    </h1>
  ) : (
    <h1 className={titleClassName}>{formik.values.name}</h1>
  )
}
