import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

export const ContentCard: FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <div
      className={classNames(
        'mt-4 max-w-full bg-white shadow sm:mb-8 sm:rounded-md sm:pb-6',
        className
      )}
      {...props}
    />
  )
}
