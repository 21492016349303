import React, { FunctionComponent, TdHTMLAttributes } from 'react'
import classNames from 'classnames'

export type TableFooterCellProps = TdHTMLAttributes<HTMLTableDataCellElement>

export const TableFooterCell: FunctionComponent<TableFooterCellProps> = ({
  className,
  ...rest
}) => {
  return (
    <td
      {...rest}
      className={classNames(
        'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500',
        className
      )}
    />
  )
}
