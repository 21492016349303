import { setNodes } from '@udecode/plate'
import { Editor, Node, Path, Range } from 'slate'
import { createEmptyParagraph } from '../../config/config'

// if the previous node is a void node, remove the current node and select the void node
export const withCorrectTableCellDeleteBehavior = <T extends Editor>(
  editor: T
): T => {
  const { deleteBackward, deleteForward } = editor

  editor.deleteBackward = unit => {
    return correctTableCellDeleteBehavior(unit, deleteBackward)
  }

  editor.deleteForward = unit => {
    return correctTableCellDeleteBehavior(unit, deleteForward)
  }

  type Unit = 'character' | 'word' | 'line' | 'block'

  const correctTableCellDeleteBehavior = (
    unit: Unit,
    deleteFn: (unit: Unit) => void
  ) => {
    if (
      !editor.selection ||
      !Range.isCollapsed(editor.selection) ||
      editor.selection.anchor.offset !== 0
    ) {
      return deleteFn(unit)
    }

    const parentPath = Path.parent(editor.selection.anchor.path)
    const parentNode = Node.get(editor, parentPath)
    const parentIsEmpty = Node.string(parentNode).length === 0

    const grandparentPath = Path.parent(parentPath)
    const grandparentNode = Node.get(editor, grandparentPath)

    if (
      parentIsEmpty &&
      !Path.hasPrevious(parentPath) &&
      (grandparentNode as any).type === 'td'
    ) {
      return setNodes(editor, createEmptyParagraph())
    }

    deleteFn(unit)
  }

  return editor
}
