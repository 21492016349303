import React, { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { ExperienceLoader } from '../ReduxFetch/ExperienceLoader'
import { ExperiencePropertyConfigLoader } from '../ReduxFetch/ExperiencePropertyConfigLoader'
import { ExperienceTrashPageContent } from './ExperienceTrashPageContent'

export const ExperienceTrashPage: FunctionComponent = () => {
  const { experienceId } = useParams<{ experienceId?: string }>()
  if (!experienceId) throw new Error('experienceId is undefined')

  return (
    <ExperienceLoader experienceId={experienceId}>
      <ExperiencePropertyConfigLoader recordType="experience">
        <ExperienceTrashPageContent />
      </ExperiencePropertyConfigLoader>
    </ExperienceLoader>
  )
}
