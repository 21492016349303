import React from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { TElement, TRenderElementProps } from '@udecode/plate'
import classNames from 'classnames'
import { Resizable, ResizeCallback } from 're-resizable'
import { Transforms } from 'slate'
import { ReactEditor, useFocused, useSelected } from 'slate-react'
import useSWR from 'swr'
import invariant from 'tiny-invariant'
import { apiAttachmentPath } from '../../../../helpers/apiPaths'
import { ApiResponse_Get_Attachment } from '../../../../hooks/useAPI'
import { useStoreEditorRefInvariant } from '../../config/useStoreEditorRefInvariant'

export type AttachmentElement = TElement<{
  type: 'attachment'
  attachmentId: EntityId
  widthPercent: string
}>

export const AttachmentElement = ({
  attributes,
  children,
  element,
}: TRenderElementProps<AttachmentElement>): JSX.Element => {
  const editor = useStoreEditorRefInvariant()
  const selected = useSelected()
  const focused = useFocused()
  const { widthPercent } = element
  const { data } = useSWR<ApiResponse_Get_Attachment>(
    apiAttachmentPath(element.attachmentId)
  )

  const saveWidth: ResizeCallback = (event, direction, ref) => {
    invariant(ref.parentElement)
    const widthPercent =
      (100 * ref.clientWidth) / ref.parentElement.clientWidth + '%'
    const path = ReactEditor.findPath(editor, element)
    Transforms.setNodes(editor, { widthPercent } as Partial<any>, { at: path })
  }

  return (
    <div {...attributes}>
      <div contentEditable={false} className="my-2 flex justify-center">
        {/* Resizable overrides the height, just set height to any percentage */}
        <Resizable
          lockAspectRatio
          defaultSize={{ width: widthPercent, height: '0%' }}
          onResizeStop={saveWidth}
          enable={{ right: true, left: true }}
          maxWidth="100%"
          minWidth="10%"
        >
          <img
            src={data?.data?.attachment?.medium}
            className={classNames({
              'block w-full': true,
              'ring-2': selected && focused,
            })}
          />
        </Resizable>
      </div>
      {children}
    </div>
  )
}
