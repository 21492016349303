import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { getPlateActions, Plate } from '@udecode/plate-core'
import { mutate } from 'swr'
import { useMe } from '../../features/users/useMe'
import { apiDiscussionFeedMessagesPath } from '../../helpers/apiPaths'
import { UserAvatar } from '../UserAvatar/UserAvatar'

export const DiscussionFeedMessageForm: FunctionComponent<{
  discussionFeedId: EntityId
}> = ({ discussionFeedId }) => {
  const initialValue = useMemo(
    () => [{ type: 'p', children: [{ text: '' }] }],
    []
  )
  const [messageValue, setMessageValue] = useState(initialValue)

  const user = useMe().data || {}

  const plateActions = getPlateActions(discussionFeedId as string)

  const handleSubmit = useCallback(() => {
    if (JSON.stringify(messageValue) === JSON.stringify(initialValue)) return
    fetch(apiDiscussionFeedMessagesPath(), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        discussion_feed_message: {
          content: messageValue,
          discussion_feed_id: discussionFeedId,
        },
      }),
    }).then(() => {
      plateActions.value(initialValue)
      plateActions.resetEditor()
      setMessageValue(initialValue)
      mutate(apiDiscussionFeedMessagesPath(discussionFeedId))
    })
  }, [discussionFeedId, initialValue, messageValue, plateActions])

  return (
    <div className="rounded bg-white p-8 shadow-sm">
      <div className="flex flex-row space-x-4">
        <div>
          <UserAvatar size="large" user={user} />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="w-full" style={{ minHeight: '8em' }}>
            <Plate
              id={discussionFeedId as string}
              value={messageValue}
              onChange={(e: any[]) => setMessageValue(e)}
              editableProps={{
                className: 'flex-1 h-full p-4 border rounded focus:shadow',
                placeholder: 'Your Message',
              }}
            />
          </div>
          <div className="flex justify-end py-4">
            <button
              onClick={() => handleSubmit()}
              className="rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
            >
              Post Message
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
