import React, { FunctionComponent } from 'react'
import { Plus } from '@styled-icons/heroicons-outline'
import { Button } from '../Button/Button'

interface InitialStateProps {
  onClick: () => void
}

export const InitialState: FunctionComponent<InitialStateProps> = ({
  onClick,
}) => {
  return (
    <Button icon={Plus} theme="dashed" fluid onClick={onClick}>
      Add section
    </Button>
  )
}
