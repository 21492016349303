import React, { FunctionComponent, useCallback, useRef } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { FolderUpload } from '@styled-icons/remix-line'
import { BlockToolbarButton } from '@udecode/plate'
import { useUpload } from '../../../ImagePicker/useUpload'
import { SpinnerIcon } from '../../../SpinnerIcon/SpinnerIcon'
import { useStoreEditorRefInvariant } from '../../config/useStoreEditorRefInvariant'
import { insertAttachment } from './eventHandlers'

export const AttachmentButton: FunctionComponent<{
  type: string
}> = ({ type }) => {
  const editor = useStoreEditorRefInvariant()
  const inputRef = useRef<HTMLInputElement>(null)
  const handleUploadSuccess = useCallback(
    (attachmentId: EntityId) => {
      if (!editor) return
      insertAttachment(editor, attachmentId)
    },
    [editor]
  )
  const { upload, isUploading } = useUpload({
    onUploadSuccess: handleUploadSuccess,
  })
  const handleMouseDown = useCallback(
    (event: any) => {
      event.preventDefault()
      if (isUploading) return
      inputRef.current?.click()
    },
    [isUploading]
  )
  const handleChangeFiles = useCallback(
    (event: any) => {
      event.preventDefault()
      if (isUploading) return
      event.target.files && upload(event.target.files)
    },
    [isUploading, upload]
  )
  return (
    <>
      <BlockToolbarButton
        type={type}
        onMouseDown={handleMouseDown}
        disabled={isUploading}
        icon={
          isUploading ? (
            <SpinnerIcon className="animate-spin" />
          ) : (
            <FolderUpload />
          )
        }
      ></BlockToolbarButton>
      <input
        type="file"
        className="hidden"
        accept="image/*"
        ref={inputRef}
        onChange={handleChangeFiles}
      />
    </>
  )
}
