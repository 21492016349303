import React, { FunctionComponent, HTMLAttributes } from 'react'
import classNames from 'classnames'

export type TableBodyProps = HTMLAttributes<HTMLTableSectionElement>

export const TableBody: FunctionComponent<TableBodyProps> = ({
  className,
  ...rest
}) => {
  return (
    <tbody
      {...rest}
      className={classNames('divide-y divide-gray-200 bg-white', className)}
    />
  )
}
