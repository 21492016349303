import React, { FunctionComponent, useCallback } from 'react'
import { useHistory } from 'react-router'
import Popup from 'reactjs-popup'
import { DocumentDuplicate } from '@styled-icons/heroicons-outline'
import { apiExperienceClonePath } from '../../helpers/apiPaths'
import { experienceEditPath } from '../../helpers/paths'
import {
  ApiResponse_Get_Experience,
  useSWR_Experience,
} from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { Button } from '../Button/Button'
import { Modal } from '../Modal/Modal'
import { ToolTip } from '../ToolTip/ToolTip'
import { SetSavingProps } from './ExperienceStatus'

export const ExperienceStatus_CopyButton: FunctionComponent<SetSavingProps> = ({
  setSaving,
}) => {
  const experienceId = useExperienceId()
  const experience = useSWR_Experience(experienceId)
  const history = useHistory()
  const flashMessages = useFlashMessages()

  const handleCopy = useCallback(async () => {
    setSaving(true)
    try {
      const response = await fetch(apiExperienceClonePath(), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: experienceId }),
      })

      if (!response.ok) throw response.status
      const json = (await response.json()) as ApiResponse_Get_Experience
      setSaving(false)
      history.push(`${experienceEditPath(json.data.experience.id)}?focusTitle`)
      flashMessages.show({
        type: 'success',
        text: `Successfully copied ${experience.data?.data?.experience?.title}`,
        duration: 5000,
      })
    } catch {
      setSaving(false)
      flashMessages.show({
        type: 'error',
        text: 'Could not copy Learning Experience',
      })
    }
  }, [experience, setSaving, experienceId, history, flashMessages])

  return (
    <ToolTip content="Make a copy">
      <Popup
        modal
        lockScroll
        closeOnEscape
        trigger={
          <Button
            className="copy-button"
            theme="dark"
            title="Make a copy"
            icon={DocumentDuplicate}
          />
        }
      >
        {(close: () => void) => {
          const handleConfirm = () => {
            close()
            handleCopy()
          }
          return (
            <Modal
              icon={<DocumentDuplicate size={24} />}
              title="Make a copy"
              description="Are you sure you want to create a copy of this Learning Experience?"
              buttons={
                <>
                  <Button theme="secondary" onClick={close}>
                    Cancel
                  </Button>
                  <Button onClick={handleConfirm}>Make a Copy</Button>
                </>
              }
            />
          )
        }}
      </Popup>
    </ToolTip>
  )
}
