import { useCallback } from 'react'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { useAirbrake } from '../hooks/useAirbrake'
import { useFlashMessages } from './useFlashMessages'

/**
 * Error handler for useMutation
 */
export function usePostError<I, P>(message?: string) {
  const airbrake = useAirbrake()
  const flash = useFlashMessages()
  const client = useQueryClient()

  return useCallback(
    ({
      data,
      params,
      context,
    }: {
      data?: unknown
      params?: P
      context?: {
        queryKey?: QueryKey
        preCallData?: I
      }
    }) => {
      console.error(data, params, context)
      airbrake.notify({ data, params, context })

      flash.show({
        type: 'error',
        text:
          message ||
          "That didn't work. Apologies for the inconvenience; the tech team has been notified.",
      })

      if (!context?.queryKey || !context?.preCallData) return

      client.setQueryData(context.queryKey, context.preCallData)
    },
    [airbrake, client, flash, message]
  )
}

// Drop-in for useInfiniteQuery
export const getNextPageParam = ({
  pagination,
}: {
  pagination?: {
    current_page?: number
    total_pages?: number
  }
}) => {
  const { current_page, total_pages } = pagination || {}

  if (!current_page || !total_pages || current_page >= total_pages) return

  return current_page + 1
}

export const getPreviousPageParam = ({
  pagination,
}: {
  pagination?: { current_page?: number }
}) => {
  const { current_page } = pagination || {}

  if (!current_page || current_page <= 1) return

  return current_page - 1
}
