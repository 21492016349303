import React, { FunctionComponent } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import classNames from 'classnames'
import { DiscernibleText } from '../Button/types'

/**
 * L(earninx)Link - Link with simple styling & behavior (blue text).
 * Use for in-app navigation.
 *
 * Ensure children contain a meaningful description. Otherwise, pass it using
 * one of the other accessible attributes (listed in src/Button/types.ts -
 * DiscernibleText).
 */
export const StyledLink: FunctionComponent<
  LinkProps<HTMLAnchorElement> & DiscernibleText
> = props => {
  return (
    <Link {...props} className={classNames('btn btn--link', props.className)} />
  )
}
