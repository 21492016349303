import { createSelector } from '@reduxjs/toolkit'
import { conversationsAdapter } from '../slices/conversations'
import { RootState } from '../types/store'
import { getEntities } from './base'

const conversationsSelector = createSelector(
  getEntities,
  entities => entities.conversations
)
const conversationsSelectors = conversationsAdapter.getSelectors<RootState>(
  conversationsSelector
)

export const {
  selectAll: selectAllConversations,
  selectById: selectConversationById,
} = conversationsSelectors
