import { EntityId } from '@reduxjs/toolkit'
import { apiDiscussionFeedPath } from '../../helpers/apiPaths'

export const renameDiscussionFeedFetch = (
  discussionFeedId: EntityId,
  title: string
): Promise<Response> => {
  if (title.length === 0) title = 'Untitled'
  return fetch(apiDiscussionFeedPath(discussionFeedId), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      discussion_feed: {
        title: title,
      },
    }),
  })
}
