import React, { FunctionComponent, useCallback } from 'react'
import Popup from 'reactjs-popup'
import { CheckCircle } from '@styled-icons/heroicons-outline'
import { mutate } from 'swr'
import { useMe } from '../../features/users/useMe'
import { apiExperienceMembershipsPath } from '../../helpers/apiPaths'
import { useSWR_MyExperienceMemberships } from '../../hooks/useAPI'
import { useExperienceId } from '../../hooks/useExperienceId'
import { useUpdateMembership } from '../../hooks/useUpdateMembership'
import { Button } from '../Button/Button'
import { DropdownItem } from '../Dropdown/DropdownItem'
import { Modal } from '../Modal/Modal'

export const ExperienceAttendeeDropdown_DropdownMenu_DoneButton: FunctionComponent =
  () => {
    const experienceId = useExperienceId()
    const { data: membershipData } =
      useSWR_MyExperienceMemberships(experienceId)
    const { data: profile } = useMe()

    const updateMembership = useUpdateMembership()
    const handleConfirmDone = useCallback(async () => {
      if (!membershipData || !profile) return
      await updateMembership(
        membershipData.data.experience_memberships[0].id,
        { done_at: new Date() },
        `Successfully marked this LX as done. Please wait for confirmation from one the of LX Creators.`
      )
      await mutate(
        apiExperienceMembershipsPath({
          experienceId,
          userId: profile.id,
        })
      )
    }, [updateMembership, membershipData, profile, experienceId])

    if (!membershipData || !profile) return null

    const membership = membershipData.data.experience_memberships[0]

    return membership.done_at || membership.confirmed_at ? (
      <Button
        disabled
        theme="none"
        className="flex w-full cursor-not-allowed whitespace-nowrap px-4 py-2 text-sm text-gray-700"
      >
        Mark LX as done
      </Button>
    ) : (
      <Popup
        modal
        lockScroll
        closeOnEscape
        trigger={
          <DropdownItem>
            <div className="whitespace-nowrap">Mark LX as done</div>
          </DropdownItem>
        }
      >
        {(close: () => void) => {
          const handleConfirm = () => {
            close()
            handleConfirmDone()
          }
          return (
            <Modal
              icon={<CheckCircle size={24} />}
              title="Are you sure you want to mark this LX as Done?"
              description="The LX creators will be notified and will need to confirm."
              buttons={
                <>
                  <Button theme="secondary" onClick={close}>
                    Cancel
                  </Button>
                  <Button onClick={handleConfirm}>Yes, mark as done</Button>
                </>
              }
            />
          )
        }}
      </Popup>
    )
  }
