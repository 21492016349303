import React, { FunctionComponent, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export interface SideMenuLogoProps
  extends Pick<HTMLAttributes<HTMLElement>, 'className'> {
  url: string
  src: string
  title: string
  alt: string
}

export const NarrowSideMenuLogo: FunctionComponent<
  SideMenuLogoProps
> = props => {
  return (
    <Link to={props.url} title={props.title} tabIndex={-1}>
      <img
        src={props.src}
        width={72}
        height={72}
        alt={props.alt}
        className={classNames(
          'overflow-hidden rounded-md bg-white p-4',
          props.className
        )}
      />
    </Link>
  )
}
