import {
  createBoldPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createPlateUI,
  createPlugins,
  createTablePlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_IMAGE,
  ELEMENT_LINK,
  ELEMENT_PARAGRAPH,
  ImageElement,
} from '@udecode/plate'
import { EditableProps } from 'slate-react/dist/components/editable'
import {
  AttachmentElement,
  createAttachmentPlugin,
  ELEMENT_ATTACHMENT,
} from '../plugins/attachment'
import {
  CalloutElement,
  createCalloutPlugin,
  ELEMENT_CALLOUT,
} from '../plugins/callout'
import { createCorrectDeleteBackwardBehaviorPlugin } from '../plugins/correct-delete-backward-behavior/createCorrectDeleteBackwardBehaviorPlugin'
import { createCorrectDeleteForwardBehaviorPlugin } from '../plugins/correct-delete-forward-behavior/createCorrectDeleteForwardBehaviorPlugin'
import {
  createEmbedPlugin,
  ELEMENT_EMBED,
  EmbedElement,
} from '../plugins/embed'
import {
  createFileFieldlugin,
  ELEMENT_FILE_FIELD,
} from '../plugins/file-field/createFileFieldPlugin'
import { FileFieldElement } from '../plugins/file-field/FileFieldElement'
import {
  H1Element,
  H2Element,
  H3Element,
} from '../plugins/heading/HeadingElements'
import {
  createHorizontalRulePlugin,
  ELEMENT_HORIZONTAL_RULE,
  HorizontalRuleElement,
} from '../plugins/horizontal-rule'
import {
  createIframePlugin,
  ELEMENT_IFRAME,
  IframeElement,
} from '../plugins/iframe'
import { createImagePlugin } from '../plugins/image'
import { LinkElement, withLinks } from '../plugins/link'
import { createCorrectTableCellDeleteBehaviorPlugin } from '../plugins/table-delete-backward-behavior/createTableCellDeleteBehaviorPlugin'
import {
  createTextAreaFieldlugin,
  ELEMENT_TEXT_AREA_FIELD,
} from '../plugins/text-area-field/createTextAreaFieldPlugin'
import { TextAreaFieldElement } from '../plugins/text-area-field/TextAreaFieldElement'
import {
  createTextFieldlugin,
  ELEMENT_TEXT_FIELD,
} from '../plugins/text-field/createTextFieldPlugin'
import { TextFieldElement } from '../plugins/text-field/TextFieldElement'
import { withStyledDraggables } from '../plugins/withStyledDraggables/withStyledDraggables'
import { optionsExitBreakPlugin } from './options/optionsExitBreakPlugin'

export const emptyChildren = [{ text: '' }]

export const createEmptyParagraph = (): any => ({
  id: Date.now(),
  type: ELEMENT_PARAGRAPH,
  children: emptyChildren,
})

export const createDefaultValue = (): any[] => [createEmptyParagraph()]

export const plugins = createPlugins(
  [
    // editor
    createNodeIdPlugin(),

    // Elements from slate-plugins
    createParagraphPlugin(),
    createHeadingPlugin(),
    createListPlugin(),
    createTablePlugin(),
    createLinkPlugin({
      withOverrides: withLinks,
    }),

    // Custom elements
    createEmbedPlugin(),
    createIframePlugin(),
    createHorizontalRulePlugin(),
    createImagePlugin(),
    createCalloutPlugin(),
    createAttachmentPlugin(),
    createTextFieldlugin(),
    createTextAreaFieldlugin(),
    createFileFieldlugin(),

    // Marks
    createBoldPlugin(),
    createItalicPlugin(),
    createUnderlinePlugin(),

    // Stock Utils
    createExitBreakPlugin(optionsExitBreakPlugin),
    // Keep a paragraph at the bottom so the cursor can move after a void element.
    createTrailingBlockPlugin(createEmptyParagraph()),
    createDndPlugin(),

    // Custom utils
    createCorrectTableCellDeleteBehaviorPlugin(),
    createCorrectDeleteBackwardBehaviorPlugin(),
    createCorrectDeleteForwardBehaviorPlugin(),
  ],

  {
    components: withStyledDraggables(
      createPlateUI({
        [ELEMENT_H1]: H1Element,
        [ELEMENT_H2]: H2Element,
        [ELEMENT_H3]: H3Element,
        [ELEMENT_EMBED]: EmbedElement,
        [ELEMENT_IFRAME]: IframeElement,
        [ELEMENT_HORIZONTAL_RULE]: HorizontalRuleElement,
        [ELEMENT_IMAGE]: ImageElement,
        [ELEMENT_ATTACHMENT]: AttachmentElement,
        [ELEMENT_LINK]: LinkElement,
        [ELEMENT_CALLOUT]: CalloutElement,
        [ELEMENT_TEXT_FIELD]: TextFieldElement,
        [ELEMENT_TEXT_AREA_FIELD]: TextAreaFieldElement,
        [ELEMENT_FILE_FIELD]: FileFieldElement,
      })
    ),
  }
)

export const editableProps: EditableProps = {
  placeholder: 'This page has no content yet.',
  'aria-label': 'Edit page content',
  style: { zIndex: 0, opacity: 1 },
}
