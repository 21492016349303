import React from 'react'
import { TElement } from '@udecode/plate'
import { SlateElementComponent } from '../../config/types'
import {
  CalloutChildrenDiv,
  CalloutDiv,
  CalloutEmojiDiv,
  CalloutNimbleEmoji,
} from '../callout'
import { CalloutRoleSwitch } from '../CalloutRoleSwitch'
import { textInputClassName } from '../formFieldClasses'
import { ELEMENT_TEXT_AREA_FIELD } from './createTextAreaFieldPlugin'
import { TextAreaFieldInput } from './TextAreaFieldInput'

export type TextAreaFieldElement = TElement<{
  type: ELEMENT_TEXT_AREA_FIELD
  fieldId: string
}>

export const TextAreaFieldElement: SlateElementComponent = ({
  attributes,
  element,
  children,
}) => {
  return (
    <div {...attributes}>
      <CalloutRoleSwitch>
        <CalloutDiv>
          <CalloutEmojiDiv>
            <CalloutNimbleEmoji emoji={':writing_hand:'} />
          </CalloutEmojiDiv>
          <CalloutChildrenDiv>
            <TextAreaFieldInput
              id={element.fieldId}
              fieldId={element.fieldId}
              placeholder="Add your long answer"
              className={textInputClassName}
            />
          </CalloutChildrenDiv>
        </CalloutDiv>
      </CalloutRoleSwitch>
      {children}
    </div>
  )
}
