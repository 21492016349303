import React, { FunctionComponent, useCallback } from 'react'
import Popup from 'reactjs-popup'
import { Archive } from '@styled-icons/heroicons-outline'
import { mutate } from 'swr'
import { apiExperiencePath } from '../../helpers/apiPaths'
import { getSelectedExperienceId } from '../../selectors/experiences'
import { useFlashMessages } from '../../utils/useFlashMessages'
import { useSelectorOrThrow } from '../../utils/useSelectorOrThrow'
import { Button } from '../Button/Button'
import { DropdownItem } from '../Dropdown/DropdownItem'
import { Modal } from '../Modal/Modal'
import { SetSavingProps } from './ExperienceStatus'
import { updateExperienceStatus } from './updateExperienceStatus'

export const ExperienceStatus_DropdownMenu_ArchiveButton: FunctionComponent<
  SetSavingProps
> = ({ setSaving }) => {
  const experienceId = useSelectorOrThrow(getSelectedExperienceId)
  const flashMessages = useFlashMessages()

  const handleConfirmArchive = useCallback(async () => {
    setSaving(true)
    try {
      const response = await updateExperienceStatus(experienceId, 'archived')
      if (!response.ok) throw response.status
      await mutate(apiExperiencePath(experienceId))
      flashMessages.show({
        type: 'success',
        text: 'Your Learning Experience was archived successfully.',
      })
    } catch {
      flashMessages.show({
        type: 'error',
        text: 'Your Learning Experience could not be archived.',
      })
    }
    setSaving(false)
  }, [experienceId, flashMessages, setSaving])

  return (
    <Popup
      modal
      lockScroll
      closeOnEscape
      trigger={
        <DropdownItem>
          <div className="text-red-500">Archive</div>
        </DropdownItem>
      }
    >
      {(close: () => void) => {
        const handleConfirm = () => {
          close()
          handleConfirmArchive()
        }
        return (
          <Modal
            icon={<Archive size={24} />}
            title="Archive Learning Experience"
            description="The LX will move to the archived list and will be hidden to its attendees and members of the community. You can restore it at anytime."
            buttons={
              <>
                <Button onClick={close} theme="secondary">
                  Cancel
                </Button>
                <Button onClick={handleConfirm} theme="destroy">
                  Archive
                </Button>
              </>
            }
          />
        )
      }}
    </Popup>
  )
}
