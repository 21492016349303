import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'
import { getCollapsedSectionIds } from '../../selectors/base'
import { ExperienceSideMenuAddPageButton } from '../ExperienceSideMenu/ExperienceSideMenuAddPageButton'

export interface Props {
  sectionId: EntityId
  showAddPageButton?: boolean
  showHiddenPages?: boolean
}

export const SideMenuSectionPages: FunctionComponent<Props> = ({
  sectionId,
  showAddPageButton,
  children,
}) => {
  const isSectionCollapsed = useSelector(getCollapsedSectionIds)[sectionId]
  if (isSectionCollapsed) return null

  return (
    <div>
      <div className="mb-2">{children}</div>
      {showAddPageButton && (
        <ExperienceSideMenuAddPageButton sectionId={sectionId} />
      )}
    </div>
  )
}
