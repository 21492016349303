import React, { FunctionComponent, useCallback } from 'react'
import { PencilAlt } from '@styled-icons/heroicons-outline'
import {
  BorderAll,
  BorderBottom,
  BorderClear,
  BorderLeft,
  BorderRight,
  BorderTop,
} from '@styled-icons/material'
import { HorizontalRule } from '@styled-icons/material/HorizontalRule'
import {
  Bold,
  H1,
  H2,
  H3,
  Italic,
  Link,
  ListOrdered,
  ListUnordered,
  Underline,
} from '@styled-icons/remix-editor'
import { CodeBox, Edit, Lightbulb, Movie, Save } from '@styled-icons/remix-line'
import {
  addColumn,
  addRow,
  BlockToolbarButton,
  deleteColumn,
  deleteRow,
  deleteTable,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_OL,
  ELEMENT_UL,
  getPluginType,
  insertTable,
  LinkToolbarButton,
  ListToolbarButton,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MarkToolbarButton,
  TableToolbarButton,
} from '@udecode/plate'
import { ToolTip } from '../ToolTip/ToolTip'
import { useStoreEditorRefInvariant } from './config/useStoreEditorRefInvariant'
import { AttachmentButton, ELEMENT_ATTACHMENT } from './plugins/attachment'
import { ELEMENT_CALLOUT, insertCallout } from './plugins/callout'
import { ELEMENT_EMBED } from './plugins/embed'
import { insertFileField } from './plugins/file-field/eventHandlers'
import {
  ELEMENT_HORIZONTAL_RULE,
  insertHorizontalRule,
} from './plugins/horizontal-rule'
import { ELEMENT_IFRAME, insertIframeElement } from './plugins/iframe'
import { promptLink } from './plugins/link'
import { insertTextAreaField } from './plugins/text-area-field/eventHandlers'
import { ELEMENT_TEXT_FIELD } from './plugins/text-field/createTextFieldPlugin'
import { insertTextField } from './plugins/text-field/eventHandlers'
import { promptUrl } from './plugins/url/eventHandlers'

export const EditorToolbar: FunctionComponent = () => {
  return (
    <div className="flex w-full flex-wrap justify-between gap-6 rounded-t px-4 pt-6 pb-4">
      <ToolbarGroup title="Text formatting">
        <ToolbarGroupTextFormatting />
      </ToolbarGroup>

      <ToolbarGroup title="Media, links and shapes">
        <ToolbarGroupMedia />
      </ToolbarGroup>

      <ToolbarGroup title="Tables">
        <ToolbarButtonsTable />
      </ToolbarGroup>

      <ToolbarGroup title="Task fields">
        <ToolbarGroupFields />
      </ToolbarGroup>
    </div>
  )
}

const ToolbarGroup: FunctionComponent<{ title: string }> = ({
  title,
  children,
}) => {
  return (
    <div className="bg-white">
      <div className="mx-1 mb-1 text-xs font-medium">{title}</div>
      <div className="flex flex-wrap gap-y-2">{children}</div>
    </div>
  )
}

const ToolbarGroupTextFormatting: FunctionComponent = () => {
  const editor = useStoreEditorRefInvariant()
  return (
    <>
      <ToolTip content="Big Heading">
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_H1)}
          icon={<H1 />}
        />
      </ToolTip>

      <ToolTip content="Medium Heading">
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_H2)}
          icon={<H2 />}
        />
      </ToolTip>

      <ToolTip content="Small Heading">
        <BlockToolbarButton
          type={getPluginType(editor, ELEMENT_H3)}
          icon={<H3 />}
        />
      </ToolTip>

      <div className="mx-1" />

      <ToolTip content="Bold">
        <MarkToolbarButton
          type={getPluginType(editor, MARK_BOLD)}
          icon={<Bold />}
        />
      </ToolTip>

      <ToolTip content="Italic">
        <MarkToolbarButton
          type={getPluginType(editor, MARK_ITALIC)}
          icon={<Italic />}
        />
      </ToolTip>

      <ToolTip content="Underline">
        <MarkToolbarButton
          type={getPluginType(editor, MARK_UNDERLINE)}
          icon={<Underline />}
        />
      </ToolTip>

      <div className="mx-1" />

      <ToolTip content="Unordered List">
        <ListToolbarButton
          type={getPluginType(editor, ELEMENT_UL)}
          icon={<ListUnordered />}
        />
      </ToolTip>

      <ToolTip content="Ordered List">
        <ListToolbarButton
          type={getPluginType(editor, ELEMENT_OL)}
          icon={<ListOrdered />}
        />
      </ToolTip>
    </>
  )
}

const ToolbarGroupMedia: FunctionComponent = () => {
  const editor = useStoreEditorRefInvariant()
  const handleUrl = useCallback(
    e => {
      e.preventDefault()
      promptUrl(editor)
    },
    [editor]
  )
  const handleIframe = useCallback(
    e => {
      e.preventDefault()
      const code = window.prompt('Insert iframe code')
      if (code) insertIframeElement(editor, code)
      else alert('No iframe code inserted.')
    },
    [editor]
  )
  const handleLink = useCallback(
    e => {
      e.preventDefault()
      promptLink(editor)
    },
    [editor]
  )
  const handleHorizontalRule = useCallback(
    e => {
      e.preventDefault()
      insertHorizontalRule(editor)
    },
    [editor]
  )
  const handleCallout = useCallback(
    e => {
      e.preventDefault()
      insertCallout(editor, ':bulb:')
    },
    [editor]
  )
  return (
    <>
      <ToolTip content="Insert Media by URL">
        <BlockToolbarButton
          onMouseDown={handleUrl}
          type={getPluginType(editor, ELEMENT_EMBED)}
          icon={<Movie />}
        />
      </ToolTip>

      <ToolTip content="Insert iframe">
        <BlockToolbarButton
          onMouseDown={handleIframe}
          type={getPluginType(editor, ELEMENT_IFRAME)}
          icon={<CodeBox />}
        />
      </ToolTip>

      <ToolTip content="Upload Image">
        <AttachmentButton type={getPluginType(editor, ELEMENT_ATTACHMENT)} />
      </ToolTip>

      <ToolTip content="Link">
        <LinkToolbarButton icon={<Link />} onMouseDown={handleLink} />
      </ToolTip>

      <ToolTip content="Callout">
        <BlockToolbarButton
          onMouseDown={handleCallout}
          type={getPluginType(editor, ELEMENT_CALLOUT)}
          icon={<Lightbulb />}
        />
      </ToolTip>

      <ToolTip content="Horizontal Rule">
        <BlockToolbarButton
          onMouseDown={handleHorizontalRule}
          type={getPluginType(editor, ELEMENT_HORIZONTAL_RULE)}
          icon={<HorizontalRule />}
        />
      </ToolTip>
    </>
  )
}

const ToolbarButtonsTable = () => (
  <>
    <ToolTip content="Insert Table">
      <TableToolbarButton icon={<BorderAll />} transform={insertTable} />
    </ToolTip>
    <ToolTip content="Delete Table">
      <TableToolbarButton icon={<BorderClear />} transform={deleteTable} />
    </ToolTip>
    <ToolTip content="Add Row">
      <TableToolbarButton icon={<BorderBottom />} transform={addRow} />
    </ToolTip>
    <ToolTip content="Delete Row">
      <TableToolbarButton icon={<BorderTop />} transform={deleteRow} />
    </ToolTip>
    <ToolTip content="Add Column">
      <TableToolbarButton icon={<BorderRight />} transform={addColumn} />
    </ToolTip>
    <ToolTip content="Delete Column">
      <TableToolbarButton icon={<BorderLeft />} transform={deleteColumn} />
    </ToolTip>
  </>
)

const ToolbarGroupFields: FunctionComponent = () => {
  const editor = useStoreEditorRefInvariant()
  const handleTextField = useCallback(
    e => {
      e.preventDefault()
      insertTextField(editor)
    },
    [editor]
  )
  const handleTextAreaField = useCallback(
    e => {
      e.preventDefault()
      insertTextAreaField(editor)
    },
    [editor]
  )
  const handleFileField = useCallback(
    e => {
      e.preventDefault()
      insertFileField(editor)
    },
    [editor]
  )
  return (
    <>
      <ToolTip content="Text Field">
        <BlockToolbarButton
          onMouseDown={handleTextField}
          type={getPluginType(editor, ELEMENT_TEXT_FIELD)}
          icon={<Edit />}
        />
      </ToolTip>

      <ToolTip content="Text Area">
        <BlockToolbarButton
          onMouseDown={handleTextAreaField}
          type={getPluginType(editor, ELEMENT_TEXT_FIELD)}
          icon={<PencilAlt />}
        />
      </ToolTip>

      <ToolTip content="File Field">
        <BlockToolbarButton
          onMouseDown={handleFileField}
          type={getPluginType(editor, ELEMENT_TEXT_FIELD)}
          icon={<Save />}
        />
      </ToolTip>
    </>
  )
}
