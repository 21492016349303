import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { components, FocusEventHandler, Props } from 'react-select'
import { FieldValidator, useField, useFormikContext } from 'formik'
import { RecordPropertyValue } from '../../types/entities'
import { FieldError } from '../FieldError/FieldError'
import { ThemedSelect } from '../ThemedSelect/ThemedSelect'
import { ToolTip } from '../ToolTip/ToolTip'

export interface SelectFieldProps extends Props {
  name: string
  submitOnChange?: boolean
  inputId?: string
  ariaLabel?: string
  dropdownHidden?: boolean
  isFilter?: boolean
  selectedPlaceholder?: string
  validate?: FieldValidator
  hideErrors?: boolean
}

const Input = (props: any) => {
  const { selectProps } = props

  return (
    <components.Input
      {...props}
      placeholder={selectProps.menuIsOpen ? 'Type to search' : ''}
    />
  )
}

const Option = (props: any) => {
  const ref = useRef<null | HTMLDivElement>(null)
  const [isOverflown, setIsOverflown] = useState(false)
  useEffect(() => {
    const element = ref.current
    setIsOverflown(element != null && element.scrollWidth > element.clientWidth)
  }, [props])
  const option = useMemo(() => {
    return (
      <div>
        <components.Option {...props}>
          {props.isMulti ? (
            <div
              className={`flex items-center ${
                props.selectProps.isFilter && 'truncate'
              }`}
              ref={ref}
            >
              <input
                className="mr-2 cursor-pointer"
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{' '}
              <label className="cursor-pointer">{props.label}</label>
            </div>
          ) : (
            <label className="cursor-pointer">{props.label}</label>
          )}
        </components.Option>
      </div>
    )
  }, [props])
  return props.isFilter ? (
    option
  ) : (
    <ToolTip content={isOverflown ? props.label : undefined}>{option}</ToolTip>
  )
}

import { CommonProps } from 'react-select'
interface MultiValueContainerProps extends CommonProps<any, any, any> {
  children: Array<ReactElement>
  getValue: () => Array<any>
  hasValue: boolean
  selectProps: {
    placeholder: string
    menuIsOpen: boolean
    isSearchable: boolean
    hideValueContainer: boolean
    selectedPlaceholder: string
    groupMultiDisplay: boolean
  }
}

const MultiValueContainer: FunctionComponent<MultiValueContainerProps> = ({
  children,
  ...props
}) => {
  const { getValue, hasValue } = props
  const value = getValue()
  const numberSelect = value.length
  const placeholder = props.selectProps.placeholder
  const selectedPlaceholder = props.selectProps.selectedPlaceholder
  const groupMultiDisplay = props.selectProps.groupMultiDisplay
  const displayedPlaceholder = groupMultiDisplay ? (
    <div className="flex flex-col">
      {value.map(val => (
        <div key={val.value} className="block">
          {val.label}
        </div>
      ))}
    </div>
  ) : (
    `${selectedPlaceholder} (${numberSelect})`
  )
  const menuIsOpen = props.selectProps.menuIsOpen
  const isSearchable = props.selectProps.isSearchable

  if (props.selectProps.hideValueContainer == true) return null
  return (
    <components.ValueContainer
      className={!hasValue ? 'text-gray-500' : undefined}
      {...props}
    >
      {(!isSearchable || (isSearchable && !menuIsOpen)) &&
        (!hasValue ? placeholder || 'Select...' : displayedPlaceholder)}
      {React.Children.map(children, (child: any) => {
        return child && child.props.id && child
      })}
    </components.ValueContainer>
  )
}

interface SingleValueContainerProps extends CommonProps<any, any, any> {
  children: Array<ReactElement>
  getValue: () => Array<any>
  hasValue: boolean
  selectProps: { placeholder: string }
}

const SingleValueContainer: FunctionComponent<SingleValueContainerProps> = ({
  children,
  ...props
}) => {
  const { getValue, hasValue } = props
  const placeholder = props.selectProps.placeholder
  const value = getValue()

  return (
    <components.ValueContainer
      className={!hasValue ? 'text-gray-500' : undefined}
      {...props}
    >
      {!hasValue ? placeholder || 'Select...' : value[0].label}
      {React.Children.map(children, (child: any) => {
        return child?.props.id && child
      })}
    </components.ValueContainer>
  )
}

export const SelectField: FunctionComponent<SelectFieldProps> = props => {
  const { submitForm } = useFormikContext()
  const [field, meta, helpers] = useField<RecordPropertyValue>({
    name: props.name,
    validate: props.validate,
  })

  useEffect(
    () => field.value === '' && (helpers.setValue(undefined) as any),
    [field.value, helpers]
  )

  const value = useMemo(() => {
    if (field.value === '') {
      return []
    }
    return props.options?.filter(option =>
      Array.isArray(field.value)
        ? field.value.includes(option.value)
        : field.value === option.value
    )
  }, [field.value, props.options])

  const components = useMemo(() => {
    return {
      ...((props.isMulti && { Option }) as any),
      ...((props.isSearchable && { Input }) as any),
      ...((props.dropdownHidden && {
        DropdownIndicator: () => null,
      }) as any),
      ValueContainer: props.isMulti
        ? MultiValueContainer
        : SingleValueContainer,
    }
  }, [props.isMulti, props.isSearchable, props.dropdownHidden])

  const handleOnChange = useCallback(
    (option, action) => {
      const value = Array.isArray(option)
        ? option.map(_option => _option.value)
        : option?.value
      helpers.setValue(value)
      props.onChange?.(option, action)
      if (props.submitOnChange) submitForm()
    },
    [helpers, props, submitForm]
  )
  const handleOnBlur = useCallback<FocusEventHandler>(
    event => {
      helpers.setTouched(true)
      props.onBlur && props.onBlur(event)
    },
    [helpers, props]
  )

  return (
    <div id={`select-${props.inputId?.replace('.', '-')}`}>
      <ThemedSelect
        options={props.options}
        value={value as any}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        placeholder={props.placeholder}
        isClearable={
          typeof props.isClearable === 'undefined' ? false : props.isClearable
        }
        isSearchable={props.isSearchable}
        components={components}
        isMulti={props.isMulti}
        isDisabled={props.isDisabled}
        isOptionDisabled={props.isOptionDisabled}
        inputId={props.inputId}
        menuPlacement={props.menuPlacement}
        filterOption={props.filterOption}
        ariaLabel={props.ariaLabel}
        closeMenuOnSelect={!props.isMulti}
        hideSelectedOptions={false}
        isFilter={props.isFilter}
        menuIsOpen={props.menuIsOpen}
        selectedPlaceholder={props.selectedPlaceholder}
        isInvalid={meta.error && !props.hideErrors}
        noBorder={props.noBorder}
        groupMultiDisplay={props.groupMultiDisplay}
      />
      {meta.error && !props.hideErrors && <FieldError>{meta.error}</FieldError>}
    </div>
  )
}
