import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { ExperienceInput } from '../types/entities'

export type ExperienceInputsState = EntityState<ExperienceInput>

export const experienceInputsAdapter = createEntityAdapter<ExperienceInput>()

export const initialState =
  experienceInputsAdapter.getInitialState<ExperienceInputsState>({
    ids: [],
    entities: {},
  })

export const experienceInputsSlice = createSlice({
  name: 'experienceInputs',
  initialState,
  reducers: {
    upsertOneExperienceInput: experienceInputsAdapter.upsertOne,
    upsertManyExperienceInputs: experienceInputsAdapter.upsertMany,
    removeAllExperienceInputs: experienceInputsAdapter.removeAll,
  },
})

export const {
  upsertOneExperienceInput,
  upsertManyExperienceInputs,
  removeAllExperienceInputs,
} = experienceInputsSlice.actions

export const { reducer } = experienceInputsSlice
