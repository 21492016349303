import React, { FunctionComponent } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import invariant from 'tiny-invariant'
import { useSWR_Experience } from '../../../../hooks/useAPI'
import { useExperienceId } from '../../../../hooks/useExperienceId'
import { useMembershipId } from '../../../../hooks/useMembershipId'
import { useCurrentAttendeeMembership } from '../../../../utils/useCurrentAttendeeMembership'
import { EvidenceInputBox } from '../EvidenceInputBox'
import { useSubmitForTextInput } from '../inputUtils'
import { RoleSwitch } from '../RoleSwitch'
import { useInput } from '../useInputs'

interface TextAreaFieldInputProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  fieldId: EntityId
}

export const TextAreaFieldInput: FunctionComponent<
  TextAreaFieldInputProps
> = props => {
  return (
    <RoleSwitch
      attendee={<AttendeeVersion {...props} />}
      creator={<CreatorVersion {...props} />}
      evidence={<EvidenceVersion {...props} />}
    />
  )
}

const AttendeeVersion: FunctionComponent<TextAreaFieldInputProps> = ({
  fieldId,
  ...props
}) => {
  const experienceId = useExperienceId()
  const experienceResponse = useSWR_Experience(experienceId)
  const experience = experienceResponse.data?.data.experience
  const membership = useCurrentAttendeeMembership()
  invariant(membership)
  const input = useInput(fieldId, membership.id)
  const { handleChange } = useSubmitForTextInput(input)
  // This auto-growing textbox is shamelessly stolen from this CSS tricks post:
  // https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
  // It works by creating an invisible div with exactly the same content and
  // styling as the textarea, and using grid to place them on top of each other.
  // The div will expand to fit the content, which expands the parent container,
  // which expands the textarea.

  if (!experience) return null

  return (
    <div className="grid">
      <textarea
        {...props}
        value={input.unpublished}
        onChange={handleChange}
        rows={5}
        className={`${props.className} col-span-1 col-start-1 row-span-1 row-start-1 resize-none overflow-hidden`}
        disabled={experience.status !== 'published'}
        readOnly={experience.status !== 'published'}
      />
      <div
        className={`${props.className} invisible col-span-1 col-start-1 row-span-1 row-start-1 whitespace-pre-wrap px-3 py-2`}
      >
        {input.unpublished}{' '}
      </div>
    </div>
  )
}

const CreatorVersion: FunctionComponent<TextAreaFieldInputProps> = ({
  placeholder,
}) => {
  return (
    <div className="h-16 rounded bg-white p-2 text-gray-500">{placeholder}</div>
  )
}

const EvidenceVersion: FunctionComponent<TextAreaFieldInputProps> = ({
  fieldId,
}) => {
  const membershipId = useMembershipId()
  const input = useInput(fieldId, membershipId)
  return (
    <EvidenceInputBox className="min-h-[6rem]">
      {input.published}
    </EvidenceInputBox>
  )
}
