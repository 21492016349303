import { createSelector } from '@reduxjs/toolkit'
import { recordPropertiesAdapter } from '../slices/recordProperties'
import { RecordProperty } from '../types/entities'
import { RootState } from '../types/store'
import { getEntities } from './base'
import { recordPropertyComparer } from './recordProperties'

const experienceRecordPropertiesSelector = createSelector(
  getEntities,
  entities => {
    const experienceRecordProperties = Object.fromEntries(
      Object.entries(entities.recordProperties.entities).filter(
        ([, recordProperty]) => recordProperty?.recordType === 'experience'
      )
    )
    const experienceRecordPropertyIds = Object.keys(experienceRecordProperties)
    return {
      entities: experienceRecordProperties,
      ids: experienceRecordPropertyIds,
    }
  }
)
const experienceRecordPropertiesDefaultSelectors =
  recordPropertiesAdapter.getSelectors<RootState>(
    experienceRecordPropertiesSelector
  )

export const getExperienceRecordPropertiesAll =
  experienceRecordPropertiesDefaultSelectors.selectAll
export const getExperienceRecordPropertyById =
  experienceRecordPropertiesDefaultSelectors.selectById

export const getSortedExperienceRecordPropertiesAll = createSelector(
  getExperienceRecordPropertiesAll,
  configs => configs.sort(recordPropertyComparer)
)

export const getSearchableExperienceRecordPropertiesAll = createSelector(
  getSortedExperienceRecordPropertiesAll,
  configs => configs.filter(c => c.config.searchable)
)

const getExperiencePropertyConfigsSelectorForType = (
  type: RecordProperty['propertyType']
) =>
  createSelector(getExperienceRecordPropertiesAll, configs =>
    configs.filter(x => x.propertyType === type)
  )

export const getSortedVerticalExperienceRecordProperties = createSelector(
  getExperiencePropertyConfigsSelectorForType('secondary'),
  configs => configs.sort(recordPropertyComparer)
)

export const getSortedHorizontalExperienceRecordProperties = createSelector(
  getExperiencePropertyConfigsSelectorForType('primary'),
  getExperiencePropertyConfigsSelectorForType('system'),
  (primaryConfigs, systemConfigs) =>
    [...primaryConfigs, ...systemConfigs].sort(recordPropertyComparer)
)
