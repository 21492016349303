import React, { useCallback } from 'react'
import 'tippy.js/themes/light.css'
import { ExternalLink } from '@styled-icons/heroicons-outline'
import Tippy from '@tippyjs/react'
import { TElement, TRenderElementProps } from '@udecode/plate'
import { useReadOnly } from 'slate-react'
import { useStoreEditorRefInvariant } from '../../config/useStoreEditorRefInvariant'
import { setLinkUrl, unwrapLink } from './eventHandlers'

export type LinkElement = TElement<{
  type: 'a'
  url: string
}>

export const LinkElement = ({
  attributes,
  children,
  element,
}: TRenderElementProps<LinkElement>): JSX.Element => {
  const editor = useStoreEditorRefInvariant()
  const isReadOnly = useReadOnly()
  const handleEdit = useCallback(
    event => {
      event.preventDefault()
      const newUrl = prompt('Change the URL:', element.url)
      if (newUrl) setLinkUrl({ editor, element, url: newUrl })
      else unwrapLink(editor)
    },
    [editor, element]
  )

  const link = (
    <a
      className="text-blue-600 hover:cursor-pointer hover:underline"
      {...attributes}
      href={element.url}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )

  const tooltipContent = (
    <div contentEditable={false} className="space-x-2 text-xs">
      <a
        href={element.url}
        target="_blank"
        rel="noreferrer"
        className="hover:text-blue-500 hover:underline"
      >
        {element.url}
        <ExternalLink size={14} className="ml-1 inline align-text-bottom" />
      </a>
      <button
        onClick={handleEdit}
        className="rounded-sm bg-gray-100 px-2 py-1 font-semibold hover:bg-gray-200"
      >
        Edit
      </button>
    </div>
  )

  const linkWithTooltip = (
    <Tippy content={tooltipContent} interactive theme="light">
      {link}
    </Tippy>
  )

  return isReadOnly ? link : linkWithTooltip
}
