import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type RequestsState = Record<
  string,
  { loading: boolean; error: Record<string, any> | undefined }
>

export const initialState: RequestsState = {}

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    initRequest: (state, action: PayloadAction<string>) => {
      state[action.payload] = { loading: true, error: undefined }
    },
    setLoading: (
      state,
      action: PayloadAction<{ key: string; loading: boolean }>
    ) => {
      state[action.payload.key].loading = action.payload.loading
    },
    setError: (state, action: PayloadAction<{ key: string; error: any }>) => {
      state[action.payload.key].error = action.payload.error
    },
  },
})

export const { initRequest, setLoading, setError } = requestsSlice.actions

export const { reducer } = requestsSlice
