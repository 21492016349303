import React, { FunctionComponent } from 'react'

interface Props {
  title: string
}

export const ExperienceTopMenuOverview: FunctionComponent<Props> = ({
  title,
}) => {
  return (
    <h1 className="w-full truncate bg-transparent text-base font-medium text-gray-100 placeholder-gray-300">
      {title}
    </h1>
  )
}
